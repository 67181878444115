import {gridDictionary} from '../common-element/list-generator/generator-interfaces';

export class Dictionaries {
  public static dictionaries: gridDictionary[] = [
    {
      id: 'documents',
      name: 'Документы',
      fileNameExport: 'Документы',
      get: 'api/document/list',
      fastDelete: true,
      delete: 'api/document',
      primaryKey: 'id',
      allowCopy: true,
      editRole: 'documents',
      remoteOperations: true,
      countPage: 15,
      wordWrap: true,
      allowRefresh: true,
      allowAdd: true,
      hideRowStatus: true,
      showCountRow: true,
      hidePrimaryKeyCol: true,
      dropDownButton: [
        {
          hint: 'Загрузки',
          icon: 'upload',
          dropDownWidth: 100,
          items: [
            {value: 1, name: 'РТН', icon: 'cart'}
          ]
        }, {
          hint: 'Пакетное согласование',
          icon: 'fa fa-archive',
          dropDownWidth: 250,
          items: [
            {value: 2, name: 'Вся иерархния документов', icon: 'hierarchy'},
            {value: 3, name: 'Только выбранные документы', icon: 'fieldchooser'}
          ]
        }
      ],
      customButton: [{
        text: 'Загрузка архива из СБИС',
        hint: 'Загрузка архива из СБИС',
        value: 'download',
        icon: 'fa fa-download'
      }],
      columns: [{
        caption: 'id',
        dataField: 'id',
        dataType: 'number',
        visible: false,
        allowEditing: false
      },{
        caption: 'Доп. информация',
        dataField: 'additionalInfo',
        dataType: 'number',
        headerFilter: false,
        dictionary: {
          dataSource: [{id: 1, name: 'Типовое решение'}, {id: 2, name: 'Ручная загрузка'}],
          name: 'name',
          id: 'id',
          popupWidth: '250px'
        },
        cellTemplate: 'documentSchemaName',
        width: 50
      }, {
        caption: 'Имеются архивные МТРиО',
        dataField: 'hasArchiveMaterial',
        dataType: 'boolean',
        headerFilter: false,
        cellTemplate: 'hasArchiveMaterial',
        width: 50,
        visible: false
      }, {
        caption: 'Номер документа',
        dataField: 'number',
        dataType: 'string',
        cellTemplate: 'columnDocumentNumberButtons',
        width: 300
      }, {
        caption: 'Дата документа',
        dataField: 'documentDate',
        dataType: 'date',
        selectedFilterOperation: 'between'
      }, {
        caption: 'Объект/Подобъект',
        dataField: 'object',
        dataType: 'string',
        cellTemplate: 'longTextTemplate',
        width: 400
      }, {
        caption: 'Тип документа',
        dataField: 'documentType',
        dataType: 'string'
      }, {
        caption: 'Вид работ',
        dataField: 'actTypeWork',
        dataType: 'string',
        visible: false
      }, {
        caption: 'Наименование',
        dataField: 'name',
        dataType: 'string'
      }, {
        caption: 'Описание',
        dataField: 'description',
        dataType: 'string',
        cellTemplate: 'longTextTemplate'
      }, {
        caption: 'Версия',
        dataField: 'version',
        dataType: 'string'
      }, {
        caption: 'Статус',
        dataField: 'status',
        dataType: 'string',
        cellTemplate: 'documentSailError'
      }, {
        caption: 'Подразделение',
        dataField: 'department',
        dataType: 'string',
      }, {
        caption: 'Дата передачи',
        dataField: 'status_2',
        dataType: 'date',
      }, {
        caption: 'Архив из СБИСа',
        dataField: 'filesFromSbis',
        dataType: 'boolean',
        cellTemplate: 'sbisArchive',
        width: 65
      }, {
        caption: 'А',
        dataField: 'isActive',
        dataType: 'boolean'
      }, {
        caption: 'Дата создания',
        dataField: 'createdAt',
        dataType: 'datetime',
        selectedFilterOperation: 'between'
      }, {
        caption: 'Дата соглас.',
        dataField: 'status_3',
        dataType: 'date',
      }, {
        caption: 'Номер договора',
        dataField: 'numContract',
        dataType: 'string'
      }, {
          caption: 'Ошибка из Паруса',
          dataField: 'sailResponse',
          dataType: 'string',
          visible: false
      }]
    },
    {
      id: 'documentsNonRemote',
      name: 'Документы',
      fileNameExport: 'Документы',
      fastDelete: true,
      delete: 'api/document',
      primaryKey: 'id',
      allowCopy: true,
      editRole: 'documents',
      remoteOperations: false,
      countPage: 15,
      wordWrap: true,
      allowRefresh: true,
      allowAdd: true,
      hideRowStatus: true,
      showCountRow: true,
      groupingColumn: true,
      columns: [{
        caption: 'id',
        dataField: 'id',
        dataType: 'number',
        visible: false
      }, {
        caption: 'Номер документа',
        dataField: 'number',
        dataType: 'string'
      }, {
        caption: 'Дата документа',
        dataField: 'documentDate',
        dataType: 'date'
      }, {
        caption: 'Объект/Подобъект',
        dataField: 'object',
        dataType: 'string',
        cellTemplate: 'longTextTemplate',
        width: 400,
        visible: false
      }, {
        caption: 'Тип документа',
        dataField: 'documentType',
        dataType: 'string'
      }, {
        caption: 'Наименование',
        dataField: 'name',
        dataType: 'string',
        buttons: [{
          icon: 'download',
          hint: 'Скачать основной файл',
          id: 'download',
          show: data => data.hasFile ? 'block' : 'none'
        }, {
          icon: 'fa fa-eye',
          hint: 'Открыть',
          id: 'edit'
        }
        ]
      }, {
        caption: 'Подразделение',
        dataField: 'departmentName',
        dataType: 'string'
      }, {
        caption: 'Родительский документ',
        dataField: 'parentName',
        cellTemplate: 'longTextTemplate',
        dataType: 'string'
      }, {
        caption: 'Описание',
        dataField: 'description',
        dataType: 'string',
        cellTemplate: 'longTextTemplate'
      }, {
        caption: 'Версия',
        dataField: 'version',
        dataType: 'string'
      }, {
        caption: 'Статус',
        dataField: 'status',
        dataType: 'string'
      }, {
        caption: 'А',
        dataField: 'isActive',
        dataType: 'boolean'
      }, {
        caption: 'Дата создания',
        dataField: 'createdAt',
        dataType: 'datetime'
      }, {
        caption: 'Год документа',
        dataField: 'year',
        dataType: 'number'
      }]
    },
    {
      id: 'doc-objects',
      name: 'Объект/подобъект',
      primaryKey: 'name',
      columnAutoWidth: true,
      wordWrap: true,
      hideSearchPanel: true,
      hideRowStatus: true,
      hideFilterPanel: true,
      hideFilterHead: true,
      hideExport: true,
      hideChooser: true,
      hideSelectedColumn: true,
      hideFilterRow: true,
      hideSearchFilterHead: true,
      hideColumnHeader: true,
      columns: [{
        caption: 'Объект/подобъект',
        dataType: 'string',
        dataField: 'isObject',
        width: 25,
        dictionary: {
          dataSource: [
            {id: 'true', name: 'Объект'},
            {id: 'false', name: 'Подобъект'}
          ],
          name: 'name',
          id: 'id'
        },
        sort: 'desc'
      }, {
        caption: 'Наименование',
        dataType: 'string',
        dataField: 'code',
        cellTemplate: 'objectNameLinkTemplate',
        sort: 'asc'
      }
      ]
    },
    {
      id: 'doc-documents',
      countPage: 30,
      showCaption: true,
      name: 'Дополнительные файлы',
      hideExport: true,
      hideChooser: true,
      showCountRow: false,
      primaryKey: 'id',
      wordWrap: true,
      allowDelete: true,
      addRowCustom: true,
      dropDownButton: [
        {
          hint: 'Добавить',
          icon: 'add',
          dropDownWidth: 150,
          items: [
            {value: 1, name: 'Файл', icon: 'fa fa-upload'},
            {value: 2, name: 'Документ', icon: 'fa fa-link'},
            {value: 3, name: 'Счет-фактура', icon: 'datafield', visible: true}
          ],
          // checkRights: 'W'
        }
      ],
      columns: [{
        caption: 'Наименование',
        dataField: 'name',
        dataType: 'string',
        allowEdit: false,
        cellTemplate: 'columnButtonsLongText',
        buttons: [
          {
            icon: 'download',
            hint: 'Скачать',
            id: 'download',
            show: data => data.extension || data.attachmentType === 'file' ? 'block' : 'none'
          }, {
            icon: 'fa fa-eye',
            hint: 'Открыть карточку связанного документа',
            id: 'link',
            show: data => !data.extension && data.attachmentType !== 'file' ? 'block' : 'none'
          }, {
            icon: 'fa fa-eye',
            hint: 'Просмотреть содержимое',
            id: 'report',
            show: data => data.typeId ? 'block' : 'none'
          },
        ],
        width: undefined
      },
        {
          caption: '',
          dataField: 'extension',
          dataType: 'string',
          allowEdit: false,
          width: 25,
          cellTemplate: 'iconFile',
          hideHeaderFiltering: true,
        },
        {
          caption: 'typeId',
          dataField: 'typeId',
          dataType: 'number',
          allowEdit: false,
          visible: false,
        },
        {
          caption: 'Тип',
          dataField: 'documentTypeName',
          dataType: 'string',
          allowEdit: false,
          width: 150
        },
        {
          caption: 'Дата',
          dataField: 'createdAt',
          dataType: 'datetime',
          allowEdit: false,
          width: 150
        },
        {
          caption: 'attachmentType',
          dataField: 'attachmentType',
          dataType: 'string',
          allowEdit: false,
          visible: false,
          sort: 'asc'
        }
      ]
    },
    {
      id: 'document-statement1',
      showCaption: true,
      name: '',
      hideExport: false,
      hideChooser: false,
      showCountRow: true,
      primaryKey: 'id',
      wordWrap: true,
      columns: [{
        caption: '№ пп',
        dataField: 'pp',
        dataType: 'number',
        allowEditing: false,
        sort: 'asc',
        width: 70
      },
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string',
          allowEditing: false
        },
        {
          caption: 'Ед. изм.',
          dataField: 'unit',
          dataType: 'string',
          allowEditing: false,
          width: 100
        },
        {
          caption: 'Кол.',
          dataField: 'count',
          dataType: 'string',
          allowEditing: false,
          width: 100
        },
        {
          caption: 'Примечание',
          dataField: 'note',
          dataType: 'string',
          allowEditing: false,
          width: 250
        },
        {
          caption: 'Хар-ка набора',
          columns: [
            {
              caption: 'Физ. хар-ка',
              dataField: 'physCharacter',
              dataType: 'string',
              width: 250,
              editCellTemplate: 'editCellPhysCharacter',
              cellTemplate: 'cellPhysCharacter',
            },
            {
              caption: 'Значение хар-ки',
              editCellTemplate: 'editCellValue',
              dataField: 'value',
              dataType: 'string',
              width: 120,
              cellTemplate: 'cellPhysCharacterValue',
            },
            {
              caption: 'Ед. измерения',
              dataField: 'physUnit',
              dataType: 'string',
              width: 100,
              allowEditing: false
            }
          ]
        },
        {
          caption: '',
          dataField: 'fileCharacter',
          cellTemplate: 'filePhysInfo'
        }
      ]
    },
    {
      id: 'document-statement2',
      showCaption: true,
      name: '',
      hideExport: false,
      hideChooser: false,
      showCountRow: true,
      primaryKey: 'id',
      wordWrap: true,
      columns: [{
        caption: 'Позиция',
        dataField: 'pp',
        dataType: 'number',
        allowEditing: false,
        sort: 'asc',
        width: 100
      },
        {
          caption: 'Наименование и техническая характеристика',
          dataField: 'name',
          dataType: 'string',
          allowEditing: false
        },
        {
          caption: 'Тип, марка обозначение документа, опросного листа',
          dataField: 'type',
          dataType: 'string',
          allowEditing: false
        },
        {
          caption: 'Код оборудования, изделия, материала',
          dataField: 'code',
          dataType: 'string',
          allowEditing: false
        },
        {
          caption: 'Завод-изготовитель',
          dataField: 'manufacturer',
          dataType: 'string',
          allowEditing: false
        },
        {
          caption: 'Мнемокод',
          dataField: 'mnemonicCode',
          dataType: 'string',
          allowEditing: false
        },
        {
          caption: 'Единица измерения',
          dataField: 'unit',
          dataType: 'string',
          allowEditing: false
        },
        {
          caption: 'Количество',
          dataField: 'count',
          dataType: 'string',
          allowEditing: false
        },
        {
          caption: 'Масса единицы, кг',
          dataField: 'unitWeight',
          dataType: 'string',
          allowEditing: false
        },
        {
          caption: 'Примечание',
          dataField: 'note',
          dataType: 'string',
          allowEditing: false
        }
      ]
    },
    {
      id: 'choose-documents',
      name: 'Выбор документа (ов)',
      primaryKey: 'id',
      get: 'api/document/list',
      remoteOperations: true,
      countPage: 10,
      wordWrap: true,
      allowRefresh: true,
      allowAdd: true,
      hideRowStatus: true,
      allowDelete: false,
      hideExport: true,
      showCaption: true,
      allowChoice: false,
      allowCopy: false,
      hideChooser: true,
      showCountRow: true,
      columns: [{
        caption: 'Номер документа',
        dataField: 'number',
        dataType: 'string'
      }, {
        caption: 'Дата документа',
        dataField: 'documentDate',
        dataType: 'date',
        sort: 'desc'
      }, {
        caption: 'Объект/Подобъект',
        dataField: 'object',
        dataType: 'string',
        cellTemplate: 'longTextTemplate',
        width: 400
      }, {
        caption: 'Тип документа',
        dataField: 'documentType',
        dataType: 'string'
      }, {
        caption: 'Наименование',
        dataField: 'name',
        dataType: 'string',
        buttons: [
          {
            icon: 'edit',
            hint: 'Просмотр',
            id: 'download'
          }
        ]
      }, {
        caption: 'Описание',
        dataField: 'description',
        dataType: 'string',
        cellTemplate: 'longTextTemplate'
      }, {
        caption: 'Версия',
        dataField: 'version',
        dataType: 'string'
      }, {
        caption: 'Статус',
        dataField: 'status',
        dataType: 'string'
      }, {
        caption: 'Активен',
        dataField: 'isActive',
        dataType: 'boolean'
      }, {
        caption: 'Дата создания',
        dataField: 'createdAt',
        dataType: 'date'
      }]
    },
    {
      id: 'check-table',
      showCaption: true,
      name: 'Выбрать из существующих документов для создания новой версии:',
      fileNameExport: 'Существующие документы для создания новой версии',
      showCountRow: true,
      primaryKey: 'id',
      columns: [{
        caption: 'Наименование документа',
        dataField: 'name',
        dataType: 'string',
        allowEdit: false
      },
        {
          caption: '№ документа',
          dataField: 'number',
          dataType: 'string',
          allowEdit: false
        },
        {
          caption: '№ версии',
          dataField: 'version',
          dataType: 'string',
          allowEdit: false
        },
        {
          caption: 'Статус',
          dataField: 'status',
          dataType: 'string',
          allowEdit: false
        }
      ]
    }
  ];
}
