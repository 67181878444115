import {Component, Input, OnInit} from '@angular/core';
import {
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  sidebarCollapse = false;

  @Input() mainUrl = '';
  @Input() navPageList: Array<any>;
  @Input() ID: number;
  @Input() selectedPage: string;
  @Input() parentPageId: string;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  sidebarButtonClick() {
    this.sidebarCollapse = !this.sidebarCollapse;
  }

  changeSelectionPage(e) {
    if (!e.child && this.mainUrl) {
      this.router.navigate([`${this.mainUrl}${e.url ? '/' + e.url : ''}/${this.ID}`]);
    }
  }

  getChildrenRights(children) {
    return children.map(x => x.right);
  }
}
