import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ReferenceInformationService} from '../../../services/reference-information.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmService} from '../../common-element/components/confirm/confirm.service';
import {ObjectService} from '../../../services/object.service';
import {DxDropDownBoxComponent} from 'devextreme-angular';
import {ListGeneratorComponent} from '../../common-element/list-generator/list-generator.component';
import {Dictionaries} from '../dictionaries';
import notify from 'devextreme/ui/notify';
import {CommonChoiceComponent} from '../../reference-information/components/common-choice/common-choice.component';
import {AuthenticationService} from '../../../services/authentication.service';


@Component({
  selector: 'app-subobject',
  templateUrl: './subobject.component.html',
  styleUrls: ['./subobject.component.scss'],
  providers: [ObjectService]
})

export class SubobjectComponent implements OnInit {
  sub: any;
  docs: any;
  sets: any;
  originalSets: any;
  setsReadOnly = true;
  enabledSubmit = false;
  loading = true;

  heightGrid: number;
  indicatorVisible = false;

  dictionaryDocs: any;
  @ViewChild('docsList', {static: false}) docsList: DxDropDownBoxComponent;
  selectedDoc: any;

  dictionarySets: any;
  @ViewChild('setsList', {static: false}) setsList: DxDropDownBoxComponent;
  selectedSet: any;

  dictionarySubobjectCharacters: any;
  @ViewChild('tableSubobjectCharacters', {static: false}) tableSubobjectCharacters: ListGeneratorComponent;
  subobjectCharacters: any;
  editRight = false;
  formType = 'subobject';
  disableEditPlanValues = false;

  constructor(private objectService: ObjectService,
              public dialogRef: MatDialogRef<SubobjectComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private refService: ReferenceInformationService,
              public dialog: MatDialog,
              private ref: ChangeDetectorRef,
              private confirmDialog: ConfirmService,
              private auth: AuthenticationService) {
    this.formType = this.data.formType || this.formType;
    this.disableEditPlanValues = this.data.subObject && this.data.isElement && this.data.subObject.version && ![1, 6].includes(this.data.subObject.version.statusId);
    this.editRight = this.auth.USER.forms.find(x => x.form === this.formType && x.permission === 'W');
    this.docGridBox_displayExpr = this.docGridBox_displayExpr.bind(this);
    this.setGridBox_displayExpr = this.setGridBox_displayExpr.bind(this);
  }

  docGridBox_displayExpr() {
    return this.selectedDoc.name + ' ' + this.selectedDoc.number + ' от ' + this.selectedDoc.documentDate;
  }

  setGridBox_displayExpr() {
    return this.selectedSet.name;
  }

  changeSetsReadOnly() {
    if (!this.auth.checkRights(this.formType, 'W')) {
      return;
    }
    if (this.selectedSet && this.selectedSet.id > 0) {
      this.confirmDialog.confirm('Подтверждение', 'Набор характеристик впоследствии будет изменен и данные будут утеряны! Продолжить?', false).subscribe(
        result => {
          if (result) {
            this.setsReadOnly = false;
            this.reSets();
          }
        });
    } else {
      this.setsReadOnly = false;
      this.reSets();
    }
  }

  reSets() {
    this.sets = [];
    for (const r of this.originalSets) {
      if (!r.isOld || (this.selectedSet && this.selectedSet.id === r.id)) {
        this.sets.push(r);
      }
    }
    this.setsList.instance.open();
  }

  selectDoc(e) {
    this.selectedDoc = e;
    this.selectedSet = null;
    this.subobjectCharacters = null;
    this.docsList.instance.close();
    this.refreshData();
    this.ref.detectChanges();
  }

  selectSet(e) {
    this.selectedSet = e;
    this.setsList.instance.close();
    this.refreshData(true);
    this.ref.detectChanges();
  }

  ngOnInit() {
    if (this.data.subObject.id > 0) {
      this.heightGrid = window.innerHeight - 440;

      this.sub = this.data.subObject;
      this.dictionarySubobjectCharacters = Dictionaries.dictionaries.find(x => x.id === 'subobject-characters' + this.sub.typeId);
      if (this.data.subObject.hasCharacters) {
        this.objectService.getDefaultCharacter(this.data.subObject.id).subscribe(data => {
          this.data.subObject.defaultSetDocumentId = data[0].default_set_document_id;
        });
      }
      this.dictionaryDocs = JSON.parse(JSON.stringify(Dictionaries.dictionaries.find(x => x.id === 'documents')));
      if (this.formType === 'subtitle') {
        const column = this.dictionaryDocs.columns.find(x => x.dataField === 'object');
        column.caption = 'ИП/Элемент ИП';
      }
      this.objectService.getDocuments(this.sub.objectId, this.formType, 'all', this.sub.titleId, true, true, true).subscribe(d => {
        console.log(d);
        this.docs = d.items;
        if (this.docs.length > 0) {
          if (this.sub.defaultSetDocumentId) {
            this.selectedDoc = this.docs.find(x => x.id === this.sub.defaultSetDocumentId);
          }
        } else {
          notify('Отсутствуют документы основания для подобъектов!', 'warning', 2500);
        }
        this.refreshData();
      }, error => {
        notify(error.message, 'error', 2500);
        this.dialogRef.close();
      });

      this.dictionarySets = Dictionaries.dictionaries.find(x => x.id === 'sets');
      this.objectService.getSets().subscribe(s => {
        console.log(s);
        this.originalSets = s.items;
        this.sets = s.items;
        this.refreshData();
      }, error => {
        notify(error.message, 'error', 2500);
        this.dialogRef.close();
      });
    } else {
      this.dialogRef.close();
    }
  }

  onValueChanged(e = null) {
    if (this.loading) {
      return;
    } else if (e && e.value === undefined) {
      return;
    }

    console.log('Changed');
    if (this.subobjectCharacters) {
      this.enabledSubmit = true;
    }
    this.ref.detectChanges();
  }

  refreshData(force = false) {
    if (this.docs && this.sets) {
      this.loading = false;
    } else {
      return;
    }

    if (this.selectedDoc && this.selectedDoc.id > 0) {
      if (!force) {
        this.objectService.getSubobjectCharacters(this.sub.id, this.selectedDoc.id).subscribe(data => {
          console.log(data);
          if (data.id === null) {
            this.getNewSubobjectCharacters();
          } else {
            this.subobjectCharacters = data.characters;
            this.updateDictionary();
            if (data.characterSet && data.characterSet.id > 0) {
              this.selectedSet = this.originalSets.find(x => x.id === data.characterSet.id);
            } else {
              this.selectedSet = null;
            }
            this.setsReadOnly = true;
            this.enabledSubmit = false;
          }
        }, () => {
          this.getNewSubobjectCharacters();
        });
      } else {
        this.getNewSubobjectCharacters();
      }
    } else {
      notify('Необходимо выбрать документ основание!', 'warning', 2500);
    }
  }

  updateDictionary() {
    this.subobjectCharacters.forEach(x => {
      if (x.character && x.character.dictionary) {
        if (!x.planInputId && !isNaN(x.planInputValue))
          x.planInputId = x.planInputValue;
        x.planInputValue = x.character.dictionary.values.find(d => d.id === Number(x.planInputId))
          ? x.character.dictionary.values.find(d => d.id === Number(x.planInputId)).value : null;
        if (!x.beforeId && !isNaN(x.beforeValue))
          x.beforeId = x.beforeValue;
        x.beforeValue = x.character.dictionary.values.find(d => d.id === Number(x.beforeId))
          ? x.character.dictionary.values.find(d => d.id === Number(x.beforeId)).value : null;
        if (!x.planOutputId && !isNaN(x.planOutputValue))
          x.planOutputId = x.planOutputValue;
        x.planOutputValue = x.character.dictionary.values.find(d => d.id === Number(x.planOutputId))
          ? x.character.dictionary.values.find(d => d.id === Number(x.planOutputId)).value : null;
        if (!x.character.dictionary.values.map(c => c.id).includes(-1, null)) {
          x.character.dictionary.values.push({id: -1, value: 'Сбросить значение'});
        }
      }
    });

    this.subobjectCharacters.forEach(x => x.statusRow = 's');
    // this.tableSubobjectCharacters.dataGrid.instance.refresh();
  }

  getNewSubobjectCharacters() {
    if (this.selectedSet && this.selectedSet.id > 0) {
      const saveObject = {
        subobjectId: this.sub.id,
        documentId: this.selectedDoc.id,
        setId: this.selectedSet.id,
        force: true
      };
      this.objectService.newSubobjectCharacters(saveObject).subscribe(data => {
        console.log(data);
        this.subobjectCharacters = data;
        this.updateDictionary();
        notify('Характеристики успешно созданы!', 'info', 1500);
        this.enabledSubmit = false;
      });
    } else {
      notify('Необходимо выбрать набор характеристик!', 'warning', 2500);
    }
    this.setsReadOnly = true;
  }

  onSubobjectFormSubmit() {
    if (this.tableSubobjectCharacters) {
      if (this.tableSubobjectCharacters.validData()) {
        this.save();
      }
    }
  }

  save() {
    if (this.tableSubobjectCharacters) {
      this.tableSubobjectCharacters.closeEditor();
      if (!this.tableSubobjectCharacters.valid) {
        alert('Проверьте корректность данных!');
        return;
      }

      if (this.tableSubobjectCharacters.getModifedRow().length > 0) {
        this.tableSubobjectCharacters.getModifedRow().forEach(x => {
          if (x.character && x.character.dictionary) {
            x.planInputValue = x.planInputId, x.planInputId = null,
              x.beforeValue = x.beforeId, x.beforeId = null,
              x.planOutputValue = x.planOutputId,
              x.planOutputId = null;
          }
        });

        const saveObject = {
          setId: this.tableSubobjectCharacters.getModifedRow()[0].subobjectSetId,
          characters: this.tableSubobjectCharacters.getModifedRow()
        };
        this.indicatorVisible = true;
        this.loading = true;
        this.objectService.saveSubobjectCharacters(saveObject).subscribe(data => {
          console.log(data);
          this.updateDictionary();
          this.loading = false;
          this.indicatorVisible = false;
          this.enabledSubmit = false;
          notify('Данные успешно сохранены!', 'success', 1500);
        }, error => {
          this.updateDictionary();
          this.loading = false;
          this.indicatorVisible = false;
          // notify(error.message, 'error', 2500);
        });
      } else {
        notify('Данные для сохранения отсутствуют!', 'warning', 2500);
      }
    }
  }

  cellClick(e) {
    if(!this.editRight || (this.disableEditPlanValues && ["planInputValue", "planOutputValue"].includes(e.column.dataField)))
      return;
    if (e.rowType === 'data' && e.row.data.character && e.row.data.character.dictionary
      && (e.column.dataField === 'planInputValue'
        || e.column.dataField === 'beforeValue'
        || e.column.dataField === 'planOutputValue')) {
      const dialogRef = this.dialog.open(CommonChoiceComponent, {
        minWidth: '30%',
        maxWidth: '30%',
        maxHeight: '100vh - 100px',
        data: {
          dataSource: e.row.data.character.dictionary.values.filter(x => x.value !== null && x.id !== -1 && (
            e.column.dataField === 'planInputValue' ? (e.row.data.planInputId != null ? x.id !== Number(e.row.data.planInputId) : x.id === x.id) :
              (e.column.dataField === 'beforeValue' ? (e.row.data.beforeId != null ? x.id !== Number(e.row.data.beforeId) : x.id === x.id) :
                  (e.column.dataField === 'planOutputValue' ? (e.row.data.planOutputId != null ? x.id !== Number(e.row.data.planOutputId) : x.id === x.id) : x.id === x.id)
              ))),
          dictionary: 'choiceCharacter'
        },
        disableClose: false
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.id === -1) {
            result.id = null;
            result.value = null;
          }
          if (e.column.dataField === 'planInputValue') {
            e.row.data.planInputId = result.id;
            e.row.data.planInputValue = result.value;
          }
          if (e.column.dataField === 'beforeValue') {
            e.row.data.beforeId = result.id;
            e.row.data.beforeValue = result.value;
          }
          if (e.column.dataField === 'planOutputValue') {
            e.row.data.planOutputId = result.id;
            e.row.data.planOutputValue = result.value;
          }
          e.row.data.statusRow = 'u';
          this.onValueChanged();
        }
      });
    }
  }

  resetCharacteristic(e) {
    if (e.column.dataField === 'planInputValue') {
      e.row.data.planInputId = null;
      e.row.data.planInputValue = null;
    }
    if (e.column.dataField === 'beforeValue') {
      e.row.data.beforeId = null;
      e.row.data.beforeValue = null;
    }
    if (e.column.dataField === 'planOutputValue') {
      e.row.data.planOutputId = null;
      e.row.data.planOutputValue = null;
    }
    e.row.data.statusRow = 'u';
    this.onValueChanged();
  }

  closeDialog() {
    if (!this.enabledSubmit) {
      this.dialogRef.close();
    } else {
      this.confirmDialog.confirm('Подтверждение', 'Имеются не сохраненные данные! Сохранить перед выходом?', true).subscribe(
        result => {
          if (result) {
            this.onSubobjectFormSubmit();
          } else if (result !== undefined) {
            this.dialogRef.close();
          }
        });
    }
  }

  EditingStart(e) {
    if (e.data && e.data.dictionaryId || (this.disableEditPlanValues && ["planInputValue", "planOutputValue"].includes(e.column.dataField)))
      e.cancel = true;
  }
}
