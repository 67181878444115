import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ManualInfo} from '../models/manual.model';
import {FileInfo} from '../models/file.model';
import * as saveAs from 'file-saver';

const api = '/api';

@Injectable()
export class ManualService {
  constructor(private http: HttpClient) {
  }

  public getManual(id: number): Observable<ManualInfo> {
    const params = new HttpParams()
      .set('id', String(id));
    return this.http.get<any>(`${api}/admin/manual`, {params});
  }
  public getManualLk(id: number): Observable<ManualInfo> {
    const params = new HttpParams()
      .set('id', String(id));
    return this.http.get<any>(`${api}/admin/manual-lk`, {params});
  }
  public saveManual(manual, id) {
    if (id) {
      return this.http.put<any>(`${api}/admin/manual`, manual);
    } else {
      return this.http.post<any>(`${api}/admin/manual`, manual);
    }
  }
  public saveManualLk(manual, id) {
    if (id) {
      return this.http.put<any>(`${api}/admin/manual-lk`, manual);
    } else {
      return this.http.post<any>(`${api}/admin/manual-lk`, manual);
    }
  }
  downloadFile(id: number) {
    this.getFile(id).subscribe(data => {

      const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: contentType});
      };

      const blob = b64toBlob(data.content);
      // const blobUrl = URL.createObjectURL(blob);
      saveAs(blob, data.name);
    });
  }

  public getFile(id: number): Observable<FileInfo> {
    const params = new HttpParams()
      .set('id', String(id));
    return this.http.get<FileInfo>(`${api}/admin/manual/file`, {params});
  }

  public checkCode(value: string): Observable<any> {
    const params = new HttpParams()
      .set('field', 'code')
      .set('value', value);
    return this.http.get<any>(`${api}/admin/manual/check`, {params});
  }
  public checkCodeLk(value: string): Observable<any> {
    const params = new HttpParams()
      .set('field', 'code')
      .set('value', value);
    return this.http.get<any>(`${api}/admin/manual-lk/check`, {params});
  }

  async getInstruction(id): Promise<any> {
    const params = new HttpParams().set('id', id);
    return await this.http.get(`${api}/admin/instruction`, {params}).toPromise();
  }
}
