import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

const api = '/api';
const columnsUnlocated = [
  {
    caption: 'Подразделение',
    dataField: 'branch_name',
    dataType: 'string',
    //width: 80,
    allowEditing: false
  }, {
    caption: 'Код статьи',
    dataType: 'string',
    dataField: 'plan_code',
    allowEditing: false,
    width: 120
  }, {
    caption: 'Наименование статьи',
    dataField: 'plan_name',
    dataType: 'string',
    allowEditing: false,
    // width: 100
  }, {
    caption: 'Год',
    dataField: 'year',
    dataType: 'string',
    allowEditing: false,
    width: 120
  }, {
    caption: 'Месяц',
    dataField: 'month_name',
    dataType: 'string',
    allowEditing: false,
    width: 120
  }, {
    caption: 'Остатки',
    dataField: 'sum',
    dataType: 'number',
    format: "###,###,###,###,##0",
    allowEditing: false
  }, {
    caption: 'Первоначальная дата распределения',
    dataField: 'orig_dist_date',
    dataType: 'datetime',
    width: 160,
    allowEditing: false
  }, {
    caption: 'Принудительная дата распределения',
    dataField: 'comp_dist_date',
    dataType: 'datetime',
    width: 160,
    allowEditing: false
  }, {
    caption: 'Документ-распределения затрат',
    dataField: 'documentNumber',
    dataType: 'string',
    width: 220,
    allowEditing: false
  }];
const columnsCommon = [
  {
    caption: 'Подразделение',
    dataField: 'branch_name',
    dataType: 'string',
    //width: 80,
    allowEditing: false
  }, {
    caption: 'Год',
    dataField: 'year',
    dataType: 'string',
    allowEditing: false,
    width: 120
  }, {
    caption: 'Сумма',
    dataField: 'sum',
    dataType: 'number',
    format: "###,###,###,###,##0",
    allowEditing: false
  }, {
    caption: 'Дата загрузки затрат',
    dataType: 'datetime',
    dataField: 'date_distribution',
    allowEditing: false
  }, {
    caption: 'Месяц',
    dataType: 'number',
    dataField: 'month',
    allowEditing: false
  }, {
    caption: 'Статья затрат',
    dataType: 'string',
    dataField: 'plan_name',
    allowEditing: false
  }
  ];

@Injectable()
export class AdminAreaService {
  constructor(private http: HttpClient) {
  }

  getAdminAreas(): Observable<any> {
    return this.http.get<Array<any>>(`${api}/admin/area/tree`);
  }

  public getColumnsOksDist(unlocated) {
    if (unlocated) {
      return columnsUnlocated;
    } else {
      return columnsCommon;
    }
    ;
  }

  getContragentList(filter?, take?, skip?): Observable<any> {
    let httpParams = new HttpParams();
    if (filter) {
      httpParams = httpParams.append('filter', JSON.stringify(filter) || '[]');
    }
    if (take) {
      httpParams = httpParams.append('take', take.toString());
    }
    if (skip) {
      httpParams = httpParams.append('skip', skip.toString());
    }
    return this.http.get<any>(`${api}/admin/contragent/list-lk`, {params: httpParams});
  }

  getAdminAreasLk(): Observable<any> {
    return this.http.get<Array<any>>(`${api}/admin/area/tree-lk`);
  }

  getOksDistribution1(unlocated?, sort?): Observable<any> {
    let httpParams = new HttpParams();
    if (unlocated) {
      httpParams = httpParams.append('unlocated', unlocated);
    }
    if (sort) {
      httpParams = httpParams.append('sort', JSON.stringify(sort));

    }
    return this.http.get<any>(`${api}/admin/oks-distribution`, {params: httpParams});
  }

  postSubscriptionForParus(costItems): Observable<any> {
    return this.http.post(`${api}/admin/parus`, {costItems});
  }
  getParusSubscriptionList(): Observable<any> {
    return this.http.get<any>(`${api}/admin/parus`);
  }

  getIntegrationProtocolList(params): Observable<any> {
    return this.http.get<any>(`${api}/admin/integration-protocol`, {params});
  }

  getTableList(): Observable<any> {
    return this.http.get<any>(`${api}/admin/tables`);
  }

  getProtocolTableColumnsInfo(id): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.get<any>(`${api}/admin/table-columns`, {params});
  }

  getIntegrationFileBody(bodyColumnName, nameColumnName, tableId, rowId): Observable<any> {
    let params = new HttpParams();
    params = params.append('rowId', rowId);
    params = params.append('bodyColumnName', bodyColumnName);
    params = params.append('nameColumnName', nameColumnName);
    params = params.append('tableId', tableId);
    return this.http.get<any>(`${api}/admin/integration/file-body`, {params, responseType: 'text' as 'json'});
  }
}
