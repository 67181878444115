import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import notify from 'devextreme/ui/notify';
import {AuthenticationService} from '../../../../../services/authentication.service';
import {ReportService} from '../../../../../services/report.service.service';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import {ObjectService} from '../../../../../services/object.service';
import {InvestService} from '../../../../../services/invest.service';
import {DxDataGridComponent, DxDropDownBoxComponent} from 'devextreme-angular';

@Component({
  selector: 'app-payment-ks2',
  templateUrl: './payment-ks2.component.html',
  styleUrls: ['./payment-ks2.component.scss']
})
export class PaymentKS2Component implements OnInit, OnDestroy {

  loading = false;
  visiblePopup;
  params = {
    userId: this.authService.USER.id,
    periodStart: null,
    periodEnd: null,
    filialIds: [],
    objectId: null
  };
  filialData;
  objectData;
  selectedObject;
  selectedFilial = [];
  needRefresh = false;

  @ViewChild('objectsGrid', {static: false}) objectsGrid: DxDataGridComponent;
  @ViewChild('dropDownBoxObjects', {static: false}) dropDownBoxObjects: DxDropDownBoxComponent;

  constructor(public reportService: ReportService,
              private authService: AuthenticationService,
              private objectService: ObjectService,
              private investService: InvestService
  ) {
    this.objectData = new DataSource({
      paginate: true,
      pageSize: 15,
      store: new CustomStore({
        key: 'id',
        load: (options) => {
          return this.investService.getObjects(options, false, true, this.params.filialIds).toPromise().then(data => {
            return {
              type: 'array',
              key: 'id',
              data: data.items || data,
              totalCount: data.count
            };
          }).catch(error => {
            throw error.error;
          });
        },
        byKey: () => {
          return new Promise(resolve => {
            resolve(this.selectedObject);
          });
        }
      })
    });
  }

  ngOnInit() {
  }

  getFilials() {
    if (!this.filialData) {
      Promise.all([this.objectService.getDepartmentList(2, null, null, null, null, null, true).toPromise().then(data => {
        this.filialData = data.items;
      })]).then(() => this.loading = false);
    }
  }

  show() {
    this.visiblePopup = true;
  }

  generateReport() {
    if (!this.params.objectId && (!this.params.filialIds || !this.params.filialIds.length) && (!this.params.periodStart || !this.params.periodEnd)) {
      return notify({message: 'Необходимо или выбрать филиалы или заполнить границы периода или выбрать объекты!', width: 800}, 'error', 1500);
    }
    if (this.params.periodStart && this.params.periodEnd && this.params.periodStart > this.params.periodEnd) {
      return notify({message: 'Начало периода не может быть больше окончания!', width: 800}, 'error', 1500);
    }
    const params = {
      userId: this.params.userId,
      ...(this.params.filialIds && this.params.filialIds.length && {filialId: this.params.filialIds} || {}),
      ...(this.params.objectId && {objectId: this.params.objectId} || {}),
      ...(this.params.periodStart && {dateStart: `${this.params.periodStart.getFullYear()}-${this.params.periodStart.getMonth() + 1}-${this.params.periodStart.getDate()}`} || {}),
      ...(this.params.periodEnd && {dateEnd: `${this.params.periodEnd.getFullYear()}-${this.params.periodEnd.getMonth() + 1}-${this.params.periodEnd.getDate()}`} || {})
    };
    const fileName = `Отчет по расчетам за выполненные работы${this.params.filialIds && this.params.filialIds.length ? this.params.filialIds.length === 1 && ` филиала ${this.selectedFilial[0].shortName}` || this.params.filialIds.length > 1 && ` филиалов ${this.selectedFilial.map(x => x.shortName).join(', ')}` : ''}${this.params.objectId ? ` объекта ${this.selectedObject.code}` : ''}${this.params.periodStart ? ` c ${this.params.periodStart.toLocaleDateString()}` : ''}${this.params.periodEnd ? ` по ${this.params.periodEnd.toLocaleDateString()}` : ''}`;
    this.loading = true;
    this.reportService.genFilePlansReport('payments_ks2', null, 'xlsx', fileName, params).toPromise().then(data => {
      window.open(`api/file/download?id=${data.uploadId}&destroy=true&upload=true`);
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      this.loading = false;
    });
  }

  onOpenedObjects() {
    if (this.needRefresh) this.objectsGrid.instance.refresh();
    this.needRefresh = false;
  }

  selectObject(e) {
    this.params.objectId = e.data.id;
    this.selectedObject = e.data;
    this.dropDownBoxObjects.instance.close();
  }

  selectFilial(e) {
    this.params.filialIds = e.selectedRowKeys;
    this.selectedFilial = e.selectedRowsData;
    this.needRefresh = true;
  }

  onContentReady(e) {
    e.component._$popupContent[0].style.paddingTop = '0';
  }

  ngOnDestroy() {
    this.params.periodStart = this.params.periodEnd = this.params.objectId = null;
    this.params.filialIds = [];
  }

}
