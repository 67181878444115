import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DocumentService} from "../../../../../services/document.service";
import {FinancePlansService} from "../../../../plans/finance-plans.service";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import {DxDropDownBoxComponent} from "devextreme-angular";

@Component({
  selector: 'app-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss']
})
export class SelectPlanComponent implements OnInit, OnDestroy {

  loading = false;
  plansColumns: any = [
    {caption: 'Номер документа', dataField: 'number'},
    {caption: 'Наименование', dataField: 'name'},
    {caption: 'Подразделение', dataField: 'departmentName'},
    {caption: 'Версия', dataField: 'version'},
    {caption: 'Дата создания', dataField: 'createdAt', dataType: 'date', format: 'shortDate', sortOrder: 'desc'},
    {caption: 'Статус', dataField: 'status'},
    {caption: 'Год документа', dataField: 'year'}
  ];
  dataSource;
  years = [];
  versions = [];
  ready = false;
  selectedItem;
  year;
  versionId;
  id;

  @Input() label = 'План';
  @ViewChild('dropDownBox', {static: false}) dropDownBox: DxDropDownBoxComponent;

  constructor(public documentService: DocumentService,
              public financePlansService: FinancePlansService) {
    this.dataSource = new DataSource({
      paginate: true,
      pageSize: 10,
      store: new CustomStore({
        load: options => {
          const res = {
            type: 'array',
            key: 'id',
            data: [],
            totalCount: 0
          };

          if (!this.ready) {
            return new Promise(resolve => {
              resolve(res);
            });
          }

          return this.documentService.getDocuments(null, 'pi1g', options).toPromise().then(data => {
            return {
              type: 'array',
              key: 'id',
              data: data.items,
              totalCount: data.count
            };
          }, error => {
            notify(error.error, 'error', 2500);
          });
        },
        byKey: key => {
          return new Promise(resolve => {
            resolve(this.selectedItem)
          })
        }
      })
    });
  }

  ngOnInit() {
  }

  setReady() {
    this.ready = true;
    this.dataSource.reload();
  }

  clearItem() {
    this.selectedItem = this.year = this.versionId = null;
  }

  ngOnDestroy(): void {
    if (this.dataSource)
      this.dataSource.dispose();
    this.years = this.versions = null;
  }

  displayExprBefore(item) {
    return item && `${item.number}`;
  }

  selectedRowPlans(e, plan) {
    this.versionId = null;
    this.selectedItem = e.data;
    this.year = e.data.year;
    this.id = e.data.id;
    this.dropDownBox.instance.close();
    this.reloadDocumentData();
  }

  async reloadDocumentData() {
    this.loading = true;
    try {
      const dataPlan = await Promise.all([
        this.financePlansService.getYears(this.selectedItem.id).toPromise(),
        this.documentService.getDocumentVersions(this.selectedItem.id).toPromise()
      ]);
      this.years = dataPlan[0];
      this.versions = dataPlan[1];

      if (this.years && this.years.length) {
        const _year = this.years.find(e => e.year == this.year);
        if (_year) {
          this.year = _year.year;
        } else {
          notify('В списке лет нет года плана!', 'error');
        }
      } else if (this.selectedItem) notify('Список лет пуст!', 'error');

      if (this.versions && this.versions.length) {
        const version = this.versions.find(e => e.statusId !== 5);
        this.versionId = version.id;
      }

    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

}
