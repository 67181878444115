import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import data from "devextreme";
import {map} from "rxjs/operators";

const api = '/api';

@Injectable({
  providedIn: 'root'
})
export class ReferenceInformationService {
  dismantlingData = {};
  urlDismantling = '/api/estimate/dismantling';
  constructor(private http: HttpClient) { }

  setHttpParams(params, queryParams) {
    if (queryParams.take) params = params.append('take', queryParams.take.toString());
    if (queryParams.skip) params = params.append('skip', queryParams.skip.toString());
    if (queryParams.filter) params = params.append('filter', JSON.stringify(queryParams.filter));
    if (queryParams.sort) params = params.append('sort', JSON.stringify(queryParams.sort));
    if (queryParams.group) params = params.set('group', JSON.stringify(queryParams.group));
    if (queryParams.requireTotalCount) params = params.set('requireTotalCount', 'true');
    return params;
  }

  public getList(apiGet: string): Observable<any> {
    return this.http.get<any>(apiGet);
  }

  public getTypeDocById(id: string): Observable<Array<any>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('id', id);
    return this.http.get<Array<any>>('api/document/type', { params: httpParams });
  }

  public getYear(year: any): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('year', year);
    return this.http.get<any>('api/plan/fact-close-period/getClosed', { params: httpParams });
  }
  public getYear1(): Observable<any> {
    return this.http.get<any>('api/plan/fact-close-period/list');
  }
  public postYear(year: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('year', year);
    return this.http.post<any>('api/plan/fact-close-period', 'any',{ params: httpParams });
  }
  public putYear(id,body): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('id', id);
    return this.http.put<any>('api/plan/fact-close-period',  body ,{params: httpParams});
  }
  public getContragentData(): Observable<any> {
    return this.http.get<any>(`/api/contract/contragent/list`)
  }

  public getDismantlingData(type) {
    if (!['column', 'group', 'unit'].includes(type)) {
      throw new Error('Type does not match any values');
    }
    if (['group', 'unit'].includes(type) && this.dismantlingData[type]) {
      return new Promise(resolve => resolve(this.dismantlingData[type]));
    } else {
      return this.http.get<any>(`/api/estimate/dismantling-${type}`).toPromise().then(data => {
        this.dismantlingData[type] = data;
        return data;
      });
    }
  }

  initRequest(url, method, options, addParams = null) {
    let params;
    switch (method) {
      case 'get':
        params = new HttpParams();
        params = this.setHttpParams(params, options);
        if (addParams) {
          Object.keys(addParams).forEach(key => params = params.append(key, addParams[key]));
        }
        break;
      case 'delete':
        params = {...options, ...(addParams || {})};
        break;
    }
    return this.http[method](url, params && {params} || options);
  }

  public methodDismantling(method, options, addParams = null) {
    return this.initRequest(this.urlDismantling, method, options, addParams);
  }

  public methodDismantlingType(method, options) {
    return this.initRequest(`${this.urlDismantling}-type`, method, options);
  }

  public methodDismantlingTypeValue(method, options, addParams = null) {
    return this.initRequest(`${this.urlDismantling}-type-value`, method, options, addParams);
  }
}
