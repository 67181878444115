import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class FormatNumberPipe implements PipeTransform {
  transform(value: number, thousand?: boolean): string {

    if (!thousand)
      return (+value).toLocaleString('ru-ru', {minimumFractionDigits: 2, maximumFractionDigits:2});
    else
      return (+value).toLocaleString('ru-ru', {minimumFractionDigits: 3, maximumFractionDigits:3});
  }
}
