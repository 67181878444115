import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserInfo} from '../models/user.model';
import {UserOfficialInfo} from '../models/user-official.model';

const api = '/api';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {
  }

  public getOfficial(id: number): Observable<UserOfficialInfo> {
    const params = new HttpParams()
      .set('id', String(id));
    return this.http.get<any>(`${api}/organization/official`, {params});
  }

  public getAccountUser(): Observable<any> {
    return this.http.get<any>('/api/account/user');
  }
  public checkGencontractor(gencontractorId: any): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('gencontractorId', gencontractorId);
    return this.http.get<any>('/api/admin/subcontractors-object', { params: httpParams });
  }
  public getGenSubcontractors(): Observable<any> {
    return this.http.get<any>(`/api/admin/subcontractors`)
  }
  public getUser(id: number, profile: boolean = false): Observable<UserInfo> {
    const params = new HttpParams()
      .set('id', String(id));
    const url = !profile ? `${api}/admin/user` : `${api}/account/user`;
    return this.http.get<any>(url, {params});
  }
  public getUserLk(id: any): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('id', id);
    return this.http.get<any>('/api/admin/user-lk', { params: httpParams });
  }
  public saveUser(user, id, profile: boolean = false) {
    const url = !profile ? `${api}/admin/user` : `${api}/account/user`;
    if (id) {
      return this.http.put<any>(url, user);
    } else {
      return this.http.post<any>(url, user);
    }
  }
  public saveUserLk(user, id) {
    if (id) {
      return this.http.put<any>(`${api}/admin/user-lk`, user);
    } else {
      return this.http.post<any>(`${api}/admin/user-lk`, user);
    }
  }

  public deleteUser(ids: any[]) {
    let params = new HttpParams();
    ids.forEach(x => params = params.append('id', x));
    return this.http.delete(`${api}/admin/user`, {params});
  }
  public deleteUserLk(ids: any[]) {
    let params = new HttpParams();
    ids.forEach(x => params = params.append('id', x));
    return this.http.delete(`${api}/admin/user-lk`, {params});
  }

  public lockUser(ids: any[]) {
    return this.http.post<any>(`${api}/admin/user/lock`, {ids});
  }
  public lockUserLk(ids: any[]) {
    return this.http.post<any>(`${api}/admin/user-lk/lock`, {ids});
  }

  public resetPassword(newPassword: string, currentPassword: string) {
    return this.http.patch(`${api}/account/password`, {newPassword, currentPassword});
  }

  public checkUser(field: string, value: string): Observable<any> {
    const params = new HttpParams()
      .set('field', field)
      .set('value', value);
    return this.http.get<any>(`${api}/admin/user/check`, {params});
  }
  public checkUserLk(field: string, value: string): Observable<any> {
    const params = new HttpParams()
      .set('field', field)
      .set('value', value);
    return this.http.get<any>(`${api}/admin/user-lk/check`, {params});
  }
  public checkADUser(value: string): Observable<any> {
    const params = new HttpParams()
      .set('sAMAccountName', value);
    return this.http.get<any>(`${api}/ad/user/check`, {params});
  }

  public unlockUser(id: number, lkk: boolean = false): Observable<UserInfo> {
    return this.http.post<any>(`/api/admin/${lkk && 'lk/' || ''}user/password/restore`, {id});
  }

}
