import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {CommentService} from "./comment.service";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {faTrash, faEdit, faHistory} from '@fortawesome/free-solid-svg-icons';
import {DxListComponent, DxTextAreaComponent} from "devextreme-angular";
import {SubscriptionLike} from "rxjs";

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(private service: CommentService, private changeDetector: ChangeDetectorRef) {
  }
  disabledSave = false;
  dataSource: any;
  store: CustomStore;
  commentId = 0;
  @Input() ID = 0;
  @Input() text = '';
  first = true;
  faTrash = faTrash;
  faEdit = faEdit;
  faHistory = faHistory;
  focusedComment;
  ToolTipText;
  TooltipTarget;
  subscriptions: SubscriptionLike[] = [];
  comment;
  @Input() type;
  @Input() height;
  changedData = false;
  @ViewChild('dxTextAreaComment', {static: true}) dxTextAreaComment: any;
  @ViewChild('dxListData', {static: true}) dxListData: DxListComponent;
  @Output() onChangeData = new EventEmitter<any>();
  @Output() afterViewInit = new EventEmitter<any>();
  popupVisibleCommentHistory;
  dataSourceCommentHistory;

  ngOnInit() {
    this.initDataSource();

    this.dataSourceCommentHistory = new DataSource({
      paginate: true,
      pageSize: 20,
      store: new CustomStore({
        key: 'id',
        load: (options) => {
          return this.service.getCommentHistory(this.commentId).toPromise().then(data => {
              return {
                type: 'array',
                key: 'id',
                data: data.items,
                totalCount: data.count
              };
            }
          ).catch(error => {
            throw error.error;
          });
        }
      })
    });

  }

  ngAfterViewInit(): void {
    this.afterViewInit.emit();
  }

  initDataSource() {
    this.dataSource = new DataSource({
      paginate: true,
      pageSize: 5,
      store: new CustomStore({
        key: "ID",
        load: (options) => {
          if(!this.ID) return new Promise((resolve, reject) => {
            resolve([]);
          });
          let filter;
          if (options.searchValue)
            filter = ['comment', 'contains', options.searchValue];
          return this.service.getComments(this.ID, this.type, options.take, options.skip, filter, options.sort).toPromise().then(data => {
              if (this.changedData) {
                this.onChangeData.emit(data.items.length > 0);
                this.changedData = false;
              }
              return data.items || [];
            }
          ).catch(error => {
            throw error.error;
          });
        }
      })
    });
  }

  deleteComment(e) {
    if (confirm('Удалить данный комментарий?')) {
      this.subscriptions.push(
        this.service.deleteComment(e.id).subscribe(data => {
          this.changedData = true;
          this.dxListData.instance.reload();
        })
      );
    }
  }

  editComment(e) {
    this.comment = e.comment;
    this.focusedComment = e;
  }

  undoEditComment() {
    this.comment = '';
    this.focusedComment = null;
  }

  saveComment() {
    this.subscriptions.push(
      this.service.postComment(this.type, this.ID, this.comment, this.focusedComment ? this.focusedComment.id : null).subscribe(data => {
        this.comment = '';
        this.changedData = true;
        this.focusedComment = null;
        this.dxListData.instance.reload();
      })
    );
  }

  refreshData(id, text) {
    if (id !== this.ID) {
      this.ID = id;
      this.text = text;
      this.dxListData.instance.reload();
    }
    this.disabledSave = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (subscription) => subscription.unsubscribe());
    this.subscriptions = [];
    if (this.dataSource) this.dataSource.dispose();
  }

  setTooltipTarget(targetId = null, text = null) {
    this.TooltipTarget = targetId ? '#'+targetId : null;
    this.ToolTipText = text;
  }

  showCommentHistory(e) {
    this.commentId = e.id;
    if (this.popupVisibleCommentHistory !== undefined)
      this.dataSourceCommentHistory.reload();
    this.popupVisibleCommentHistory = true;
  }
}
