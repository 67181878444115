import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnInit
} from '@angular/core';
import {AuthenticationService} from "../../services/authentication.service";

@Directive({
  selector: '[elementHideRight]'
})
export class HideDirective implements OnInit, AfterViewInit {
  @Input() appPage: any;

  constructor(
    private el: ElementRef,
    private authorizationService: AuthenticationService
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    let res = false;
    if(Array.isArray(this.appPage)) {
      this.appPage.forEach( x => {
        res = this.authorizationService.hasAccess(x);
        if(res) return;
      });
    } else
      res = this.authorizationService.hasAccess(this.appPage);
    if (!res) {
      this.el.nativeElement.style.display = 'none';
    }
  }

}
