import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ObjectService } from 'src/app/services/object.service';
import { FinancePlansService } from 'src/app/modules/plans/finance-plans.service';
import { SelectPlanComponent } from '../select-plan/select-plan.component';
import { ReportService } from 'src/app/services/report.service.service';
import notify from "devextreme/ui/notify";

@Component({
  selector: 'app-fin-result-info',
  templateUrl: './fin-result-info.component.html',
  styleUrls: ['./fin-result-info.component.scss']
})
export class FinResultInfoComponent implements OnInit {

  loading = false;
  visiblePopup;
  thousand = false;
  oks = true;
  params = {
    userId: this.authService.USER.id,
    filialIds: [],
    specialMarks: []
  };
  filialData;
  specialMarks;
  selectedFilial = [];
  selectedMark = [];
  needRefresh = false;
  @ViewChild('planApproved', {static: false}) planApproved: SelectPlanComponent;
  @ViewChild('planWork', {static: false}) planWork: SelectPlanComponent;

  constructor(
    private authService: AuthenticationService,
    private objectService: ObjectService,
    private planService: FinancePlansService,
    private reportService: ReportService) { }

  ngOnInit() {
  }

  show() {
    if (this.visiblePopup === undefined) {
      this.planApproved.setReady();
      this.planWork.setReady();
    }
    this.visiblePopup = true;
  }

  getFilials() {
    if (!this.filialData) {
      Promise.all([this.objectService.getDepartmentList(2, null, null, null, null, null, true).toPromise().then(data => {
        this.filialData = data.items;
      })]).then(() => this.loading = false);
    }
  }

  selectFilial(e) {
    this.params.filialIds = e.selectedRowKeys;
    this.selectedFilial = e.selectedRowsData;
    this.needRefresh = true;
  }

  getSpecialMarks() {
    if (!this.specialMarks) {
      this.planService.getSpecialMarkData().subscribe(data => {
        this.specialMarks = data.items;//.sort((n1,n2) => n2.name - n1.name)
      })
    }
  }

  selectSpecialMark(e) {
    this.params.specialMarks = e.selectedRowKeys;
    this.selectedMark = e.selectedRowsData;
    this.needRefresh = true;
  }

  generateReport(e) {
    console.log(this.planApproved)
    if (!this.planApproved.id || !this.planWork.id) {
      notify('Для выгрузки необходимо указать планы!', 'error');
      return;
    }
    this.loading = true;
    const params = {
      userId: this.params.userId,
      ...(this.planApproved && {IdPrev: `${this.planApproved.id}`} || {}),
      ...(this.planApproved && {YearPrev: `${this.planApproved.year}`} || {}),
      ...(this.planWork && {IdLast: `${this.planWork.id}`} || {}),
      ...(this.planWork && {YearLast: `${this.planWork.year}`} || {}),
      ...({Thousand: `${Number(this.thousand)}`}),
      ...({OksUks: `${Number(this.oks)}`}),
      ...(this.selectedMark && this.selectedMark.length && {SpecialMark: this.selectedMark.map(x => x.id)} || {}),
      ...(this.params.filialIds && this.params.filialIds.length && {FilialId: this.params.filialIds} || {})
    };
    // const id = `${this.planBefore.versionId}-${this.planBefore.year}-${this.planAfter.versionId}-${this.planAfter.year}-${Number(this.thousand)}-${Number(this.oks)}-0-1`
    const fileName = `Информация о финансовом результате по договорам ТП`;
    this.reportService.genFilePlansReport('pi1g_compare', null, 'xlsx', fileName, params).toPromise().then(data => {
      console.log(data)
      window.open(`api/file/download?id=${data.uploadId}&destroy=true&upload=true`);
    }).finally(() => {
      this.loading = false;
    });
  }

}
