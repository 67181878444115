import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material";
import {DiagramAgreementService} from "../../diagram-agreement/diagram-agreement.service";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {SubscriptionLike} from "rxjs";
import {DxTreeListComponent} from "devextreme-angular";
import notify from "devextreme/ui/notify";
import {
  faSnowman,
  faInfoCircle,
  faHome,
  faExclamationTriangle,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-choose-executor-new',
  templateUrl: './choose-executor-new.component.html',
  styleUrls: ['./choose-executor-new.component.scss']
})
export class ChooseExecutorComponentNew implements OnInit, OnDestroy, AfterViewInit {
  faUserTie = faUserTie;
  faHome = faHome;
  faExclamationTriangle = faExclamationTriangle;
  loading = true;
  dataSource: any;
  expandedRowKeys = [];
  store: CustomStore;
  subscriptions: SubscriptionLike[] = [];
  filterDepartment = [];
  selectedRowsData: any;
  first = true;
  faInfoCircle = faInfoCircle;
  warningMessage = 'Для данного подразделения не заданы исполнители по умолчанию!';
  @ViewChild('treeOrgList', {static: false}) treeOrgList: DxTreeListComponent;
  @Input() data ;
  result = {};
  @Output() personValueChanged = new EventEmitter<any>();

  constructor(private service: DiagramAgreementService) {
    this.dataSource = new DataSource({
      store: new CustomStore({
        key: 'id',
        load: (options) => {
          let _filter = null;
          if (options.parentIds) options.parentIds = options.parentIds.filter(x => x);
          if (!options.parentIds || options.parentIds.length === 0) _filter = options.filter;
          if (this.filterDepartment && this.filterDepartment.length > 0) {
            let arrayFilters = [];
            this.filterDepartment.forEach(x => {
              if (arrayFilters.length > 0) arrayFilters.push('or');
              arrayFilters.push(['id', '=', x], 'or', [['parentId', '=', x], 'and', ['id', '<', '0']]);
            });
            if (this.first || (options.parentIds && options.parentIds.includes(0))) {
              options.parentIds = [];
              _filter = arrayFilters;
            } else if (options.parentIds && options.parentIds.length === 1 && this.filterDepartment.includes(options.parentIds[0])) {
              _filter = ['id', '<', '0'];
            } else if (_filter && _filter[0] !== 'id') {
              _filter = [_filter, 'and', arrayFilters];
            } else if (_filter && _filter[0] === 'id' && _filter[2] === null) {
              return [];
            }
            this.first = false;
          }
          return this.service.getTreeOrg(options.parentIds, _filter, options.sort, this.data.department, this.data.agreement).toPromise().then(data => {
            return data.map(x => {

              if (this.data.department) {
                x.isDepartment = true;
                this.warningMessage = 'В данном подразделении нет сотрудников!';
              }

              if (!x.isDepartment) {
                let parent = this.treeOrgList.instance.getDataSource().items().find(p => p.key === x.parentId);
                if (parent) parent = parent.data;
                else if (!parent) parent = data.find(p => p.id === x.parentId);
                if (parent) {
                  x.departmentName = parent.name;
                  x.depShortName = parent.shortName;
                }
              }
              /*if (this.data.filterDepartment && this.data.filterDepartment.includes(x.id))
                x.parentId = null;*/
              return x;
            });
          }).catch(error => {
            throw error.error;
          })
        }
      })
    });
  }

  private btnChoice: any = null;

  ngOnInit() {
    if (this.data && this.data.idStage) {
      this.loading = true;
      this.subscriptions.push(this.service.getDefaultDepartment(this.data.idStage, this.data.idDoc).subscribe(data => {
        this.filterDepartment = data;
        data.forEach(x => this.expandedRowKeys.push(x));
        this.loading = false;
      }, error => {
        this.personValueChanged.emit();
        this.loading = false;
      }));
    } else this.loading = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (subscription) => subscription.unsubscribe());
    this.subscriptions = [];
    if (this.dataSource) this.dataSource.dispose();
  }

  ngAfterViewInit(): void {
  }

  onToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        hint: 'Выбрать',
        height: 36,
        width: 36,
        icon: 'check',
        onInitialized: (args: any) => {
          this.btnChoice = args.component;
        },
        onClick: this.onChoiceRows.bind(this)
      }
    }, {
      location: 'after',
      template: 'searchInfo',
      locateInMenu: 'auto',
      showText: 'inMenu'
    });

  }

  onCellPrepared(e) {
    if (e.rowType === "data" && e.columnIndex === 0 && !this.data.singleSelect) {
      if (this.data.hideSelect && e.cellElement.children.length > 0 && this.data.hideSelect.includes(e.key))
        (e.cellElement.children[0].children[e.cellElement.children[0].children.length - 1] as HTMLElement).style.display = "none";
      else if (e.data.isDepartment && Number(e.data.hasExecutors) === 0 && e.cellElement.children.length > 0)
        (e.cellElement.children[0].children[e.cellElement.children[0].children.length - 1] as HTMLElement).style.display = "none";
      else if (e.data.isDepartment && this.data.filterDepartment && !this.data.filterDepartment.includes(e.data.id) && e.cellElement.children.length > 0)
        (e.cellElement.children[0].children[e.cellElement.children[0].children.length - 1] as HTMLElement).style.display = "none";
    }
  }

  onRowPrepared(e) {
    if (e.rowType === "data") {
      if (this.data.hideSelect && this.data.hideSelect.includes(e.key))
        e.rowElement.style.backgroundColor = '#cbe7ff';
    }
  }

  onSelectionChanged(e) {
    if (this.data.singleSelect) {
      let disabled = false;
      if (e.selectedRowsData[0].isDepartment) {
        disabled = true;
      } else if (this.data.executors && this.data.executors.includes(e.selectedRowsData[0].id))
        disabled = true;
      this.btnChoice.option({
        disabled: disabled
      });
      if (disabled) {
        this.selectedRowsData = [];
        return;
      }
    }
    this.selectedRowsData = e.selectedRowsData;
  }

  onChoiceRows() {
    if (this.data.singleSelect) {
      if (this.data.selectedRowKeys.length === 0) {
        notify('Необходимо выбрать сотрудника!', 'error', 2500);
      } else if (this.data.executors && this.data.executors.includes(this.data.selectedRowKeys[0])) {
        notify('Данный сотрудник уже присутствует в списке!', 'error', 2500);
      } else {
        this.personValueChanged.emit({
          id: this.selectedRowsData[0].id * -1,
          departmentId: this.selectedRowsData[0].parentId,
          name: `${this.selectedRowsData[0].name} (${this.selectedRowsData[0].depShortName || this.selectedRowsData[0].departmentName || this.selectedRowsData[0].depName})`,
          departmentName: this.selectedRowsData[0].departmentName,
          phone: this.selectedRowsData[0].phone,
          email: this.selectedRowsData[0].email,
          userName: this.selectedRowsData[0].name
        });
      }
    } else {
      if (this.data.canSelectDepartment || this.data.department) {
        this.personValueChanged.emit(this.selectedRowsData);
      } else {
        const departments = this.selectedRowsData.filter(x => x.id > 0).map(x => x.id);
        let executors = this.selectedRowsData.filter(x => x.id < 0).map(x => {
          return {id: x.id * -1, name: `${x.name} (${x.depShortName || x.departmentName || x.depName})`}
        });
        if (departments.length > 0) {
          this.subscriptions.push(
            this.service.getExecutorsOfDepartment(departments).subscribe(data => {
              data.forEach(x => {
                if (!executors.find(e => e.id === x.id)) executors.push({
                  id: x.id,
                  name: `${x.fullName} (${x.depShortName || x.departmentName || x.depName})`
                });
              });
              if (this.data.hideSelect)
                executors = executors.filter(x => !this.data.hideSelect.includes(x.id * -1));
              this.personValueChanged.emit(executors);
            })
          );
        } else this.personValueChanged.emit(executors);
      }
    }
  }

  onEditorPreparing(e: any) {
    if (e.parentType === 'searchPanel') {
      e.editorOptions.onValueChanged = function (arg) {
        if (arg.value.length == 0 || arg.value.length >= 5) {
          e.component.searchByText(arg.value);
        }
      }
    }
  }

  refreshData() {
    this.expandedRowKeys = [];
    this.selectedRowsData = [];
    this.dataSource.reload();
  }

  clearData(): void {
    this.dataSource.clear();
  }
}
