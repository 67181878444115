import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthenticationService} from "../services/authentication.service";
import notify from "devextreme/ui/notify";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authenticationService.isLoggedIn()) {
      this.router.navigate(['/login']);
    }
    const res = this.authenticationService.hasAccess(state.url);
    if(res) {
      this.authenticationService.currentUrl = state.url;
      const page = (state.url.includes('?') ? state.url.substring(0, state.url.indexOf('?')) : state.url).replace(/([^a-z]+)/gi, '').toLowerCase();
      this.authenticationService.selectedPage = page;
      return res;
    }
    else {
      notify({message:'У Вас нет прав на просмотр данной страницы!', width: 500}, 'error', 2500);
      this.router.navigate([this.authenticationService.currentUrl]);
    }
  }
}
