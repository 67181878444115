import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard]
  }, {
    path: 'home2',
    loadChildren: () => import('./modules/home/home2.module').then(m => m.HomeModule2),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'documents',
    loadChildren: () => import('./modules/documents/documents.module').then(m => m.DocumentsModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'administration',
    loadChildren: () => import('./modules/service/service.module').then(m => m.ServiceModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'service',
    loadChildren: () => import('./modules/service/service.module').then(m => m.ServiceModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'administrationlkk',
    loadChildren: () => import('./modules/service/service.module').then(m => m.ServiceModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {lkk: true}
  },
  {
    path: 'subcontractors',
    loadChildren: () => import('./modules/service/service.module').then(m => m.ServiceModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {subcontractors: true}
  },
  {
    path: 'information',
    loadChildren: () => import('./modules/reference-information/reference-information.module').then(m => m.ReferenceInformationModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'tasks',
    loadChildren: () => import('./modules/tasks/tasks.module').then(m => m.TasksModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'agreements',
    loadChildren: () => import('./modules/diagram-agreement/diagram-agreement.module').then(m => m.DiagramAgreementModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'objects',
    loadChildren: () => import('./modules/object/object.module').then(m => m.ObjectModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'estimate',
    loadChildren: () => import('./modules/estimate/estimate.module').then(m => m.EstimateModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'plans',
    loadChildren: () => import('./modules/plans/plans.module').then(m => m.PlansModule),
    canActivate: [AuthGuard]
  }, {
    path: 'leader',
    loadChildren: () => import('./modules/leader/leader.module').then(m => m.LeaderModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'purchase',
    loadChildren: () => import('./modules/purchase/purchase.module').then(m => m.PurchaseModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard]
  }, {
    path: 'contracts',
    loadChildren: () => import('./modules/contracts/contracts.module').then(m => m.ContractsModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'objectssubobjects',
    loadChildren: () => import('./modules/subobjects/objects-subobjects.module').then(m => m.ObjectsSubobjectsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'finish',
    loadChildren: () => import('./modules/finish/finish.module').then(m => m.FinishModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'schedule',
    loadChildren: () => import('./modules/schedule/schedule.module').then(m => m.ScheduleModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'inspections',
    loadChildren: () => import('./modules/inspection/inspection.module').then(m => m.InspectionModule),
    canActivate: [AuthGuard]
  }, {
    path: 'tables',
    loadChildren: () => import('./modules/tables/tables.module').then(m => m.TablesModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  }, {
    path: 'invest',
    loadChildren: () => import('./modules/invest/invest.module').then(m => m.InvestModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  }, {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
