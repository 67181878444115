import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import {DocumentService} from "../../../../../services/document.service";
import {FinancePlansService} from "../../../../plans/finance-plans.service";
import {DxDropDownBoxComponent} from "devextreme-angular";
import {SelectPlanComponent} from "../select-plan/select-plan.component";
import {ReportService} from "../../../../../services/report.service.service";

@Component({
  selector: 'app-compare-plans',
  templateUrl: './compare-plans.component.html',
  styleUrls: ['./compare-plans.component.scss']
})
export class ComparePlansComponent implements OnInit, OnDestroy {

  loading = false;
  visiblePopup;
  thousand = false;
  oks = true;

  @ViewChild('planBefore', {static: false}) planBefore: SelectPlanComponent;
  @ViewChild('planAfter', {static: false}) planAfter: SelectPlanComponent;

  constructor(public reportService: ReportService) {}

  ngOnInit() {
  }

  show() {
    if (this.visiblePopup === undefined) {
      this.planBefore.setReady();
      this.planAfter.setReady();
    }
    this.visiblePopup = true;
  }

  ngOnDestroy(): void {
    ['planAfter', 'planBefore'].forEach(component => {
      if (this[component])
        this[component] = null;
    });
  }

  generateReport(e) {
    if (!this.planBefore.versionId || !this.planAfter.versionId) {
      notify('Для сравнения нужно указать план с исходными данными и план с откорректированными данными!', 'error');
      return;
    }
    this.loading = true;
    const id = `${this.planBefore.versionId}-${this.planBefore.year}-${this.planAfter.versionId}-${this.planAfter.year}-${Number(this.thousand)}-${Number(this.oks)}-0-1`
    const fileName = `Сравнение планов ПИ-1Г ${this.planBefore.selectedItem.number} и ${this.planAfter.selectedItem.number}`;
    this.reportService.genFilePlansReport('plans-compare', id, 'xlsx', fileName).toPromise().then(data => {
      window.open(`api/file/download?id=${data.uploadId}&destroy=true&upload=true`);
    }).finally(() => {
      this.loading = false;
    });
  }

}
