import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from '../../../services/authentication.service';
import {UserComponent} from '../../service/service/users/user/user.component';
import {MatDialog} from '@angular/material';
import {Router} from '@angular/router';
import {
  faBars,
  faCaretDown,
  faCog,
  faDoorOpen,
  faUserTie,
  faQuestionCircle,
  faExclamationTriangle,
  faNewspaper
} from '@fortawesome/free-solid-svg-icons';
import notify from 'devextreme/ui/notify';
import {InvestService} from '../../../services/invest.service';
import {UserLkComponent} from '../../service/service/users/user-lk/user-lk.component';
import {AdminAreaService} from '../../../services/admin-area.service';
import {ManualService} from '../../../services/manual.service';
import {FinancePlansService} from '../../plans/finance-plans.service';
import {SubscriptionLike} from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import {ObjectService} from '../../../services/object.service';
import {DxDropDownBoxComponent} from 'devextreme-angular';
import {DocumentService} from '../../../services/document.service';
import {environment} from "../../../../environments/environment";
import {ReportService} from "../../../services/report.service.service";
import {WhatnewsComponent} from "../whatnews/whatnews.component";
import {ReportHeaderComponent} from "../report-header/report-header.component";
import {ConfirmComponent} from "../components/confirm/confirm.component";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [ManualService, DocumentService, FinancePlansService]
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  systemName = 'АИС КАПСТРОЙ';
  faUserTie = faUserTie;
  faExclamationTriangle = faExclamationTriangle;
  faBars = faBars;
  faCog = faCog;
  added = false;
  removed = false;
  faDoorOpen = faDoorOpen;
  faCaretDown = faCaretDown;
  faQuestionCircle = faQuestionCircle;
  faNewspaper = faNewspaper;
  clearTimePopupVisible: boolean = false;
  contractDataSource;
  filialDataSource;
  selectedFilial;
  clearTimeParams = {filialId: null, contractId: null, workStateId: null, startDate: null, endDate: null};
  @ViewChild('sidenav', {static: false}) sidenav;
  @ViewChild('contractDropDown', {static: false}) contractDropDown: DxDropDownBoxComponent;
  @ViewChild('filialDropDown', {static: false}) filialDropDown: DxDropDownBoxComponent;
  @ViewChild('workStateDropDown', {static: false}) workStateDropDown: DxDropDownBoxComponent;
  @ViewChild('whatnews', {static: false}) whatnews: WhatnewsComponent;
  @ViewChild('reportHeader', {static: false}) reportHeader: ReportHeaderComponent;
  datePipe = new DatePipe('en-US');

  navigationList = [
    // {link: 'home', label: 'Домой'},
    {link: 'invest', label: 'ИП', tooltip: 'ИнвестПроекты'},
    {link: 'objects', label: 'Объекты', lkk: true},
    {link: 'objectssubobjects', label: 'Подобъекты', lkk: true},
    {
      link: 'contracts', label: 'Договоры', lkk: true,
      items: [
        {link: 'contracts', label: 'Договоры расходные', lkk: true},
        {link: 'contracts/rn', label: 'Договоры доходные', lkk: true},
        {link: 'contracts/tp', label: 'Договоры ТП'},
        {link: 'contracts/ds', label: 'Создание ДС'},
        {link: 'contracts/payments', label: 'Платежи'}
      ]
    },
    {
      link: 'plans', label: 'Планы',
      items: [
        {link: 'plans', label: 'Планы КС'},
        {link: 'plans/planspi1g', label: 'Планы ПИ-1Г'},
        {link: 'plans/investment', label: 'Планы КВЛ (из ПИ-1Г)'},
        {link: 'plans/investprogram', label: 'ИнвестПрограмма'}
      ]
    },
    {link: 'purchase', label: 'Закупки'},
    {link: 'documents', label: 'Документы', lkk: true},
    {link: 'tasks', label: 'Задачи'},
    {
      link: 'tables',
      label: 'Таблицы',
      lkk: true,
      items: [
        {link: 'tables/mup-cpid-sti', label: 'МУП ЦПИД по СТИ'},
        {link: 'tables/coef-calc', label: 'Таблица по расчету коэф. обновления'},
        {link: 'tables/characteristics-gkrtt', label: 'Характеристики для ГКРТТ'},
        {link: 'tables/sbis-table', label: 'Документы для подписи в СБИС'},
        {link: 'tables/nzs', label: 'НЗС по интеграции из ИСУ Парус'},
        {link: 'tables/ecp-table', label: 'Документы для подписи в ЭЦП'},
      ]
    },
    {
      link: 'reports', label: 'Отчетность',
      items: [
        {
          link: 'reports/tp-contract-execution', label: 'Исполнение договоров ТП', onClick: () => {
            this.clearTimePopupVisible = true;
            this.loadClearTimeData();
          }
        }, {
          link: 'reports/plans', label: 'Отчеты по планированию', onClick: () => {
            this.plansReports = true;
            // this.plansPopup = true;
            // this.documentsPlanDS.reload();
          }
        }, {
          link: 'reportmonitoringact', label: 'Мониторинг отработки актов', onClick: () => {
            this.reportHeader.showReport('monitoringAct');
          }
        }, {
          link: 'paymentks', label: 'Отчет по расчетам за выполненные работы', onClick: () => {
            this.reportHeader.showReport('paymentKS2');
          }
        }, {
          link: 'finresultinfo', label: 'Информация о финансовом результате по договорам ТП', onClick: () => {
            this.reportHeader.showReport('finResultInfo');
          }
        }]
    },
    {
      link: 'information', label: 'НСИ',
      items: [
        {link: 'information/objects', label: 'Объекты'},
        {link: 'information/subobjects', label: 'Характеристики подобъектов'},
        {link: 'information/investprogram', label: 'ИнвестПрограмма'},
        {link: 'information/documents', label: 'Документы и согласование'},
        {link: 'information/tasks', label: 'Задачи'},
        {link: 'information/plan', label: 'Планы'},
        {link: 'information/others', label: 'Прочее'},
        {link: 'agreements/editor', label: 'Маршруты'},
        {link: 'schedule/templates', label: 'Шаблоны графиков'},
        {link: 'information/schemes', label: 'Типовые решения'},
        {link: 'information/whatnews', label: 'Информационные сообщения'}]
    },
    {
      link: 'service', label: 'Сервис', lkk: true,
      items: [
        {link: 'administration', label: 'Администрирование'},
        {link: 'administrationlkk', label: 'Администрирование ЛК'},
        {link: 'subcontractors', label: 'Привязка субподрядчика к объектам'},
        {
          link: 'service/invest', label: 'Загрузить данные инвестпрограммы', onClick: () => {
            this.popupInvest = true;
            this.getPprList();
          }
        },
        {
          link: 'service/parus', label: 'Подписаться на изменения в Парус', onClick: () => {
            this.popupParus = true;
            this.getParusSubscriptionList();
          }
        },
        {
          link: 'service/integration-protocol', label: 'Просмотр протоколов интеграций'
        }, {link: 'service/oksdistribution', label: 'Распределение затрат ОКС/УКС'}]
    }
  ];
  navigation;
  popupAbout = false;
  popupInvest = false;
  popupParus = false;
  pprList: any;
  pprValue: any;
  loading = false;
  sidebarCollapse = true;
  lkk: boolean;
  isDeleted: boolean;
  parusInfo: any;
  items: string[];
  subscriptions: SubscriptionLike[] = [];
  version;
  workStateDataSource;
  selectedItem;
  documentsPlanDS;
  plansPopup;
  documentsYearsDS;
  data: any;
  year: any;
  isGridBoxOpened: boolean;
  checkboxTysRubDefaultValue: boolean;
  checkboxOksUksDefaultValue: boolean;
  reportPlans = {
    stp: false,
    tp: false,
    ggf: false,
    gup: false,
    pof: false,
    onm: false,
    rps: false,
    prd: false,
    swp: false,
    prds: false,
    swod: false
  };
  plansReports: boolean;
  workState;
  contract;

  plansColumns: any = [
    {caption: 'Номер документа', dataField: 'number'},
    {caption: 'Наименование', dataField: 'name'},
    {caption: 'Подразделение', dataField: 'departmentName'},
    {caption: 'Версия', dataField: 'version'},
    {caption: 'Дата создания', dataField: 'createdAt', dataType: 'date', format: 'shortDate', sortOrder: 'desc'},
    {caption: 'Статус', dataField: 'status'},
    {caption: 'Год документа', dataField: 'year'}
  ];

  constructor(private router: Router,
              public authService: AuthenticationService,
              public dialog: MatDialog,
              public investService: InvestService,
              public adminService: AdminAreaService,
              public objectService: ObjectService,
              public plansService: FinancePlansService,
              private manualservice: ManualService,
              public documentService: DocumentService,
              public financePlansService: FinancePlansService,
              public reportService: ReportService
  ) {
    this.systemName = environment.name !== 'infoenergo' ? '<b>АИС КС</b> – <b>А</b>втоматизированная <b>И</b>нформационная <b>С</b>истема <b>К</b>апитального <b>С</b>троительства' :
      '<b>АИС КапС.Pro</b> – <b>А</b>втоматизированная <b>И</b>нформационная <b>С</b>истема управления процессами <b>К</b>апитального <b>С</b>троительства'
    this.lkk = this.authService.USER.lkk;
    this.isDeleted = this.authService.USER.isDeleted;
    this.documentsPlanDS = new DataSource({
      paginate: true,
      pageSize: 10,
      store: new CustomStore({
        load: options => {
          const res = {
            type: 'array',
            key: 'id',
            data: [],
            totalCount: 0
          };

          if (!this.plansPopup) {
            return new Promise(resolve => {
              resolve(res);
            });
          }

          return this.documentService.getDocuments(null, 'pi1g', options).toPromise().then(data => {
            return {
              type: 'array',
              key: 'id',
              data: data.items,
              totalCount: data.count
            };
          }, error => {
            notify(error.error, 'error', 2500);
          });
        },
        byKey: key => {
          return new Promise(resolve => {
            resolve(this.selectedItem)
          })
        }
      })
    });
    this.documentsYearsDS = new DataSource({
      key: 'id',
      store: new CustomStore({
        load: (options) => {
          const res = {
            type: 'array',
            key: 'year',
            data: []
          };

          if (!this.plansPopup || !this.selectedItem) {
            return new Promise(resolve => {
              resolve(res);
            });
          }

          return this.financePlansService.getYears(this.selectedItem.id).toPromise().then(data => {
              return {
                type: 'array',
                key: 'year',
                data: data
              };
            }
          ).catch(error => {
            throw error.error;
          });
        },
        onLoaded: result => {
          if (result['data'] && result['data'].length) {
            const _year = result['data'].find(e => e.year == this.selectedItem.year);
            if (_year) {
              this.year = _year.year;
            } else {
              notify('В списке лет нет года плана!', 'error');
            }
          } else if (this.plansPopup && this.selectedItem) notify('Список лет пуст!', 'error');
        }
      })
    });
  }

  ngOnInit() {
    if (this.authService.USER.lkk) {
      this.navigation = this.navigationList.filter(x => x.lkk);
    } else {
      this.navigation = this.navigationList;
    }

    this.subscriptions.push(this.authService.getApplicationVersion().subscribe(data => {
      this.version = data.version;
    }));
  }

  valueValidation(params) {
    console.log(params);
  }

  openProfile() {
    if (this.lkk) {
      this.dialog.open(UserLkComponent, {
        maxWidth: '1280px',
        maxHeight: '90vh',
        data: {id: this.authService.USER.id, profile: true},
        disableClose: true
      });
    } else {
      this.dialog.open(UserComponent, {
        data: {id: this.authService.USER.id, profile: true},
        disableClose: true
      });
    }
  }

  onLogout() {
    this.authService.doLogoutUser();
  }

  goHome() {
    this.router.navigate(['home']);
  }

  getSelectedPage(page) {
    this.sidebarCollapse = true;
    if (page === 'service/invest') {
      this.popupInvest = true;
      this.getPprList();
    } else if (page === 'service/parus') {
      this.popupParus = true;
    } else {
      return page ? page.replace(/([^a-z]+)/gi, '').toLowerCase() : '';
    }
  }

  getParusSubscriptionList() {
    this.loading = true;
    this.adminService.getParusSubscriptionList().subscribe(data => {
      this.parusInfo = data;
      this.items = [...this.parusInfo];
      this.loading = false;
    });

  }

  getPprList() {
    this.loading = true;
    this.investService.getPprList().subscribe(data => {
      console.log(data);
      if (data && data.length > 0) {
        this.pprList = data;
        this.pprValue = 'План перспективного развития на 2020-2024гг (ffe27e83-f81c-41ae-9699-2cb3026e4c5b)';
      }
      notify('Cписок утвержденных инвестпрограмм успешно загружен!', 'success', 1500);
      this.loading = false;
    }, () => {
      notify('Cписок утвержденных инвестпрограмм не загружен! Просьба повторить позднее', 'error', 3500);
      this.loading = false;
    });
  }

  loadInvest() {
    this.loading = true;
    this.investService.process(this.pprValue, undefined).subscribe(data => {
      notify('Данные инвестпрограммы успешно загружены!', 'success', 1500);
      this.loading = false;
    }, error => {
      notify('Данные инвестпрограммы не загружены! Просьба повторить позднее', 'error', 3500);
      this.loading = false;
    });
  }

  checkPageClass(item) {
    if (item.items) {
      return item.items.map(x => x.link.replace(/([^a-z]+)/gi, '').toLowerCase()).includes(this.authService.selectedPage);
    } else {
      return this.authService.selectedPage === item.link.replace(/([^a-z]+)/gi, '').toLowerCase();
    }
  }

  clickItem(item) {
    if (!item.items) {
      this.sidebarCollapse = true;
      this.router.navigate([item.link]);
    }
  }

  showAbout() {
    this.popupAbout = true;
  }

  sidebarButtonClick() {
    this.sidebarCollapse = !this.sidebarCollapse;
  }

  onTextBoxValueChanged(e) {
    if (this.added) {
      this.added = false;
      const value = [...e.previousValue, ...e.value];
      this.parusInfo = [...new Set(value)];
    } else {
      this.removed = true;
      this.parusInfo = e.value;
    }
  }

  onCustomItemCreating(e) {
    const newValue = e.text;
    this.parusInfo.push(newValue);
    this.items.push(newValue);
    e.customItem = newValue;
    this.added = true;
  }

  subscribeForParusChanges(e) {
    const costItems = this.parusInfo ? this.parusInfo : [];
    this.adminService.postSubscriptionForParus(costItems).subscribe(data => this.popupParus = false);
  }

  loadClearTimeData() {
    this.contractDataSource = new DataSource({
      paginate: true,
      pageSize: 5,
      store: new CustomStore({
        key: 'id',
        load: (options) => {
          return this.objectService.getConnectionContractList(options).toPromise().then(data => {
              return {
                data: data.items,
                totalCount: data.count
              };
            }
          ).catch(error => {
            throw error.error;
          });
        },
        byKey: () => {
          return new Promise(resolve => {
            resolve(this.contract);
          });
        }
      })
    });

    this.filialDataSource = new DataSource({
      paginate: true,
      pageSize: 5,
      store: new CustomStore({
        key: 'id',
        load: (options) => {
          return this.objectService.getDepartmentList(2).toPromise().then(data => {
              return data.items;
            }
          ).catch(error => {
            throw error.error;
          });
        },
        byKey: () => {
          return new Promise(resolve => {
            resolve(this.selectedFilial);
          });
        }
      })
    });
    this.workStateDataSource = new DataSource({
      paginate: true,
      pageSize: 5,
      store: new CustomStore({
        key: 'id',
        load: (options) => {
          return this.objectService.getWorkStateList(true).toPromise().then(data => {
              return {
                data: data.items,
                totalCount: data.count
              };
            }
          ).catch(error => {
            throw error.error;
          });
        },
        byKey: () => {
          return new Promise(resolve => {
            resolve(this.workState);
          });
        }
      })
    });
  }


  async getInstruction() {
    try {
      this.loading = true;
      const data = await this.manualservice.getInstruction(this.authService.selectedPage);
      if (data && data.length) {
        if (data.length === 1) {
          window.open(`/api/admin/manual/file?id=${data[0].id}`);
        }
      } else {
        notify({message: 'Для данного окна инструкция не найдена!', width: 800}, 'error', 1500);
      }
      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (subscription) => subscription.unsubscribe());
    this.subscriptions = [];
    if (this.reportHeader) this.reportHeader = null;
  }

  generateClearTimeReport() {
    if (this.clearTimeParams.endDate && this.clearTimeParams.startDate && this.clearTimeParams.startDate > this.clearTimeParams.endDate) {
      return notify('Дата "с" не может быть больше даты "по"', 'error', 2000);
    }
    window.open(
      window.location.origin + `/#/reports/tp-contract-execution/${this.clearTimeParams.filialId || 0}/${this.clearTimeParams.contractId || 0}/${this.clearTimeParams.workStateId || 0}/${this.clearTimeParams.startDate && this.clearTimeParams.startDate.toLocaleDateString().replace(/\//g, '.') || '0'}/${this.clearTimeParams.endDate && this.clearTimeParams.startDate.toLocaleDateString().replace(/\//g, '.') || '0'}`,
      '_blank'
    );
    this.clearTimePopupVisible = false;
  }

  onRowClick(e, data, id, grid) {
    this[data] = e.data;
    this.clearTimeParams[id] = e.key;
    this[grid].instance.close();
  }

  onPopupClose(e) {
    this.clearTimePopupVisible = false;
    this.clearTimeParams = {filialId: null, workStateId: null, contractId: null, startDate: null, endDate: null};
  }

  displayExpr(item) {
    return item && `${item.number}`;
  }

  selectedRowPlans(e) {
    this.selectedItem = e.data;
    this.year = null;
    this.documentsYearsDS.reload();
    this.isGridBoxOpened = false;
  }

  onHiddenReportPlan(e) {
    this.selectedItem = this.year = null;
    this.documentsYearsDS.reload();
  }

  onItemClick(e) {
    let showPages = '';
    Object.keys(this.reportPlans).forEach((x, ind) => {
      if (this.reportPlans[x]) {
        showPages += showPages ? `-${ind}` : ind;
      }
    })
    if (!showPages) {
      notify({message: 'Необходимо указать листы для печати!', width: 600}, 'info', 1500);
      return;
    }
    this.loading = true;
    const id = `${this.selectedItem.id}-${this.year}-${Number(this.checkboxTysRubDefaultValue)}-${Number(this.checkboxOksUksDefaultValue)}-${showPages}`
    const fileName = 'Отчет по планам';
    this.reportService.genFilePlansReport('plans', id, 'xlsx', `Отчет по планам ${this.selectedItem.number} ${this.selectedItem.name}`).toPromise().then(data => {
      window.open(`api/file/download?id=${data.uploadId}&destroy=true&upload=true`);
    }).finally(() => {
      this.loading = false;
    });
  }

  onPlanningPopupOpen(e) {
    this.checkboxOksUksDefaultValue = true;
    this.checkboxTysRubDefaultValue = false;
    Object.keys(this.reportPlans).forEach(x =>
      this.reportPlans[x] = false
    );
  }

  plansPopupOpen() {
    this.plansPopup = true;
    this.documentsPlanDS.reload();
  }

  showNews() {
    this.whatnews.show();
  }

  checkUserNews(e) {
    const elem = document.getElementById('whatnews-blink');
    if (elem) {
      elem.classList[e ? 'add' : 'remove']('active');
    }
  }

  ngAfterViewInit(): void {
    if (this.authService.USER.passwordExpiresWarning) {
      const confirmedPasswordError =  localStorage.getItem(this.authService.PASSWORD_ERROR);
      const currentMilliseconds = (new Date()).setHours(0, 0, 0, 0);
      if (!confirmedPasswordError || Number(confirmedPasswordError) !== currentMilliseconds) {
        const dialogRef = this.dialog.open(ConfirmComponent, {
          data: { title: `Смена пароля`,
            message: `Ваш пароль действителен до ${this.datePipe.transform(this.authService.USER.passwordExpires, 'dd.MM.yyyy')}.
            После истечения срока доступ будет заблокирован.`,
            onlyOk: true},
          disableClose: true
        });
        dialogRef.afterClosed().toPromise().then( data => {
          localStorage.setItem(this.authService.PASSWORD_ERROR, currentMilliseconds.toString());
        });
      }
    }
  }
}
