import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ObjectService} from "../../../services/object.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import {faEye} from '@fortawesome/free-solid-svg-icons';
import {DocumentComponent} from "../../documents/document/document.component";
import {DxDataGridComponent} from "devextreme-angular";

@Component({
  selector: 'app-linked-inv-numbers',
  templateUrl: './linked-inv-numbers.component.html',
  styleUrls: ['./linked-inv-numbers.component.scss']
})
export class LinkedInvNumbers implements OnInit {
  loading = true;
  dataSource: any;
  totalCount = 0;
  number: string;
  faEye = faEye;
  fieldsToMerge = ['contractNumber'];
  mergedElements = {};
  @ViewChild('contractsList', {static: false}) contractsList: DxDataGridComponent;

  constructor(public dialogRef: MatDialogRef<LinkedInvNumbers>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog, public service: ObjectService) {
    this.loadData();
  }

  ngOnInit() {
  }

  loadData() {
    this.dataSource = new DataSource({
      paginate: true,
      pageSize: 15,
      store: new CustomStore({
        key: 'id',
        load: (options) => {
          return this.service.getLinkedInvNumbers(options, this.data.id).toPromise().then(data => {
            if (data.count) {
              this.totalCount = data.count;
            }
            return {
              type: 'array',
              key: 'id',
              data: data.items,
              totalCount: data.count
            };
          }).catch(error => {
            throw error.error;
          }).finally(() => this.loading = false);
        }
      })
    });
  };

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      showText: "inMenu",
      widget: "dxButton",
      options: {
        icon: "refresh",
        text: "Обновить",
        hint: 'Обновить',
        onClick: () => {
          this.contractsList.instance.refresh();
        }
      }
    })
  }

  openObject(e) {
    window.open(window.location.origin + '/#/objects/card/' + e.objId + '/subobjects');
  }

  onCellPrepared(cellInfo) {
    if (cellInfo.rowType === 'data') {
      if (this.fieldsToMerge.includes(cellInfo.column.dataField)) {
        this.mergedCell(cellInfo);
      } else if (cellInfo.rowIndex) {
        cellInfo.cellElement.classList.add(`cell-border-top-default`);
        if(this.dataSource._totalCount === cellInfo.data.number){
          cellInfo.cellElement.classList.add(`cell-border-bottom-default`);
        }
      }
    }
  }

  mergedCell(cellInfo) {
    if (!this.mergedElements[cellInfo.data.number])
      this.mergedElements[cellInfo.data.number] = {};
    this.mergedElements[cellInfo.data.number][cellInfo.column.dataField] = cellInfo;

    if (cellInfo.data.number > 1) {
      let prevCell = this.mergedElements[cellInfo.data.number - 1] ? this.mergedElements[cellInfo.data.number - 1][cellInfo.column.dataField] : null;
      if (prevCell && prevCell.data.contractNumber === cellInfo.data.contractNumber) {
        let color = cellInfo.data.objectNum % 2 === 0 ? 'base' : 'white';
        prevCell.cellElement.classList.add(`cell-border-bottom-${color}`);
        cellInfo.cellElement.classList.add(`cell-border-top-${color}`);
        //cellInfo.cellElement.classList.add(`cell-merged-content`);
        cellInfo.cellElement.innerHTML = '';
      } else cellInfo.cellElement.classList.add(`cell-border-top-default`);
    }
    if(this.dataSource._totalCount === cellInfo.data.number){
      cellInfo.cellElement.classList.add(`cell-border-bottom-default`);
    }
  }

  openDocument(e) {
    console.log(e);
    if (e) {
      const dialogRef = this.dialog.open(DocumentComponent, {
        minWidth: '30%',
        maxWidth: '85%',
        maxHeight: '90vh',
        disableClose: false,
        data: {id: e.id}
      });
      dialogRef.afterClosed().subscribe();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
