import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateGroupedItemsService {

  constructor() {
  }

  createItems(item, index, values) {
    item = {key: values[index], count: 1};
    if (values.length - 1 !== index) {
      if (!item.items) item.items = [];
      item.items = item.items.concat([this.createItems(item, index + 1, values)]);
    }
    return item;
  }

  getGroupedDate(items: any) {
    const newItems = [];
    let lastValues;
    for (const i of items) {
      if (!i.key) {
        continue;
      }

      const _date = new Date(i.key);
      const values = [_date.getFullYear(), _date.getMonth() + 1, _date.getDate()];
      if (i.key.length > 10) {
        values.push(_date.getHours());
        values.push(_date.getMinutes());
      }

      if (!lastValues || lastValues[0] !== values[0]) {
        newItems.push(this.createItems({}, 0, values));
      } else {
        let _item = newItems[newItems.length - 1];
        for (let i = 1; i < values.length; i++) {
          if (values[i] !== lastValues[i]) {
            _item.items.push(this.createItems({}, i, values));
            break;
          }
          _item = _item.items[_item.items.length - 1];
        }
      }
      lastValues = values;
    }
    return newItems;
  }
}
