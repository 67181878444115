import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import {CommonService} from "../common.service";
import {DxDataGridComponent} from "devextreme-angular";
import notify from "devextreme/ui/notify";

@Component({
  selector: 'app-whatnews',
  templateUrl: './whatnews.component.html',
  styleUrls: ['./whatnews.component.scss']
})
export class WhatnewsComponent implements OnInit {

  dataSource;
  visibleWhatnews;
  loading = false;
  @ViewChild('gridWhatnews', {static: false}) gridWhatnews: DxDataGridComponent;
  selectedRowId;
  newsBody;
  showArchiveRows = false;
  showContentNews = false;
  whatnewsFiles = [];
  lastId;
  @Output() checkUserNews = new EventEmitter<any>();

  backButtonOptions = {
    type: 'back',
    onClick: () => {
      this.showContentNews = false;
    },
  };

  constructor(private service: CommonService) {
    this.dataSource = new DataSource({
      paginate: true,
      pageSize: 15,
      store: new CustomStore({
        key: 'id',
        load: (options) => {
          return this.service.getWhatnewsUserList(options, this.showArchiveRows).toPromise().then(data => {
              if (data.items && data.items.length && (!this.lastId || this.lastId < data.items[0].id)) {
                this.lastId = data.items[0].id;
                localStorage.setItem('whatnewsLastId', this.lastId);
                this.checkUserNews.emit(false);
              }
              return {
                type: 'array',
                key: 'id',
                data: data.items,
                totalCount: data.count
              };
            }
          ).catch(error => {
            throw error.error;
          });
        }
      })
    });
  }

  ngOnInit() {
    this.lastId = localStorage.getItem('whatnewsLastId');
    this.service.checkWhatnewsUser(this.lastId).toPromise().then(data => {
      if (data.count) {
        notify('Имеются новые сообщения!', 'success', 2500);
        this.checkUserNews.emit(!!data.count);
      }
    });
  }

  public show() {
    if (this.visibleWhatnews !== undefined) {
      this.dataSource.reload();
    }
    this.visibleWhatnews = true;
  }

  onRowClick(e) {
    if (e && e.rowType === 'data') {
      if (this.selectedRowId !== e.data.id) {
        this.setWhatnewsBody('...');
        this.selectedRowId = e.data.id;
        this.getNewsBody();
      } else this.showContentNews = true;
    }
  }

  getNewsBody() {
    this.loading = true;
    this.service.getNewsBody(this.selectedRowId, true, true).toPromise().then(data => {
      this.newsBody = data.html;
      this.whatnewsFiles = data.files;
      this.setWhatnewsBody(data.html || '<h3>Нет данных...</h3>');
      this.showContentNews = true;
    }).finally(() => {
      this.loading = false;
    })
  }

  setWhatnewsBody(html) {
    const elem = document.getElementById('whatnews-body');
    if (elem) elem.innerHTML = html;
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
        location: 'after',
        template: 'archiveRows',
        locateInMenu: 'auto'
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Обновить',
          height: 36,
          width: 36,
          icon: 'refresh',
          onClick: () => {
            this.gridWhatnews.instance.collapseAll(-1);
            this.dataSource.reload();
          }
        }
      });
  }

  downloadFile(e) {
    window.open(`/api/whatnews/file?fileId=${e.row.data.fileId}`);
  }

  archiveChanged(e) {
    this.dataSource.reload();
  }
}
