import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(private http: HttpClient) { }

  getComments(id, type, take, skip, filter?, sort?) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('id', id);
    httpParams = httpParams.append('type', type);
    if (take) httpParams = httpParams.append('take', take.toString());
    if (skip) httpParams = httpParams.append('skip', skip.toString());
    httpParams = httpParams.append('filter', JSON.stringify(filter) || '[]');
    if (sort) httpParams = httpParams.append('sort', JSON.stringify(sort));
    return this.http.get<any>(`/api/comment`, {params: httpParams});
  }

  postComment(type, id, comment, commentId?) {
    if(!commentId)
      return this.http.post<any>('/api/comment', {type, id, comment, commentId});
    else if(commentId)
      return this.http.put<any>('/api/comment', {commentId, comment});
  }

  deleteComment(id) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('id', id);
    return this.http.delete<any>('/api/comment', {params: httpParams});
  }

  getCommentHistory(id) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('id', id);
    return this.http.get<any>(`/api/comment/history`, {params: httpParams});
  }

}
