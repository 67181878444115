import {AfterViewInit, Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import notify from 'devextreme/ui/notify';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, AfterViewInit {
  correctPassword = false;
  checkPassword = false;
  currentMode = 'password';
  passwordMode = 'password';
  checkMode = 'password';

  public account = {
    currentPassword: '',
    password: '',
    checkPassword: ''
  };
  public barLabel = 'Сложность пароля:';
  token: string;
  rules;
  loading = true;
  minLength;
  @Output() onCancelRestore = new EventEmitter<any>();
  @Output() onAcceptRestore = new EventEmitter<any>();
  @Input() resetPasswordMode = false;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private service: AuthenticationService) {
    this.isValidationPassword = this.isValidationPassword.bind(this);
  }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.queryParams.token;
    if (!this.resetPasswordMode && !this.token) {
      this.router.navigate(['/login']);
    }
    this.service.getPasswordRules(this.token,
      this.resetPasswordMode && this.service.USER && this.service.USER.id).toPromise().then( data => {
      this.rules = data;
      this.minLength = this.rules && this.rules.minLength || 12;
    }).finally(() => {
      this.loading = false;
    });
  }

  ngAfterViewInit(): void {
    this.service.getErrorPassword();
  }

  onChangePassword() {
    if (!this.rules) { return; }
    if (!this.account.password) {
      this.correctPassword = false;
      this.rules.asciiCompleted = false;
      this.rules.rules.forEach( x => x.completed = false);
    }
    const { password } = this.account;

    for (const rule of this.rules.rules) {
      const count = (password.match(new RegExp(rule.regexp, 'g')) || []).length;
      rule.completed = !!count && count >= rule.min;
    }
    this.rules.asciiCompleted = !this.rules.ascii || password.length && !Array.from(password).find(c => c.toString().charCodeAt(0) > 127);
    this.correctPassword = password.length >= this.minLength && !this.rules.rules.find(x => !x.completed) && this.rules.asciiCompleted;
    this.onCheckPassword();
  }

  onCheckPassword() {
    this.checkPassword = this.account.password && this.account.checkPassword && this.account.password === this.account.checkPassword;
  }

  resetPassword() {
    if (this.resetPasswordMode) {
      if (!this.account.currentPassword) {
        notify('Введите текущий пароль!', 'error', 1500);
        return;
      }
      this.loading = true;
      this.service.resetPassword(this.account.checkPassword, this.account.currentPassword).toPromise().then( data => {
        notify('Пароль успешно изменен!', 'success', 1500);
        this.onAcceptRestore.emit();
      }).finally(() => {
        this.loading = false;
      });

    } else {
      this.service.postRestorePassword(this.token, this.account.password);
      this.loading = false;
    }
  }

  closeDialog() {
    this.onCancelRestore.emit();
  }

  isValidationPassword() {
    return this.correctPassword;
  }

  showPassword(id) {
    const input = document.getElementById(id);
    if (input && (input as HTMLInputElement)) {
      const type = (input as HTMLInputElement).type;
      (input as HTMLInputElement).type = type === 'text' && 'password' || 'text';
    }
  }

}
