import {Injectable} from '@angular/core';
import {TestSchemaAgreement} from './test-schema-agreement';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

const apiRegulation = '/api/agreement/regulation';

const api = '/api';

@Injectable({
  providedIn: 'root'
})
export class DiagramAgreementService {

  loadedDiagram = false;

  constructor(private http: HttpClient) {
  }

  getSchemaAgreement(): any {
    console.log(TestSchemaAgreement.testData);
    return TestSchemaAgreement.testData;
  }

  getRegulationSchema(id): Observable<any> {
    let urlParams = new HttpParams();
    urlParams = urlParams.append('id', id);
    return this.http.get<Array<any>>(`${apiRegulation}/schema`, {params: urlParams});
  }

  saveRegulation(regulation, id) {
    if (id) {
      return this.http.put<any>(apiRegulation, regulation);
    } else {
      return this.http.post<any>(apiRegulation, regulation);
    }
  }

  getRegulation(id): Observable<any> {
    let urlParams = new HttpParams();
    urlParams = urlParams.append('id', id);
    return this.http.get<Array<any>>(apiRegulation, {params: urlParams});
  }

  getOrgUnits(parentIds?, filter?: string, sort?: any, agreement?, filial?): Observable<any> {
    let httpParams = new HttpParams();
    if (parentIds) {
      parentIds.forEach(x => httpParams = httpParams.append('parent', x));
    }
    if (agreement) {
      httpParams = httpParams.append('agr', 'true');
    }
    httpParams = httpParams.append('filter', JSON.stringify(filter) || '[]');
    if (sort) {
      httpParams = httpParams.append('sort', JSON.stringify(sort));
    }
    if (filial) httpParams = httpParams.append('filial', 'true');
    return this.http.get<Array<any>>(`api/organization/departments`, {params: httpParams});
  }

  saveSchema(data): Observable<any> {
    return this.http.put<any>(apiRegulation + '/data', data);
  }

  getConditions(id = null) {
    let urlParams = new HttpParams();
    if (id) {
      urlParams = urlParams.append('id', id);
    }
    return this.http.get<Array<any>>(`api/agreement/conditions`, {params: urlParams});
  }

  getDictionary(id) {
    let urlParams = new HttpParams();
    urlParams = urlParams.append('id', id);
    return this.http.get<any>(`api/agreement/dictionary`, {params: urlParams});
  }

  getActionList(stage = false, action = false, isResult = false) {
    let urlParams = new HttpParams();
    if (stage) {
      urlParams = urlParams.append('stage', 'true');
    }
    if (action) {
      urlParams = urlParams.append('action', 'true');
    }
    if (isResult) {
      urlParams = urlParams.append('isResult', 'true');
    }
    return this.http.get<any>(`api/agreement/value/list`, {params: urlParams});
  }

  getStageInfo(id) {
    let urlParams = new HttpParams();
    urlParams = urlParams.append('id', id);
    return this.http.get<any>(`api/agreement/stage`, {params: urlParams});
  }

  getAgreementInfo(id) {
    const urlParams = new HttpParams().set('id', id);
    return this.http.get<any>(`api/agreement/document/info`, {params: urlParams});
  }

  getTreeOrg(parentIds?, filter?: string, sort?: string, department?, agreement?, showTerminated?) {
    let httpParams = new HttpParams();
    if (parentIds) {
      parentIds.forEach(x => httpParams = httpParams.append('parent', x));
    }
    if (department) {
      httpParams = httpParams.append('department', 'true');
    }
    if (agreement) {
      httpParams = httpParams.append('agreement', 'true');
    }
    httpParams = httpParams.append('filter', JSON.stringify(filter) || '[]');
    if (sort) {
      httpParams = httpParams.append('sort', JSON.stringify(sort));
    }
    if (showTerminated)
      httpParams = httpParams.append('showTerminated', 'true');
    return this.http.get<Array<any>>(`api/organization/tree`, {params: httpParams});
  }

  public getContragentData(): Observable<any> {
    return this.http.get<any>(`/api/contract/contragent/list`);
  }

  updateDocumentInfo(agrInfo) {
    return this.http.post<any>(`api/agreement/save/document`, agrInfo);
  }

  getExecutorInfo(id, idDoc) {
    let urlParams = new HttpParams();
    urlParams = urlParams.append('id', id);
    urlParams = urlParams.append('idDoc', idDoc);
    return this.http.get<any>(`api/agreement/executor`, {params: urlParams});
  }

  changeExecutor(data) {
    return this.http.put<any>(`api/agreement/executor`, data);
  }

  changeExecutorFromUser(id, idDocInfo, idStageDoc) {
    return this.http.put<any>(`api/agreement/executor/take`, {id, idDocInfo, idStageDoc});
  }

  deleteExecutor(id, idDocInfo, idStageDoc) {
    let params = new HttpParams();
    params = params.append('id', id);
    params = params.append('idDocInfo', idDocInfo);
    params = params.append('idStageDoc', idStageDoc);
    return this.http.delete<any>(`api/agreement/executor`, {params});
  }

  getDefaultExecutors(id, docId) {
    let urlParams = new HttpParams();
    urlParams = urlParams.append('id', id);
    urlParams = urlParams.append('docId', docId);
    return this.http.get<any>(`api/agreement/default/executors`, {params: urlParams});
  }

  getDefaultDepartment(id, docId) {
    let urlParams = new HttpParams();
    urlParams = urlParams.append('id', id);
    urlParams = urlParams.append('docId', docId);
    return this.http.get<any>(`api/agreement/default/departments`, {params: urlParams});
  }

  addExecutor(data) {
    return this.http.post<any>(`api/agreement/executor`, data);
  }

  getDocumentInfo(id) {
    const urlParams = new HttpParams().set('id', id);
    return this.http.get<any>(`api/agreement/document`, {params: urlParams});
  }

  getListFiles(ids, idDoc = null) {
    let urlParams = new HttpParams();
    if (!idDoc) {
      ids.forEach(x => urlParams = urlParams.append('id', x));
    } else {
      urlParams = urlParams.append('idDoc', idDoc);
    }
    return this.http.get<any>(`api/agreement/files`, {params: urlParams});
  }

  downloadFile(id) {

    window.open(`api/agreement/file?id=${id}`);
    /*
    const subscribeObj = this.getFile(id).pipe(map(res => {
      return {
        filename: decodeURIComponent(res.headers
          .get('content-disposition')
          .split(';')
          .map(c => c.trim())
          .find(c => c.startsWith('filename*') || c.startsWith('filename'))
          .split('=')[1]
          .substr(`UTF-8''`.length)),
        data: res.body
      };
    })).subscribe(res => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = res.filename;
      a.click();
      window.URL.revokeObjectURL(url);
      // window.open(url);
      a.remove();
      subscribeObj.unsubscribe();
    });*/
  }

  public getFile(id): Observable<HttpResponse<Blob>> {
    const httpParams = new HttpParams().set('id', id);
    return this.http.get<Blob>(`api/agreement/file`, {
      params: httpParams,
      responseType: 'blob' as 'json',
      observe: 'response'
    });
  }

  public deleteFile(id): Observable<any> {
    const httpParams = new HttpParams().set('id', id);
    return this.http.delete<any>(`api/agreement/file`, {params: httpParams, responseType: 'text' as 'json'});
  }

  public getHistory(id, options, versionId?): Observable<any> {
    let params = new HttpParams();
    params = this.setHttpParams(params, options);
    params = params.append('id', id);
    if(versionId)
      params = params.append('versionId', versionId);
    return this.http.get<Blob>(`api/agreement/history`, {params});
  }

  public getDepartment(id: number): Observable<any> {
    const params = new HttpParams()
      .set('id', String(id));
    return this.http.get<any>(`${api}/organization/department`, {params});
  }

  postDepartment(department) {
    return this.http.post(`api/organization/department`, department);
  }

  putDepartment(department) {
    return this.http.put(`api/organization/department`, department);
  }

  deleteDepartment(id) {
    const httpParams = new HttpParams().set('id', id);
    return this.http.delete<any>(`api/organization/department`, {params: httpParams, responseType: 'text' as 'json'});
  }

  public getExecutorsOfDepartment(ids) {
    let urlParams = new HttpParams();
    ids.forEach(x => urlParams = urlParams.append('id', x));
    return this.http.get<any>('api/organization/department/executors', {params: urlParams});
  }

  public changeStatusDocument(body) {
    return this.http.post<any>(`api/document/status`, body);
  }

  public async checkApproveDocument(id: any, packageId): Promise<any> {
    let params = new HttpParams();
    params = params.append('id', id);
    if (packageId) {
      params = params.append('packageId', packageId);
    }
    return await this.http.get<any>(`api/document/approve/check`, {params}).toPromise();
  }

  public getDocumentStatus(id: any, check?) {
    let params = new HttpParams();
    params = params.append('id', id);
    if (check) {
      params = params.append('check', check);
    }
    return this.http.get<any>(`api/document/status`, {params});
  }

  getRemarksList(documentId, options) {
    let params = new HttpParams();
    params = this.setHttpParams(params, options);
    params = params.append('id', documentId);
    return this.http.get<any>(`api/agreement/remarks`, {params});
  }

  getExecutorHistory(id, options) {
    let params = new HttpParams();
    params = this.setHttpParams(params, options);
    params = params.append('id', id);
    return this.http.get<any>(`api/agreement/executor/history`, {params});
  }

  getPackageDocument(id, packageId, options) {
    let params = new HttpParams();
    params = this.setHttpParams(params, options);
    if (id) params = params.append('id', id);
    if (packageId) params = params.append('packageId', packageId);
    return this.http.get<any>(`api/agreement/package/list`, {params});
  }

  setHttpParams(params, queryParams) {
    if (queryParams.take) {
      params = params.append('take', queryParams.take.toString());
    }
    if (queryParams.skip) {
      params = params.append('skip', queryParams.skip.toString());
    }
    if (queryParams.filter) {
      params = params.append('filter', JSON.stringify(queryParams.filter));
    }
    if (queryParams.sort) {
      params = params.append('sort', JSON.stringify(queryParams.sort));
    }
    if (queryParams.group) {
      params = params.set('group', JSON.stringify(queryParams.group));
    }
    return params;
  }

  public deleteRemarks(ids: any[]) {
    let urlParams = new HttpParams();
    ids.forEach(x => urlParams = urlParams.append('id', x));
    return this.http.delete('api/agreement/remarks', {params: urlParams, responseType: 'text' as 'json'});
  }

  public restoreRemarks(id) {
    return this.http.put('api/agreement/remarks/restore', {id});
  }

  public updateRemark(key, values) {
    return this.http.put('api/agreement/remarks', {key, values});
  }

  public uploadFile(file) {
    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, {type: contentType});
    };

    const blob = b64toBlob(file.content);

    const formData = new FormData();
    formData.append('file', blob, file.name);
    return this.http.post<any>(`api/file/upload`, formData, {responseType: 'text' as 'json'});
  }

  public attachRemarksFile(idDoc, idFile) {
    return this.http.post<any>('/api/agreement/remarks/attach', {idDoc, idFile});
  }

  public getDepartmentTypes() {
    return this.http.get<any>('/api/organization/department-type/list');
  }

  public getZones() {
    return this.http.get<any>('/api/organization/departments?typeId=6&onlyResName=true');
  }

  public async checkData(id, ignore?) {
    let urlParams = new HttpParams();
    urlParams = urlParams.append('id', id);
    if (ignore) {
      urlParams = urlParams.append('ignore', ignore);
    }
    //if(check) urlParams = urlParams.append('check', check);
    return await this.http.get<any>('/api/agreement/check', {params: urlParams}).toPromise();
  }

  public checkExecutorChanged(ids: any) {
    let params = new HttpParams();
    ids.forEach(x => params = params.append('id', x));
    return this.http.get<any>(`api/agreement/executor/changed`, {params});
  }

  changeExecutors(ids, officials) {
    return this.http.post<any>(`api/agreement/executor/changed`, {ids, officials});
  }

  public getAgrHistoryData(id: any) {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.get<any>(`api/agreement/history/data`, {params});
  }

  getDocumentErrorList(id, only, options, ids?) {
    let params = new HttpParams();
    params = this.setHttpParams(params, options);
    params = params.append('id', id);
    if (only)
      params = params.append('only', 'true');
    if (ids)
      params = params.append('ids', ids);
    return this.http.get<any>(`api/agreement/error/list`, {params});
  }

  fixDocumentErrorList(id, packageDoc ?) {
    return this.http.post<any>(`api/agreement/error/list`, {id, package: packageDoc});
  }

  getInfoErrorList(id, packageId?) {
    let params = new HttpParams();
    if (id)
      params = params.append('id', id);
    if (packageId)
      params = params.append('packageId', packageId);
    return this.http.get<any>(`api/agreement/error/info`, {params});
  }

  createPackageList(documentId, ids, name, ecpPackage?, originIds?) {
    return this.http.post<any>(`api/agreement/package`, {documentId, ids, name, ecpPackage, originIds});
  }

  editPackageList(id, newIds, deleteIds, name) {
    return this.http.put<any>(`api/agreement/package`, {id, newIds, deleteIds, name});
  }

  deletePackageList(id) {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.delete<any>(`api/agreement/package`, {params});
  }

  getContractorList(options) {
    let params = new HttpParams();
    params = this.setHttpParams(params, options);
    return this.http.get<any>(`api/contract/contragent/list`, {params});
  }

  deleteObjectFromPackage(id, comment) {
    return this.http.put<any>(`api/agreement/package/delete/object`, {id, comment});
  }

  public async createArchivePackage(packageId): Promise<any> {
    return await this.http.post<any>('api/document/archive/create', {packageId}).toPromise();
  }

  putContragentOrg(id, departmentId) {
    return this.http.put<any>(`api/agreement/change-contragent`, {id, departmentId});
  }

  getDocumentVersions(id) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('id', id);
    return this.http.get<Array<any>>(`/api/document/versions`, {params: httpParams});
  }

  postGenPdfFile(fileId) {
    return this.http.post<any>(`api/file/gen-pdf`, {fileId});
  }

  getAgreementHistoryPdfFile(id) {
    return this.http.get<any>(`api/report/agreement/history`, {params: {id, base64: 'true', compact: 'true'}});
  }

  addAdditionalOfficial(executorId, ids, parentId) {
    return this.http.post<any>(`api/agreement/additional-official`,
      {executorId, ids, ...(parentId && {parentId} || {})},
      {responseType: 'text' as 'json'});
  }

  getAdditionalOfficial(executorId) {
    return this.http.get<any>(`api/agreement/additional-official`, {params: {executorId}});
  }

  updateAdditionalOfficial(id, body) {
    return this.http.put<any>(`api/agreement/additional-official`, body, {params: {id}});
  }

  deleteAdditionalOfficial(id) {
    return this.http.delete<any>(`api/agreement/additional-official`,  {params: {id}, responseType: 'text' as 'json'});
  }

  getMultiplyReglaments(ids, needChildren) {
    return this.http.get<any>(`api/agreement/multiply-reglament`, {params: {ids, ...(needChildren && {needChildren} || {})}});
  }

  getHierarchyTree(ids) {
    return this.http.get<any>(`api/document/hierarchy-tree`, {params: {ids}});
  }

}
