import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError, BehaviorSubject} from 'rxjs';
import {catchError, filter, take, switchMap} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';
import notify from 'devextreme/ui/notify';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public authService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {

        if (error.error && ['PasswordExpiredError', 'RestoreTokenExpiredError'].includes(error.error.name)) {
           this.authService.redirectToRestore(error.error.token, error.error.name);
           return throwError(error);
        }

        switch (request.url[0] === '/' ? request.url.replace(/([/]+)/i, '') : request.url) {
          case 'api/account/user':
            if(this.authService.getRefreshToken())
              return this.handle401Error(request, next);
            else {
              this.authService.loading = false;
              return throwError(error);
            }
          case 'api/account/logout':
            return throwError(error);
          case 'api/account/password/restore':
            notify(request.method === 'GET' ?
              'Электронная почта не найдена' :
              'Ссылка не действительна или устарела', 'error', 3500);
            return throwError(error);
          case 'api/account/login':
            notify('Неверный логин или пароль', 'error', 3500);
            return throwError(error);
          case 'api/account/refresh':
            this.isRefreshing = false;
            this.authService.doLogoutUser(false);
            return throwError(error);
          default:
            return this.handle401Error(request, next);
        }
      } else if (error instanceof HttpErrorResponse && [504, 503].includes(error.status)) {
        this.authService.loading = false;
        this.authService.serverError = true;
      } else {
        const defaultTime = 3500;
        const str = error.error.message || error.error;
        const overTime = str.length * 50;
        notify(str, 'error', defaultTime > overTime && defaultTime || overTime);
        console.log(str);
        return throwError(error);
      }
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          return next.handle(request.clone());
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(() => {
          return next.handle(request.clone());
        }));
    }
  }
}
