import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';

const api = '/api';

@Injectable({
  providedIn: 'root'
})

export class GeneratorService {

  constructor(private http: HttpClient) {
  }

  public getList(apiGet: string, take: number, skip: number, filter?: string, sort?: string, group?: any, filterValue?: { name: string, value: string }[], queryParam?: [[string, any]], groupColumn = false, extraQueryParam?: [[string, any]]): Observable<any> {
    let httpParams = new HttpParams();
    if (take) {
      httpParams = httpParams.append('take', take.toString());
    }
    if (skip) {
      httpParams = httpParams.append('skip', skip.toString());
    }
    httpParams = httpParams.append('filter', JSON.stringify(filter) || '[]');
    if (sort) {
      httpParams = httpParams.append('sort', JSON.stringify(sort));
    }
    if (group) {
      httpParams = httpParams.set('group', JSON.stringify(group));
      if (groupColumn) {
        httpParams = httpParams.set('column', JSON.stringify(true));
      }
    }
    if (filterValue) {
      filterValue.forEach(x => {
        httpParams = httpParams.set(x.name, x.value);
      });
    }

    if (!!queryParam) {
      queryParam.forEach(x => {
        if (x[0] && x[0].toString() === 'objectIds') {
          httpParams = httpParams.append(x[0], x[1]);
        } else {
          httpParams = httpParams.set(x[0], x[1]);
        }
      });
    }

    return this.http.get<Array<any>>(apiGet, {params: httpParams});
  }

  public postList(apiPost: string, values: any): Observable<any> {
    return this.http.post<any>(apiPost, values);
  }

  public putList(apiPut: string, values: any): Observable<any> {
    return this.http.put<any>(apiPut, values);
  }

  public getListSimple(apiGet: string): Observable<any> {
    return this.http.get<Array<any>>(apiGet);
  }

  public delete(query: string, ids: any[]) {
    let urlParams = new HttpParams();
    ids.forEach(x => urlParams = urlParams.append('id', x));
    return this.http.delete(query, {params: urlParams, responseType: 'text' as 'json'});
  }

  public getObjectBySubobjectId(id: number, typeLink = 'object'): Observable<any> {
    const params = new HttpParams().set('id', String(id)).set('typeLink', typeLink);
    return this.http.get<any>(`${api}/object/obj-by-sub`, {params});
  }
}
