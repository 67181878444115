import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {DxTreeListComponent} from 'devextreme-angular';
import {ObjectService} from '../../../services/object.service';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-tree-object-hierarchy',
  templateUrl: './tree-object-hierarchy.component.html',
  styleUrls: ['./tree-object-hierarchy.component.scss'],
  providers: [ObjectService],
  encapsulation: ViewEncapsulation.None
})
export class TreeObjectHierarchyComponent implements OnInit, AfterViewInit {
  loading = false;
  searchPanelText = null;
  dataSource: any;
  selectedRowsData: any[] = [];
  selectedRowKeys: any[] = [];
  countList = [10, 20, 40, 60, 80];
  quantityShow = 10;
  selectedIndexPage = 1;
  totalPage = 0;
  totalItems = 0;
  dataSourceItems = [];
  oldSort = '';
  filtersValues = {};
  columns = [{
    width: 80,
    caption: 'id',
    dataField: 'id',
    visible: false
  }, {
    width: 250,
    sortIndex: 0,
    caption: 'Код объекта КС',
    dataField: 'code',
    sortOrder: 'asc',
    typeLink: {
      object: 'Код объекта КС',
      title: 'Идентификатор',
    }
  }, {
    caption: 'Наименование объекта КС',
    dataField: 'name',
    typeLink: {
      object: 'Наименование объекта КС',
      title: 'Наименование ИП',
    }
  }, {
    width: 150,
    caption: 'Плановая дата начала',
    dataField: 'planStartDate',
    dataType: 'date',
    format: 'MM.yyyy',
    typeLink: {
      object: 'Плановая дата начала',
      title: 'Начало реализации ИП',
    }
  }, {
    width: 150,
    caption: 'Плановая дата окончания',
    dataField: 'planEndDate',
    dataType: 'date',
    format: 'MM.yyyy',
    typeLink: {
      object: 'Плановая дата окончания',
      title: 'Окончание реализации ИП',
    }
  }, {
    width: 200,
    caption: 'Подразделение',
    dataField: 'department',
    visibleTypeLink: {
      object: true,
      title: false
    }
  }, {
    width: 200,
    caption: 'Филиал',
    dataField: 'filial'
  }];
  hierarchyData = {
    showChildren: true,
    level: null,
    levelName: '',
    linkIds: null
  };

  @ViewChild('treeListObjectHierarchy', {static: false}) treeListObjectHierarchy: DxTreeListComponent;
  isOnlySub = false;
  private btnChoice: any = null;
  objectLinkFilter = false;
  valuesLinkFilter;

  constructor(public dialogRef: MatDialogRef<TreeObjectHierarchyComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private objectService: ObjectService,
              private cdr: ChangeDetectorRef) {
    this.valuesLinkFilter = [
      {id: true, name: `Текущий ${this.data.typeLink === 'title' && 'ИП' || 'объект' }` },
      {id: false, name: `Все ${this.data.typeLink === 'title' && 'ИП' || 'объекты' }`}];

    if (this.data.typeLink) {
      this.columns.forEach(x => {
        x.caption = x.typeLink && x.typeLink[this.data.typeLink] || x.caption;
        x.visible = x.visibleTypeLink ? x.visibleTypeLink[this.data.typeLink] : x.visible;
      });
    }

    this.dataSource = new DataSource({
      store: new CustomStore({
        key: 'id',
        load: (options) => {
          // tslint:disable-next-line:variable-name
          const _filter = this.treeListObjectHierarchy.instance.getCombinedFilter();
          const skip = (this.selectedIndexPage - 1) * this.quantityShow;

          if (this.oldSort !== JSON.stringify(options.sort)) {
            this.selectedIndexPage = 1;
            this.dataSourceItems = [];
          }
          this.oldSort = JSON.stringify(options.sort);

          if (!this.dataSourceItems.length) {
            this.loading = true;
            return this.objectService.getObjectHierarchy(
              this.data.objectId,
              this.data.contractId,
              this.data.level,
              this.quantityShow,
              skip,
              options.sort,
              _filter,
              this.data.typeLink,
              this.objectLinkFilter && this.data.linkDocumentId || null,
              this.data.typeId
              ).toPromise()
              .then(result => {
                if (result.hierarchyData) {
                  this.hierarchyData = result.hierarchyData;
                }

                if (result.count) {
                    this.totalItems = result.count;
                    this.totalPage = Math.ceil(result.count / this.quantityShow);
                  } else if (this.objectLinkFilter) {
                    this.totalItems = this.totalPage = 1;
                  }
                this.dataSourceItems = result.items;
                this.loading = false;
                return this.dataSourceItems;
              }
              ).catch(error => {
                this.loading = false;
                throw error.error;
              });
          } else if (options.parentIds) {
            return this.dataSourceItems.filter(x => options.parentIds.includes(x.parentId));
          } else if (options.filter && options.filter.length === 3 && options.filter[0] === 'parentId') {
            return this.dataSourceItems.filter(x => options.filter[0] === x.parentId);
          } else { return this.dataSourceItems; }
        },
        onLoaded: result => {
          if (this.hierarchyData.showChildren) {
            result.filter(x => x.hasItems).forEach(x => {
              this.treeListObjectHierarchy.instance.expandRow(x.id);
            });
          }

          if (this.hierarchyData.level) {
            notify(`Выбор ограничен только ${this.hierarchyData.levelName}!`, 'info', 1500);
          }
        }
      })
    });
  }

  ngOnInit() {
    if (this.data.selectedRows) {
      this.selectedRowsData = this.data.selectedRows;
    }
  }

  ngAfterViewInit() {
    if (this.data !== undefined && this.data !== null &&
      this.data.selectedRowKeys !== undefined && this.data.selectedRowKeys !== null) {
      this.selectedRowKeys = this.data.selectedRowKeys;
    }
    this.cdr.detectChanges();
  }

  onRowClick(e) {
    if (e.data.parentId == null && this.isOnlySub === true) {
      return;
    }
  }

  onCellPrepared(e) {
    if (e.column.dataField !== undefined && e.rowType === 'data') {
      if (e.data.parentId == null && this.isOnlySub === true) {
        e.cellElement.classList.add('disableEdit');
      }
    }
  }

  selectedRow(data: any) {
    if (this.data.mode === 'multiple' ) {
      this.selectedRowsData = this.selectedRowsData.filter(x => !data.currentDeselectedRowKeys.includes(x.id));
      this.selectedRowsData = [...this.selectedRowsData,
        ...this.treeListObjectHierarchy.instance.getSelectedRowsData('all').filter(x => !this.selectedRowsData.find(y => y.id === x.id))];
    } else {
      this.selectedRowsData = this.treeListObjectHierarchy.instance.getSelectedRowsData('all');
    }
    this.updateBtnStates();
  }

  onToolbarPreparing(e: any) {
    const toolbarItems: any[] = [{
      location: 'after',
      widget: 'dxButton',
      options: {
        hint: 'Обновить',
        icon: 'refresh',
        onClick: () => {
          this.dataSource.reload();
        }
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        hint: 'Выбрать',
        height: 36,
        width: 36,
        disabled: true,
        icon: 'check',
        onInitialized: (args: any) => {
          this.btnChoice = args.component;
        },
        onClick: this.onChoiceRows.bind(this)
      }
    }];
    if (this.data.mode === 'single' && this.data.linkDocumentId) {
      this.objectLinkFilter = true;
      toolbarItems.unshift({
        location: 'before',
        template: 'objectLinkFilter',
        locateInMenu: 'auto'
      });
    }
    e.toolbarOptions.items.unshift(...toolbarItems);
  }

  onChoiceRows() {
    this.dialogRef.close(this.selectedRowsData);
  }

  private updateBtnStates() {
    if (this.btnChoice != null) {
      this.btnChoice.option({
        disabled: this.selectedRowsData.length === 0 ||
          (this.hierarchyData.level === 1 && this.selectedRowsData.find(x => x.parentId))
          || (this.hierarchyData.level === 2 && this.selectedRowsData.find(x => !x.parentId))
      });
    }
  }

  changeQuantityShow(e) {
    this.selectedIndexPage = 1;
    this.dataSourceItems = [];
    this.dataSource.reload();
  }

  searchTextChange(e) {
    if (!this.data || !this.data.objectId) {
      this.selectedIndexPage = 1;
      this.dataSourceItems = [];
      this.cdr.detectChanges();
    }
  }

  setPageIndex(e) {
    switch (e) {
      case 'first':
        this.selectedIndexPage = 1;
        break;
      case 'next':
        this.selectedIndexPage += 1;
        break;
      case 'prev':
        this.selectedIndexPage -= 1;
        break;
      case 'last':
        this.selectedIndexPage = this.totalPage;
        break;
    }
    this.dataSourceItems = [];
    this.dataSource.reload();
    this.cdr.detectChanges();
  }

  filterValueChange(e, dataField) {
    if (!this.data || !this.data.objectId) {
      this.dataSourceItems = [];
      this.selectedIndexPage = 1;
      this.dataSource.reload();
      this.cdr.detectChanges();
    }
  }

  onValueChangedIndexPage(numPage = null) {
    if (numPage) {
      this.selectedIndexPage = numPage;
    }
    this.dataSourceItems = [];
    this.dataSource.reload();
    this.cdr.detectChanges();
  }

  onContentReady(e) {
    if (this.data.mode === 'multiple' ) {
      const ds = this.treeListObjectHierarchy.instance.getDataSource();
      const keys = this.selectedRowsData
        .filter(x => this.treeListObjectHierarchy.instance.getRowIndexByKey(x.id) >= 0)
        .map(x => x.id);
      this.treeListObjectHierarchy.instance.selectRows(keys, true);
    }
  }

  changeFilteringObjectLink(e) {
    this.onValueChangedIndexPage(1);
  }

}
