import {AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {GlobalService} from '../../../services/global.service';
import {CommonService} from '../common.service';
import {ChooseExecutorComponent} from '../choose-executor/choose-executor.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import {DxDataGridComponent} from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import {DiagramAgreementService} from "../../diagram-agreement/diagram-agreement.service";

@Component({
  selector: 'app-agreement-aditional-executor',
  templateUrl: './agreement-aditional-executor.component.html',
  styleUrls: ['./agreement-aditional-executor.component.scss']
})
export class AgreementAdditionalExecutorComponent implements OnInit, AfterViewInit {

  @Input() admin = false;
  @Input() editable = true;
  @Input() executorId;
  @Input() parentId;
  @Input() multipleSelect = false;
  dataSourceGroup;
  dataSourceExecutor;
  selectedOfficial;
  selectedGroupId;
  popupVisibleGroup;
  disabledGroup = false;
  selectedExecutors = [];
  loading = false;
  @Output() savedData = new EventEmitter<any>();

  @ViewChild('gridExecutors', {static: false}) gridExecutors: DxDataGridComponent;

  constructor(private commonService: CommonService,
              public dialog: MatDialog,
              private agreementService: DiagramAgreementService) {
    this.dataSourceGroup = new DataSource({
      store: new CustomStore({
        key: 'id',
        load: (options) => {
          if (!this.selectedOfficial && this.admin) {
              return [];
          }
          return this.commonService.getAdditionalGroup(this.admin, this.selectedOfficial && this.selectedOfficial.id, this.executorId, this.parentId)
            .toPromise().then(data => {
              return data.items;
            }
          ).catch(error => {
            throw error.error;
          });
        },
        insert: (values) => {
          values.officialId = this.selectedOfficial && this.selectedOfficial.id || null;
          return this.commonService.saveAdditionalData(this.admin, true, 'post', null, values).toPromise().catch(error => {
            throw error.error;
          });
        },
        update: (key, values) => {
          return this.commonService.saveAdditionalData(this.admin, true, 'put', {id: key}, values).toPromise().catch(error => {
            throw error.error;
          });
        },
        remove: key => {
          return this.commonService.saveAdditionalData(this.admin, true, 'delete', {id: key}).toPromise().catch(error => {
            throw error.error;
          });
        },
        onLoaded: result => {
          if (this.executorId) {
            const stageGroup = result.find(x => x.stage);
            if (stageGroup) {
              this.selectedGroupId = stageGroup.id;
              this.disabledGroup = true;
            } else {
              this.selectedGroupId = null;
              this.disabledGroup = false;
            }
          }
        }
      })
    });
    this.dataSourceExecutor = new DataSource({
      paginate: true,
      pageSize: 15,
      store: new CustomStore({
        key: 'id',
        load: (options) => {
          if (!this.selectedGroupId) {
            return new Promise((resolve, reject) => {
              resolve({
                type: 'array',
                key: 'id',
                data: [],
                totalCount: 0
              });
            });
          }
          return this.commonService.getAdditionalExecutor(this.selectedGroupId, this.admin, this.executorId).toPromise().then(data => {
              return {
              type: 'array',
              key: 'id',
              data: data.items,
              totalCount: data.count
            };
            }
          ).catch(error => {
            throw error.error;
          });
        },
        insert: (values) => {
          values.groupId = this.selectedGroupId || null;
          return this.commonService.saveAdditionalData(this.admin, false, 'post', null, values).toPromise().catch(error => {
            throw error.error;
          });
        },
        update: (key, values) => {
          return this.commonService.saveAdditionalData(this.admin, false, 'put', {id: key}, values).toPromise().catch(error => {
            throw error.error;
          });
        },
        remove: key => {
          return this.commonService.saveAdditionalData(this.admin, false, 'delete', {id: key}).toPromise().catch(error => {
            throw error.error;
          });
        },
      })
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if (this.executorId) {
      this.dataSourceGroup.reload();
    }
  }

  choiceMainOfficial() {
    this.choiceOfficial().then( data => {
      this.selectedGroupId = null;
      this.selectedOfficial = data;
      this.dataSourceGroup.reload();
      this.dataSourceExecutor.reload();
    });
  }

  editGroup() {
    this.popupVisibleGroup = true;
  }

  onToolbarPreparingGroup(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      locateInMenu: 'auto',
      showText: 'inMenu',
      options: {
        icon: 'refresh',
        onClick: () => { this.dataSourceGroup.reload(); },
        text: 'Обновить',
        hint: 'Обновить'
      }
    });
  }

  onToolbarPreparingExecutor(e) {
    const buttons = [{
      location: 'after',
      widget: 'dxButton',
      locateInMenu: 'auto',
      showText: 'inMenu',
      options: {
        icon: 'refresh',
        onClick: () => { this.dataSourceExecutor.reload(); },
        text: 'Обновить',
        hint: 'Обновить'
      }
    }];

    if (this.executorId) {
      buttons.push({
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        showText: 'inMenu',
        options: {
          icon: 'check',
          onClick: () => {
            this.addAdditionalExecutors();
          },
          text: 'Выбрать',
          hint: 'Выбрать'
        }
      });
    }

    e.toolbarOptions.items.unshift(...buttons);
  }

  onCellClickExecutor(e) {
    if (this.editable && e && e.column.dataField === 'official' && e.rowType === 'data') {
      this.choiceOfficial().then( data => {
        if (data) {
          this.gridExecutors.instance.cellValue(e.rowIndex, 'official', data.name);
          this.gridExecutors.instance.cellValue(e.rowIndex, 'officialId', data.id);
        }
      });
    }
  }

  choiceOfficial(): any {
    return new Promise(resolve => {
      const dialogRef = this.dialog.open(
        ChooseExecutorComponent, {
          minWidth: '550px',
          maxWidth: '60%',
          maxHeight: '95vh',
          data: {
            pageOrg: 1,
            contraget: false,
            showCurator: false,
            showAgreementInfo: false,
            singleSelect: true
          }
        }
      );
      dialogRef.afterClosed().subscribe(result => {
          resolve(result || null);
      });
    });
  }

  changedGroup(e) {
    this.selectedGroupId = e.value;
    this.gridExecutors.instance.cancelEditData();
    this.dataSourceExecutor.reload();
  }

  hideGroupEditor(e) {

  }

  public refreshGroupByStage(executorId, parentId = null) {
    this.gridExecutors.instance.deselectAll();
    this.selectedGroupId = null;
    this.executorId = executorId;
    this.parentId = parentId;
    this.dataSourceGroup.reload();
  }

  addAdditionalExecutors() {
    if (this.selectedExecutors.length) {
      this.loading = true;
      this.agreementService.addAdditionalOfficial(this.executorId, this.selectedExecutors.map(x => x.officialId), this.parentId)
        .toPromise().then(data => {
          this.savedData.emit();
          this.parentId = this.executorId = null;
      }).finally(() => {
        this.loading = false;
      });
    } else { notify('Необходимо выбрать сотрудников!', 'error'); }
  }

  onSelectionChangedExecutor(e) {
    if (this.executorId || this.parentId) {
      this.selectedExecutors = e.selectedRowsData;
    }
  }

}
