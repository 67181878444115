import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient) {
  }

  setHttpParams(params, queryParams) {
    if (queryParams.take) params = params.append('take', queryParams.take.toString());
    if (queryParams.skip) params = params.append('skip', queryParams.skip.toString());
    if (queryParams.filter) params = params.append('filter', JSON.stringify(queryParams.filter));
    if (queryParams.sort) params = params.append('sort', JSON.stringify(queryParams.sort));
    if (queryParams.group) params = params.set('group', JSON.stringify(queryParams.group));
    return params;
  }

  getWhatnewsList(options) {
    let params = new HttpParams();
    params = this.setHttpParams(params, options);
    return this.http.get<any>(`api/whatnews/list`, {params});
  }

  getNewsBody(id, files, show) {
    let params = new HttpParams();
    params = params.append('id', id);
    files ? params = params.append('files', 'true'): null;
    show ? params = params.append('show', 'true'): null;
    return this.http.get<any>(`api/whatnews/user/body`, {params});
  }

  getWhatnewsUserList(options, archive) {
    let params = new HttpParams();
    params = this.setHttpParams(params, options);
    if(archive) params = params.append('archive', 'true');
    return this.http.get<any>(`api/whatnews/user/list`, {params});
  }

  checkWhatnewsUser(id) {
    let params = new HttpParams();
    if(id)
      params = params.append('id', id);
    return this.http.get<any>(`api/whatnews/user`, {params});
  }

  getAdditionalGroup(admin?, officialId?, executorId?, parentId?) {
    return this.http.get<any>(`api/${admin && 'admin' || 'account'}/additional-group`,
      {params: {
          ...(admin && officialId && {officialId} || {}),
          ...(executorId && {executorId} || {}),
          ...(parentId && {parentId} || {})
        }});
  }

  getAdditionalExecutor(groupId, admin?, executorId?) {
    return this.http.get<any>(`api/${admin && 'admin' || 'account'}/additional-executor`,
      {params: {
            ...(groupId && {groupId} || {}),
            ...(executorId && {executorId} || {}),
          }});
  }

  saveAdditionalData(admin, group, method, params, body?) {
    const requestProp = [];
    if (body) {
      requestProp.push(body);
    }
    if (params) {
      requestProp.push({params});
    }
    return this.http[method]<any>(`api/${admin && 'admin' || 'account'}/additional-${group ? 'group' : 'executor'}`, ...requestProp);
  }

  getAccounts(contragentId, options) {
    let params = new HttpParams();
    params = this.setHttpParams(params, options);
    params = params.append('contragentId', contragentId);
    return this.http.get<any>(`api/agreement/account`, {params});
  }

}
