import {Inject} from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {ObjectService} from "../../../../services/object.service";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from "@angular/router";

const api = '/api';

/*
https://js.devexpress.com/Documentation/ApiReference/Data_Layer/CustomStore/
https://supportcenter.devexpress.com/Ticket/Details/T481700/dxautocomplete-how-to-generate-a-datasource-on-the-fly

либо material
https://stackoverflow.com/questions/51054615/angular-2-4-search-box-like-google

https://medium.com/@dhormale/use-google-places-api-autocomplete-using-angular-for-resident-and-office-address-23cc33078e8

 */

@Component({
  selector: 'app-object-search',
  templateUrl: './object-search.component.html',
  styleUrls: ['./object-search.component.scss']
})
export class ObjectSearchComponent implements OnInit {

  constructor(private objectService: ObjectService , @Inject(HttpClient) httpClient: HttpClient, private router: Router)
  {
    // https://js.devexpress.com/Documentation/Guide/Widgets/Chart/Data_Binding/Custom_Sources/
    this.httpClient = httpClient;

    this.store = new CustomStore({
      loadMode: "raw",
      cacheRawData : false,
      key: "id",
      load: (loadOptions) => {
        // console.log("CustomStore.load() called.");
        // console.log('load: name=' + this.id);
        const result = this.findObjectItems(this.id)
        // console.log('Найдено: ' + result);
        return result;
      },
      insert: (values) => {
        // ...
        return Promise.resolve(); // TODO
      },
      update: (key, values) => {
        // ...
        return Promise.resolve(); // TODO
      },
      remove: (key) => {
        // ...
        return Promise.resolve(); // TODO
      }
    });

    // ===== or inside the DataSource =====
    // this.dataSource = new DataSource({
    //   // ...
    //   // a mix of CustomStore and DataSource options
    //   // ...
    // });

  }
  public id: string;
  // public objectItem: any;
  public objects : any;
  public store: CustomStore;
  dataSource: DataSource;
  private httpClient: HttpClient;

  ngOnInit() {
  }

  /**
   * Ищет объекты в базе
   * @param text
   */
  private async findObjectItems(text: string){
    const params = new HttpParams()
      .set('text', String(text ));
    let y = null;
    // тут должен быть вызов ObjectService.searchObjects(text), но почему-то метод searchObjects() не виден
    const result = await this.httpClient.get(`${api}/object/search`, {params})
     .toPromise()
      .then( x => {
        // console.log("x=:");
        // console.log(x);
        y = (x as Array<any>).map( e => {return {id: e.id, code: e.code, fullName: e.fullName, address: e.address}});
      }) ;
    return y;
  }

  onSelectionChanged(){
    // показать здесь окно с объектом
    if (this.id )
      this.router.navigate([`/objects/card/${this.id}/search`]);
    this.id = null;

  }
}
