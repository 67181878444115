import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }

  public genFilePlansReport(name, id, extension, fileName, params?) {
    return this.http.post<any>(`api/report/file`, Object.assign({name, id, extension, fileName}, params)
      /*{headers: new HttpHeaders({ timeout: `300000` })}*/
    );
  }

}
