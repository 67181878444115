import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

export interface ConfirmDialogModel {
  title: string;
  message: string;
  field?: string;
  array?: [];
  cancel?: boolean;
  onlyOk?: boolean;
}
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  title: string;
  message: string;
  constructor(public dialogRef: MatDialogRef<ConfirmComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit() {
    console.log(this.data.array);
    const cdkoverlaycontainer = document.getElementsByClassName('cdk-overlay-container') as HTMLCollectionOf<HTMLElement>;
    if (cdkoverlaycontainer)
      cdkoverlaycontainer[0].style.zIndex = '2000';
  }

  onConfirm(): void {
    this.closediag();
    this.dialogRef.close(true);
  }

  onDismiss(result = undefined): void {
    this.closediag();
    this.dialogRef.close(result);
  }

  closediag(){
    const cdkoverlaycontainer = document.getElementsByClassName('cdk-overlay-container') as HTMLCollectionOf<HTMLElement>;
    if (cdkoverlaycontainer)
      cdkoverlaycontainer[0].removeAttribute("style");
  }
}
