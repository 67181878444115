import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, mapTo, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import notify from 'devextreme/ui/notify';
import * as fingerprint2 from 'fingerprintjs2';
import config from 'devextreme/core/config';
import repaintFloatingActionButton from 'devextreme/ui/speed_dial_action/repaint_floating_action_button';
import {AngularFireMessaging} from '@angular/fire/messaging';

export interface Refresh {
  refresh: string;
}

@Injectable()
export class AuthenticationService {

  lkk = false;
  selectedPage = 'home';
  serverError;
  loading = true;
  public currentUrl = '/home';
  public nextUrl = '';
  USER: any;
  repaint = false;
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  public readonly PASSWORD_ERROR = 'PASSWORD_ERROR';
  private fingerPrint = 'abc123';

  constructor(private http: HttpClient,
              private router: Router,
              private afMessaging: AngularFireMessaging) {
  }

  genFingerPrint() {
    fingerprint2.getPromise().then(result => {
      const fingerprint = fingerprint2.x64hash128(JSON.stringify(result), 99);
      if (fingerprint) {
        this.fingerPrint = fingerprint;
        this.getUserInfo();
      }
    });
  }

  postLogin(username: string, password: string): any {
    const u = {
      login: username,
      password,
      fingerprint: this.fingerPrint
    };

    this.http.post<any>(`/api/account/login`, u)
      .pipe(
        tap(tokens => this.doLoginUser(tokens)),
        mapTo(true),
        catchError(() => {
          return of(false);
        })).subscribe(data => {
      if (data) {
        this.getUserInfo();
      }
    });
  }

  getUserLocalInfo() {
    return this.USER;
  }

  refreshToken() {

    console.log('refreshToken');

    const r = {
      refresh: this.getRefreshToken(),
      fingerprint: this.fingerPrint
    };

    return this.http.post<any>(`/api/account/refresh`, r).pipe(tap((tokens: Refresh) => {
      console.log(tokens);
      this.storeTokens(tokens);
    }));
  }

  logout() {
    if (this.getRefreshToken()) {
      const r = {
        refresh: this.getRefreshToken(),
        fingerprint: this.fingerPrint,
        firebaseToken: this.getFirebaseToken()
      };

      this.http.post<any>(`/api/account/logout`, r, {responseType: 'text' as 'json'}).pipe(
        tap(() => {
          console.log('logout');
        }),
        mapTo(true),
        catchError(error => {
          alert(error.error);
          console.log(error);
          return of(false);
        })).subscribe(() => {
      });
    }
  }

  doLogoutUser(sendRequest = true) {
    if (sendRequest) {
      this.logout();
    }
    this.removeTokens();
  }

  isLoggedIn(): boolean {
    if (!!this.getUserLocalInfo()) {
      if (!this.repaint) {
        config({
          floatingActionButtonConfig: {
            icon: 'user',
            position: {
              my: 'right top',
              at: 'right top',
              offset: '-8 8'
            }
          }
        });

        repaintFloatingActionButton();
        this.repaint = true;
      }
    }

    return !!this.getUserLocalInfo();
  }

  getUserInfo() {
    return this.http.get<any>(`/api/account/user`).subscribe(data => {
      this.storeUserInfo(data);
      console.log(data);
      console.log(this.currentUrl);
      this.loading = false;

      const firebaseToken = this.getFirebaseToken();
      this.afMessaging.requestToken
        .subscribe(token => {
            if (!token || firebaseToken === token) {
              return;
            }
            return this.http.post(`/api/account/firebase`,
              {token},
              {responseType: 'text' as 'json'}).subscribe(() => {
              this.setFirebaseToken(token);
            });
          },
          () => {
            if (!firebaseToken) {
              return;
            }
            return this.http.delete(`/api/account/firebase`,
              {params: new HttpParams().append('token', firebaseToken)})
              .subscribe(() => {
                this.afMessaging.deleteToken(firebaseToken);
                this.deleteFirebaseToken();
              });
          });

      if (this.currentUrl === '/login') {
        this.currentUrl = '/home';
      }
      if (this.nextUrl.includes('/login')) {
        this.nextUrl = '/home';
      }
      return this.router.navigate([this.nextUrl]);
    });
  }

  getFirebaseToken() {
    return window.localStorage.getItem('firebaseToken');
  }

  setFirebaseToken(token) {
    window.localStorage.setItem('firebaseToken', token);
  }

  deleteFirebaseToken() {
    window.localStorage.removeItem('firebaseToken');
  }

  checkRights(name: string, permission: string, needMessage: boolean = true, message: string = 'Нет прав доступа!') {
    const f = this.USER.forms.find(x => x.form === name);
    const area = this.USER.rights && this.USER.rights.find(x => x.right === name);
    if ((f && f.permission >= permission) || (area && area.permission >= permission)) {
      return true;
    } else {
      if (needMessage) {
        notify(message, 'error', 1500);
      }
      return false;
    }
  }

  private doLoginUser(tokens: Refresh) {
    this.storeTokens(tokens);
    console.log(tokens);
  }

  private storeTokens(tokens: Refresh) {
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refresh);
  }

  private storeUserInfo(user: any) {
    this.USER = user;
  }

  public getRefreshToken(): any {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private removeTokens() {
    this.currentUrl = '/home';
    localStorage.removeItem(this.REFRESH_TOKEN);
    console.log('removeTokens');
    this.USER = null;
    this.router.navigate(['/login']);
    this.loading = false;
  }

  getRestorePassword(email: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('email', email.toString());
    return this.http.get<any>(`/api/account/password/restore`, {params: httpParams});
  }

  postRestorePassword(token: string, password: string) {

    const restore = {
      token,
      password,
      fingerprint: this.fingerPrint
    };

    this.http.post<any>(`/api/account/password/restore`, restore)
      .pipe(
        tap(data => this.doLoginUser(data)),
        mapTo(true),
        catchError(() => {
          return of(false);
        })).subscribe(data => {
      if (data) {
        this.getUserInfo();
      }
    });
  }

  public hasAccess(appPage: string) {
    // const module = appPage.split('/')[appPage[0] === '/' ? 1 : 0].replace(/([^a-z0-9]+)/gi, '');
    const page = (appPage.includes('?') ? appPage.substring(0, appPage.indexOf('?')) : appPage).replace(/([^a-z]+)/gi, '').toLowerCase();
    const appPageWithoutRight = ['home', 'tasks', 'agreementslist', 'objectscardsearch', 'home2', 'reportstest'];
    if (appPageWithoutRight.includes(page)) {
      return true;
    }
    return !!this.USER.forms.find(x => x.form && x.form.toLowerCase() === page);
  }

  getApplicationVersion(): any {
    return this.http.get<any>(`/api/application/version`);
  }

  getPasswordRules(token, id): any {
    return this.http.get<any>(`/api/account/password-rules`, {params: {
        ...(token && {token} || {}),
        ...(id && {id} || {}),
      }});
  }

  redirectToRestore(token, error) {
    if (error) {
      localStorage.setItem(this.PASSWORD_ERROR, error);
    }
    this.logout();
    localStorage.removeItem(this.REFRESH_TOKEN);
    this.USER = null;
    this.loading = false;
    const newURL = window.location.origin + `/#/login${token && `/restore?token=${token}` || ''}`;
    if (newURL !== window.location.href) {
      window.location.href = newURL;
    } else {
      this.getErrorPassword();
    }
  }

  getErrorPassword() {
    const errorPassword = localStorage.getItem(this.PASSWORD_ERROR);
    localStorage.removeItem(this.PASSWORD_ERROR);
    let strMessage;
    switch (errorPassword) {
      case 'PasswordExpiredError': strMessage = 'Срок действия пароля истек. Необходимо изменить пароль.'; break;
      case 'RestoreTokenExpiredError': strMessage = 'Вы просрочили срок изменения пароля. Необходимо обратиться к администратору.'; break;
    }
    if (strMessage) {
      notify(strMessage, 'error', 3500);
    }
  }

  public resetPassword(newPassword: string, currentPassword: string) {
    return this.http.patch(`api/account/password`, {newPassword, currentPassword});
  }
}
