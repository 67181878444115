import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MonitoringActComponent} from "./reports/monitoring-act/monitoring-act.component";
import {ComparePlansComponent} from "./reports/compare-plans/compare-plans.component";
import {PaymentKS2Component} from './reports/payment-ks2/payment-ks2.component';
import { FinResultInfoComponent } from './reports/fin-result-info/fin-result-info.component';


@Component({
  selector: 'app-report-header',
  templateUrl: './report-header.component.html',
  styleUrls: ['./report-header.component.scss']
})
export class ReportHeaderComponent implements OnInit, OnDestroy {

  reportName;
  @ViewChild('monitoringAct', {static: false}) monitoringAct: MonitoringActComponent;
  @ViewChild('comparePlans', {static: false}) comparePlans: ComparePlansComponent;
  @ViewChild('paymentKS2', {static: false}) paymentKS2: PaymentKS2Component;
  @ViewChild('finResultInfo', {static: false}) finResultInfo: FinResultInfoComponent;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if(this.monitoringAct) this.monitoringAct = null;
    if(this.comparePlans) this.comparePlans = null;
    if (this.paymentKS2) { this.paymentKS2 = null; }
    if (this.finResultInfo) { this.finResultInfo = null; }
  }

  showReport(nameReport) {
    this.reportName = name;
    if (this[nameReport])
      this[nameReport].show();
  }

}
