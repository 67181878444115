import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';


@Injectable()
export class FinancePlansService {
  typePlansArray:String[]=["План КВЛ","План в т.ч. СМР","План в т.ч. cод. ОКС/УКС","ИТОГО План ввода в ОФ","План ввода в ОФ за счет сод. ОКС/УКС","Итого План ввода в ОФ","План 6","План 7","План 8","План 9","План 10"];

  constructor(private http: HttpClient) {
  }

  typePlansArrayGet(data){
    return this.typePlansArray[data];
  }

// вычисление сумм в подобъектах 13-14/5-6 колонка (итого за год и период)
  calcYear3(data){
    if (data==null) return;
    let sum = Object.entries(data)
      .filter(([k, v],i)=>(k.indexOf("c")==0 && k.indexOf("code")!=0) && !(k=="c0" || k=="c13" || k=="c14"))
      .reduce((total, arg) => total + parseFloat(arg[1]==null ? "0":arg[1].toString()), 0);
    return sum;
  }
  calcYear2(data){
    if (data==null) return;
    let sum = Object.entries(data)
      .filter(([k, v],i)=>k.match(/c\d{1,2}/m) && !(k=="c0" || k=="c13" || k=="c14"))
      .reduce((total, arg) => total + parseFloat(arg[1]==null ? "0":arg[1].toString()), 0);
    //console.log(sum);
    return sum;
  }

  calcPeriod3(data) {
    if (data==null) return;
    let sum = Object.entries(data)
      .filter(([k, v],i)=>(k.indexOf("c")==0 && k.indexOf("code")!=0) && !(k=="c13" || k=="c14"))
      .reduce((total, arg) => total + parseFloat(arg[1]==null ? "0":arg[1].toString()), 0);
    return sum;
  }
  calcPeriod2(data) {
    if (data==null) return;
    let sum = Object.entries(data)
      .filter(([k, v],i)=>k.match(/c\d{1,2}/m) && !(k=="c13" || k=="c14"))
      .reduce((total, arg) => total + parseFloat(arg[1]==null ? "0":arg[1].toString()), 0);
    return sum;
  }

  calcPay2(data) {
    if (data==null) return;
    let sum = Object.entries(data)
      .filter(([k, v],i)=>k.match(/c\d{1,2}/m) && !(k=="c37"))
      .reduce((total, arg) => total + parseFloat(arg[1]==null ? "0":arg[1].toString()), 0);
    return sum.toFixed(2);
  }

  dataSumItogo(data){
    if (data[3]&&data[4]&&data[5]) {
      for (let i=1; i<15; i++ ){
        data[5]["c"+i] = data[4]["c"+i] + data[3]["c"+i]
      }
    }
    return data;
  }

  public getFinDocumentsOsnovanie(objectId=null): Observable<any> {
    // получение либо всех ДО для общей формы, либо для конкретного объекта. modify 24.05.2021 Kokorev
    // common=false - взято из ПИГ ниже
    let object = (objectId!=null)?`&common=false&objectId=${objectId}`:"";
    return this.http.get<any>(`/api/document/list?form=pi1g${object}`);
  }

  public getFinDocumentsDefolt(): Observable<any> {
    return this.http.get<any>(`/api/leader/kvl/default`);
  }



  public getPi1gDDL(objectId=null, leader = false): Observable<any> {
    const params:any = {};
    // получение либо всех ДО для общей формы, либо для конкретного объекта. modify 2.03.2021 Kokorev
    // common=false - взято у Наумов из app-choose-document
    //let object = (objectId!=null)?`&common=false&objectId=${objectId}`:"";
    if (objectId) {
      params.common = false;
      params.objectId = objectId;
    }
    if (leader) {
      params.leader = true;
    }
    return this.http.get<any>(`/api/document/list?form=${leader && 'leader-' || ''}pi1g`, {params});
  }

  public getPi1gMainOld(doId): Observable<any> {
    return this.http.get<any>(`/api/plan/pi1g/object/list?id=${doId}&take=5&skip=5`);
  }

  getPi1gMain(queryParams): Observable<any> {
    return this.http.get<Array<any>>(`/api/plan/pi1g/object/list`, {params: queryParams});
  }

  getObjectsDS(queryParams): Observable<any> {
    return this.http.get<Array<any>>(`/api/plan/ds/object/list`, {params: queryParams});
  }

  getFinanceMain(queryParams): Observable<any> {
    return this.http.get<Array<any>>(`/api/plan/investment/object/list`, {params: queryParams});
  }

  public getContragentDataFull(queryParams): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = this.setHttpParams(httpParams, queryParams);
    return this.http.get<Array<any>>(`/api/contract/contragent/list`, {params: httpParams})
  }

  public getYears(DocOsnId,objectId=null): Observable<any> {
    //для карточки объекта добавляем год
    let object = (objectId!=null)?`&objectId=${objectId}`:"";
    return this.http.get<any>(`/api/plan/pi1g/years?id=${DocOsnId}${object}`);
  }

  public getYearsFin(DocOsnId,objectId=null): Observable<any> {
    //для карточки объекта добавляем год
    let object = (objectId!=null)?`&objectId=${objectId}`:"";
    return this.http.get<any>(`/api/plan/investment/years?id=${DocOsnId}${object}`);
  }

  public getTreeData(): Observable<any> {
    return this.http.get<any>(`/api/plan/financial-plan-section/list?typeId=-9`);
  }
  public getVatsData(): Observable<any> {
    return this.http.get<any>(`/api/plan/vat/list`)
  }
  public getDefVatsData(): Observable<any> {
    return this.http.get<any>(`/api/plan/vat/default`)
  }
  public getContragentData(): Observable<any> {
    return this.http.get<any>(`/api/contract/contragent/list`)
  }
  public getFinanceData(): Observable<any> {
    return this.http.get<any>(`/api/plan/financial-flow-type/list`)
  }
  public getSpecialMarkData(): Observable<any> {
    return this.http.get<any>(`/api/plan/special-mark/list`)
  }
  public getCalculationItemData(): Observable<any> {
    return this.http.get<any>(`/api/plan/calculation-item/list`)
  }

  public getExpDirWithcostItemId(): Observable<any> {
    return this.http.get<any>(`/api/plan/pi1g/expenses-directions`)
  }
  public getExpDir(): Observable<any> {
    return this.http.get<any>(`/api/plan/expenses-direction/list`)
  }
  public getExpDirId(id): Observable<any> {
    return this.http.get<any>(`/api/plan/expenses-direction/list?sectionId=${id}`)
  }

  forciblyOksDistribution(body): Observable<any> {
    return this.http.post<any>(`api/plan/forcibly-oks-distribution`, body);
  }
  forciblyUksDistribution(body): Observable<any> {
    return this.http.post<any>(`api/plan/forcibly-uks-distribution`, body);
  }
  updateContractData(body): Observable<any> {
    return this.http.post<any>(`api/plan/contract-data`, body);
  }
  primaryOksDistribution(year, type): Observable<any> {
    return this.http.post<any>(`api/plan/primary-oks-distribution`, {year, type});
  }

  public getAdjustmentList(options, planId, year) {
    let params = new HttpParams();
    if (options.take) {
      params = params.append('take', options.take.toString());
    }
    if (options.skip) {
      params = params.append('skip', options.skip.toString());
    }
    if (options.filter) {
      params = params.append('filter', JSON.stringify(options.filter));
    }
    if (options.sort) {
      params = params.append('sort', JSON.stringify(options.sort));
    }
    if (options.group) {
      params = params.append('group', JSON.stringify(options.group));
    }
    if (planId) params = params.append('planId', planId.toString());
    if (year) params = params.append('year', year.toString());
    return this.http.get<any>(`api/plan/adjustment/list`, {params});

  }


  public applyAdjustmentObjects(body) {
    return this.http.put<any>(`api/plan/adjustment`, body);
  }

  public getObjs(id, options): Observable<any> {
    let params = new HttpParams();
    params = this.setHttpParams(params, options);
    params = params.append('id', id);
    return this.http.get<Blob>(`/api/plan/investment/object/list`, {params: params});
  }

  public deletePi1gRow(planId, id) {
    let params = new HttpParams();
    params = params.append('planId', planId);
    id.forEach(i => {
      params = params.append('id', i);
    });
    return this.http.delete<any>(`/api/plan/pi1g`, {params});
  }

  public deleteAddRow(id) {
    let params = new HttpParams();
    id.forEach(i => {
      params = params.append('id', i);
    });
    return this.http.delete<any>(`/api/plan/add`, {params});
  }

  setHttpParams(params, queryParams) {
    if (queryParams.take) params = params.append('take', queryParams.take.toString());
    if (queryParams.skip) params = params.append('skip', queryParams.skip.toString());
    if (queryParams.filter) params = params.append('filter', JSON.stringify(queryParams.filter));
    if (queryParams.sort) params = params.append('sort', JSON.stringify(queryParams.sort));
    if (queryParams.group) params = params.set('group', JSON.stringify(queryParams.group));
    return params;
  }

  sendRequest(url: string, method: string = "GET", data: any ={}): any {

    let httpParams = new HttpParams({ fromObject: data });
    let result;

    switch(method) {
      case "GET":
        return this.http.get<any>(url);
        //break;
      case "PUT":
        return this.http.put<any>(url, data);
      //break;
      case "POST":
        return this.http.post<any>(url,data);
      //break;
      case "DELETE":
        return this.http.delete<any>(url, {params:data});
      //break;
    }

    // public putList(apiPut: string, values: any): Observable<any> {
    //   return this.http.put<any>(apiPut, values);
    // }
    // return result
    //   .toPromise()
    //   .then((data: any) => {
    //     return method === "GET" ? {data:data} : {};
    //   })
    //   .catch(e => {
    //     throw e && e.error && e.error.Message;
    //   });
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  summaryT(data,checkBoxThousend){
    if(checkBoxThousend) {
      let su = [];
      su.push(data[0]);
      for (let i = 1;  i < data.length; i++){
        (data[i])?su.push(data[i]/1000):su.push(data[i]);
      }
      return su;
    } else{
      return data;
    }
  }
  summaryQ(data,checkBoxQuarter){
    if(checkBoxQuarter) {
      let su = "";
      // for (let it of data){
      //   su += `${data[0]},${data[1]},${data[2]+data[3]+data[4]},${data[5]+data[6]+data[7]},${data[8]+data[9]+data[10]},${data[11]+data[12]+data[13]},${data[14]/1000},${data[15]/1000}`
      // }
      return data;//JSON.parse("[" + su.substr(0,su.length-1) + "]");
    } else{
      return data;
    }
  }

  ObjsChbQ(data) {
    const su = [];
    for (const it of data) {
      su.push({
        ...it,
        c1: it.c1 + it.c2 + it.c3,
        c2: it.c4 + it.c5 + it.c6,
        c3: it.c7 + it.c8 + it.c9,
        c4: it.c10 + it.c11 + it.c12,
        c5: 0,
        c6: 0,
        c7: 0,
        c8: 0,
        c9: 0,
        c10: 0,
        c11: 0,
        c12: 0
      });
    }
    return su;
  }

  ObjsChbT(data,checkBoxThousand){
    if(checkBoxThousand) {
      let su = "";
      for (let it of data){
        su += `{"id": ${it.id},"planId": ${it.planId},"code": "${it.code}","department": "${it.department}","version": "${it.version}","isFake": ${it.isFake},"typeId": ${it.typeId},
        "name": "${it.name}", "c0": ${it.c0/1000},"c1": ${it.c1/1000},"c2": ${it.c2/1000},"c3": ${it.c3/1000},"c4": ${it.c4/1000},"c5": ${it.c5/1000},"c6": ${it.c6/1000},"c7": ${it.c7/1000},"c8": ${it.c8/1000},"c9": ${it.c9/1000},"c10": ${it.c10/1000},"c11": ${it.c11/1000},"c12": ${it.c12/1000},"c13": ${it.c13/1000},"c14": ${it.c14/1000}},`
      }
      su = su.replace(/\n/gs,"");
      return JSON.parse("[" + su.substr(0,su.length-1) + "]");
    } else{
      return data;
    }
  }

  SubObjsChbQ(data) {
    const su = [];
    for (const it of data) {
      su.push({
        ...it,
        c1: it.c1 + it.c2 + it.c3,
        c2: it.c4 + it.c5 + it.c6,
        c3: it.c7 + it.c8 + it.c9,
        c4: it.c10 + it.c11 + it.c12,
        c5: 0,
        c6: 0,
        c7: 0,
        c8: 0,
        c9: 0,
        c10: 0,
        c11: 0,
        c12: 0
      });
    }
    return su;
  }

  SubObjsChbT(data,checkBoxThousend){
    if(checkBoxThousend) {
      let su = "";
      for (let it of data){
        su += `{"objectId": ${it.objectId},"sectionId": ${it.sectionId},"c0": 0,"c1": ${it.c1/1000},"c2": ${it.c2/1000},"c3": ${it.c3/1000},"c4": ${it.c4/1000},"c5": ${it.c5/1000},"c6": ${it.c6/1000},"c7": ${it.c7/1000},"c8": ${it.c8/1000},"c9": ${it.c9/1000},"c10": ${it.c10/1000},"c11": ${it.c11/1000},"c12": ${it.c12/1000},"c13": 0,"c14": 0},`
      }
      return JSON.parse("[" + su.substr(0,su.length-1) + "]");
    } else{
      return data;
    }
  }

  SubSubObjsChbQ(data, checkBoxQuarter){
    if(checkBoxQuarter) {
      let su = "";
      for (let it of data){
        su += `{"id": ${it.id},"code": "${it.code}","name": "${it.name}","c0": 0,"c1": ${it.c1 + it.c2 + it.c3},"c2": ${it.c4 + it.c5 + it.c6},"c3": ${it.c7 + it.c8 + it.c9},"c4": ${it.c10 + it.c11 + it.c12},"c5": 0,"c6": 0,"c7": 0,"c8": 0,"c9": 0,"c10": 0,"c11": 0,"c12": 0,"c13": 0,"c14": 0},`
      }
      return JSON.parse("[" + su.substr(0,su.length-1) + "]");
    } else{
      return data;
    }
  }

  SubSubObjsChbT(data, checkBoxThousend){
    if(checkBoxThousend) {
      let su = "";
      for (let it of data){
        su += `{"id": ${it.id},"code": "${it.code}","name": "${it.name}","c0": 0,"c1": ${it.c1/1000},"c2": ${it.c2/1000},"c3": ${it.c3/1000},"c4": ${it.c4/1000},"c5": ${it.c5/1000},"c6": ${it.c6/1000},"c7": ${it.c7/1000},"c8": ${it.c8/1000},"c9": ${it.c9/1000},"c10": ${it.c10/1000},"c11": ${it.c11/1000},"c12": ${it.c12/1000},"c13": 0,"c14": 0},`
      }
      return JSON.parse("[" + su.substr(0,su.length-1) + "]");
    } else{
      return data;
    }
  }

  public setContractPi1g(body): Observable<any> {
    return this.http.put<any>(`api/plan/pi1g/contract`, body);
  }

  public setContract(body): Observable<any> {
    return this.http.put<any>(`api/plan/contract`, body);
  }
}


export class Objs {
  id: number;
  idParent: number;
  name: string;
  filial?:string;
  version?:string;
  typePlan?: number;
  c0?: number;
  c1?: number;
  c2?: number;
  c3?: number;
  c4?: number;
  c5?: number;
  c6?: number;
  c7?: number;
  c8?: number;
  c9?: number;
  c10?: number;
  c11?: number;
  c12?: number;
  c13?: number;
  c14?: number;
}

export class SubObjs {
  id: number;
  parentId: number;
  name: string;
  typePlan: number;
  subobjectId?: number;
  c0: number;
  c1: number;
  c2: number;
  c3: number;
  c4: number;
  c5: number;
  c6: number;
  c7: number;
  c8: number;
  c9: number;
  c10: number;
  c11: number;
  c12: number;
  c13?:number;
  c14?:number;
}

export class pi1g {
  objectId: number;
  code: string;
  name: string;
  department: string;
  version: string;
  expensesDirectionId: number;
  sectionId: number;
  form: string;
  c0: number;
  id: number;
  c1: number;
  c2: number;
  c3: number;
  c4: number;
  c5: number;
  c6: number;
  c7: number;
  c8: number;
  c9: number;
  c10: number;
  c11: number;
  c12: number;
  c13: number;
  contractId?: any;
  contractNumber?: any;
  vatId?: any;
  contragentId?: any;
  contractBeginDate?: any;
  contractEndDate?: any;
  budgetNumber?: any;
  contractYear?: any;
  financialFlowTypeId?: any;
  specialMarkId?: any;
  calculationItemId?: any;
  closed: number;
}


