import {Component, OnInit, Input, ViewChild, Output, EventEmitter} from '@angular/core';
import {ConfirmService} from '../components/confirm/confirm.service';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import {DxDropDownBoxComponent} from 'devextreme-angular';
import {DocumentService} from '../../../services/document.service';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
  providers: [DocumentService]
})
export class VersionComponent implements OnInit {
  lkk = false;
  loading = false;
  canAddNewVersion = false;
  canDeleteVersion = false;
  dataSourceVersions = [];
  selectedRowKeys = [];
  selectedDocumentData;

  @Input() documentId; // id документа
  @Input() additionalColumns = []; // дополнительные колонки для таблицы версий
  @Input() isDirty; // изменены ли данные

  @Output() clickedCreateVersion: EventEmitter<any> = new EventEmitter<any>(); // нажатие Создать
  @Output() clickedDeleteVersion: EventEmitter<any> = new EventEmitter<any>(); // нажатие Удалить
  @Output() onChangeVersion: EventEmitter<any> = new EventEmitter<any>(); // событие изменения версии

  @ViewChild('versionsList', {static: false}) versionsList: DxDropDownBoxComponent;

  constructor(
    private confirmDialog: ConfirmService,
    private router: Router,
    private authService: AuthenticationService,
    private docService: DocumentService
  ) {
    this.lkk = this.authService.USER.lkk;
    this.version_displayExpr = this.version_displayExpr.bind(this);
  }

  ngOnInit() {
    if (!this.versionsList) {
      this.refreshData(this.documentId);
    }
  }

  refreshData(id = null) {
    this.documentId = id || this.documentId;
    if (this.documentId) {
      this.loading = true;
      this.docService.getDocumentVersions(this.documentId).toPromise().then(data => {
        this.dataSourceVersions = data;
        this.selectedDocumentData = data.find(x => x.id === this.documentId);
        this.selectedRowKeys[this.documentId];
        this.showButtons();
      }).finally(() => {
        this.loading = false;
      });
    }
  }

  setSelectedVersion(id) {
    this.documentId = id;
    this.selectedRowKeys[this.documentId];
    this.selectedDocumentData = this.dataSourceVersions.find(x => x.id === this.documentId);
  }

  createVersion(event: MouseEvent) {
    this.clickedCreateVersion.emit(event);
  }

  deleteVersion(event: MouseEvent) {
    this.clickedDeleteVersion.emit(event);
  }

  version_displayExpr() {
    return this.selectedDocumentData && this.selectedDocumentData.version || '1.0';
  }

  changeVersion(e) {
    if (!e) {
      return false;
    } else {
      this.versionsList.instance.close();
      this.documentId = e.data.id;
      this.selectedDocumentData = this.dataSourceVersions.find(x => x.id === this.documentId);
      this.showButtons();
      this.onChangeVersion.emit(this.documentId);
      this.versionsList.instance.repaint();
    }
  }

  showButtons() {
    this.canAddNewVersion = [3, 5, 8].includes(this.selectedDocumentData.statusId) && this.dataSourceVersions[0].id === this.documentId;
    this.canDeleteVersion = this.selectedDocumentData.statusId === 1;
  }

  onRowPrepared(e) {
    if (e.rowType === 'data' && e.data.id === this.documentId) {
      e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
      e.rowElement.classList.add('background-light-gray');
    }
  }
}
