import {gridDictionary} from '../../common-element/list-generator/generator-interfaces';

export class Dictionaries {
  public static dictionaries: gridDictionary[] = [
    {
      id: 'users',
      name: 'Пользователи',
      fileNameExport: 'Пользователи',
      showCountRow: true,
      get: 'api/admin/user/list',
      primaryKey: 'id',
      allowCopy: true,
      editRole: 'user',
      remoteOperations: true,
      countPage: 20,
      wordWrap: true,
      allowRefresh: true,
      dropDownButton: [
        {
          hint: 'Удалить/Блокировать',
          icon: 'trash',
          dropDownWidth: 350,
          items: [
            {value: 3, name: 'Удалить', icon: 'trash'},
            {value: 4, name: 'Блокировать', icon: 'card'}],
          checkRights: 'W'
        },
        {
          hint: 'Добавить пользователя',
          icon: 'add',
          dropDownWidth: 350,
          items: [
            {value: 1, name: 'Создать пользователя из ActiveDirectory', icon: 'fa fa-users'},
            {value: 2, name: 'Создать пользователя с логином-паролем', icon: 'fa fa-user'}],
          checkRights: 'W'
        }
      ],
      hideRowStatus: true,
      rowPrepared: (rowElement, rowInfo) => {
        if (rowInfo.status !== 'А' && rowInfo.status !== 'Б') {
          rowElement.style.backgroundColor = '#FFC5B6';
        } else if (rowInfo.status === 'Б') {
          rowElement.style.backgroundColor = '#E5E5E5';
        }
      },
      defaultFilter: {
        dataSource: {
          values: {statusDeleted: false, statusBlocked: false},
          filterText: 'Не показывать удаленных и заблокированных',
          setFilterText: (data) => {
            if (!data.statusDeleted && !data.statusBlocked) {
              return 'Не показывать удаленных и заблокированных';
            } else if (data.statusDeleted && !data.statusBlocked) {
              return 'Не показывать заблокированные';
            } else if (!data.statusDeleted && data.statusBlocked) {
              return 'Не показывать удаленных';
            } else {
              return 'Показывать всех';
            }
          },
          getFilterInfo: (data) => {
            const filter = [];
            if (!data.statusDeleted) {
              filter.push(['status', '<>', 'У']);
            }
            if (!data.statusBlocked) {
              if (filter.length > 0) {
                filter.push('and');
              }
              filter.push(['status', '<>', 'Б']);
            }
            return filter;
          }
        },
        items: [
          {dataField: 'statusDeleted', editorType: 'dxCheckBox', label: 'Показывать удаленных'},
          {dataField: 'statusBlocked', editorType: 'dxCheckBox', label: 'Показывать заблокированных'}]
      },
      columns: [
        {
          caption: 'Логин пользователя',
          dataField: 'login',
          dataType: 'string'
        },
        {
          caption: 'ФИО пользователя',
          dataField: 'fullName',
          dataType: 'string',
          sort: 'asc'
        },
        {
          caption: 'Филиал',
          dataField: 'branch',
          dataType: 'string'
        },
        {
          caption: 'Подразделение',
          dataField: 'department',
          dataType: 'string'
        },
        {
          caption: 'Должность',
          dataField: 'position',
          dataType: 'string'
        },
        {
          caption: 'Электронная почта',
          dataField: 'email',
          dataType: 'string'
        },
        {
          caption: 'Рабочий телефон',
          dataField: 'workPhone',
          dataType: 'string'
        },
        {
          caption: 'Статус',
          dataField: 'status',
          dataType: 'string',
          defaultFilterExpression: true,
          /*HeaderFilter(data) {
            data.dataSource.postProcess = (results) => {
              const filter = [];
              results.filter(x => x.value).forEach(x => {
                let s = x.value.substring(0, 1);
                if (!filter.includes(s)) filter.push(s);
              });
              results = [];
              results.push({text: '(Пустое)', value: null});
              filter.forEach(x => results.push({text: x, value: x}));
              return results;
            };
          },*/
          getTooltipText(data): string {
            switch (data.status[0]) {
              case 'А':
                return 'Активный';
              case 'Б':
                return 'Заблокирован';
              case 'У':
                return 'Удален';
            }
          }
        },
        {
          caption: 'Роли',
          dataField: 'roles',
          dataType: 'string',
          defaultHeaderFilter: true,
          defaultFilterExpression: true,
          cellTemplate: 'longTextTemplate'
        }]
    },
    {
      id: 'users-lk',
      name: 'Пользователи',
      fileNameExport: 'Пользователи',
      showCountRow: true,
      get: 'api/admin/user/listlk',
      primaryKey: 'id',
      allowCopy: true,
      editRole: 'user',
      remoteOperations: true,
      countPage: 20,
      wordWrap: true,
      allowRefresh: true,
      dropDownButton: [
        {
          hint: 'Удалить/Блокировать',
          icon: 'trash',
          dropDownWidth: 350,
          items: [
            {value: 3, name: 'Удалить', icon: 'trash'},
            {value: 4, name: 'Блокировать', icon: 'card'}],
          checkRights: 'W'
        },
        {
          hint: 'Добавить пользователя',
          icon: 'add',
          dropDownWidth: 350,
          items: [
            {value: 2, name: 'Создать пользователя с логином-паролем', icon: 'fa fa-user'}],
          checkRights: 'W'
        }
      ],
      hideRowStatus: true,
      rowPrepared: (rowElement, rowInfo) => {
        if (rowInfo.status !== 'А' && rowInfo.status !== 'Б') {
          rowElement.style.backgroundColor = '#FFC5B6';
        } else if (rowInfo.status === 'Б') {
          rowElement.style.backgroundColor = '#E5E5E5';
        }
      },
      defaultFilter: {
        dataSource: {
          values: {statusDeleted: false, statusBlocked: false},
          filterText: 'Не показывать удаленных и заблокированных',
          setFilterText: (data) => {
            if (!data.statusDeleted && !data.statusBlocked) {
              return 'Не показывать удаленных и заблокированных';
            } else if (data.statusDeleted && !data.statusBlocked) {
              return 'Не показывать заблокированные';
            } else if (!data.statusDeleted && data.statusBlocked) {
              return 'Не показывать удаленных';
            } else {
              return 'Показывать всех';
            }
          },
          getFilterInfo: (data) => {
            const filter = [];
            if (!data.statusDeleted) {
              filter.push(['status', '<>', 'У']);
            }
            if (!data.statusBlocked) {
              if (filter.length > 0) {
                filter.push('and');
              }
              filter.push(['status', '<>', 'Б']);
            }
            return filter;
          }
        },
        items: [
          {dataField: 'statusDeleted', editorType: 'dxCheckBox', label: 'Показывать удаленных'},
          {dataField: 'statusBlocked', editorType: 'dxCheckBox', label: 'Показывать заблокированных'}]
      },
      columns: [
        {
          caption: 'ФИО пользователя',
          dataField: 'fullName',
          dataType: 'string',
          sort: 'asc'
        },
        {
          caption: 'Контрагент',
          dataField: 'contragent',
          dataType: 'string'
        },
        {
          caption: 'Электронная почта',
          dataField: 'email',
          dataType: 'string'
        },
        {
          caption: 'Рабочий телефон',
          dataField: 'workPhone',
          dataType: 'string'
        },
        {
          caption: 'Статус',
          dataField: 'status',
          dataType: 'string',
          defaultFilterExpression: true,
          /*HeaderFilter(data) {
            data.dataSource.postProcess = (results) => {
              const filter = [];
              results.filter(x => x.value).forEach(x => {
                let s = x.value.substring(0, 1);
                if (!filter.includes(s)) filter.push(s);
              });
              results = [];
              results.push({text: '(Пустое)', value: null});
              filter.forEach(x => results.push({text: x, value: x}));
              return results;
            };
          },*/
          getTooltipText(data): string {
            switch (data.status[0]) {
              case 'А':
                return 'Активный';
              case 'Б':
                return 'Заблокирован';
              case 'У':
                return 'Удален';
            }
          }
        },
        {
          caption: 'Роли',
          dataField: 'roles',
          dataType: 'string',
          defaultHeaderFilter: true,
          defaultFilterExpression: true,
          cellTemplate: 'longTextTemplate'
        }]
    },
    {
      id: 'roles',
      name: 'Роли',
      fileNameExport: 'Роли',
      showCountRow: true,
      get: 'api/admin/role/list',
      editRole: 'roleCard',
      fastDelete: true,
      delete: 'api/admin/role',
      primaryKey: 'id',
      allowCopy: true,
      remoteOperations: true,
      countPage: 20,
      wordWrap: true,
      allowRefresh: true,
      allowAdd: true,
      hideRowStatus: true,
      columns: [
        {
          caption: 'Код',
          dataField: 'code',
          dataType: 'string',
          width: 75,
          sort: 'asc'
        },
        {
          caption: 'Роль',
          dataField: 'name',
          dataType: 'string',
          width: 150
        },
        {
          caption: 'Описание',
          dataField: 'description',
          dataType: 'string',
          width: 600
        },
        {
          caption: 'Области использования',
          dataField: 'areas',
          dataType: 'string',
          defaultHeaderFilter: true,
          defaultFilterExpression: true
        }]
    },
    {
      id: 'roles-lk',
      name: 'Роли',
      fileNameExport: 'Роли',
      showCountRow: true,
      get: 'api/admin/role/listlk',
      editRole: 'roleCard',
      fastDelete: true,
      delete: 'api/admin/role-lk',
      primaryKey: 'id',
      allowCopy: true,
      remoteOperations: true,
      countPage: 20,
      wordWrap: true,
      allowRefresh: true,
      allowAdd: true,
      hideRowStatus: true,
      columns: [
        {
          caption: 'Код',
          dataField: 'code',
          dataType: 'string',
          width: 75,
          sort: 'asc'
        },
        {
          caption: 'Роль',
          dataField: 'name',
          dataType: 'string',
          width: 150
        },
        {
          caption: 'Описание',
          dataField: 'description',
          dataType: 'string',
          width: 600
        },
        {
          caption: 'Области использования',
          dataField: 'areas',
          dataType: 'string',
          defaultHeaderFilter: true,
          defaultFilterExpression: true
        }]
    },
    {
      id: 'user-roles',
      // showCaption: true,
      name: 'Роли пользователя',
      fileNameExport: 'Роли пользователя',
      showCountRow: true,
      primaryKey: 'id',
      allowAdd: true,
      allowDelete: true,
      addRowCustom: true,
      columns: [{
        caption: 'Код',
        dataField: 'code',
        dataType: 'string'
      },
        {
          caption: 'Роль',
          dataField: 'name',
          dataType: 'string'
        }
      ]
    },
    {
      id: 'user-rights',
      // showCaption: true,
      name: 'Права пользователя',
      fileNameExport: 'Права пользователя',
      showCountRow: true,
      primaryKey: 'id',
      editMode: 'cell',
      allowAdd: true,
      allowUpdate: true,
      allowDelete: true,
      addRowCustom: true,
      columns: [{
        caption: 'Подразделение',
        dataField: 'department.name',
        dataType: 'string',
        allowEdit: false
      }, {
        caption: 'Код подразделения',
        dataField: 'department.code',
        dataType: 'string',
        allowEdit: false
      },
        {
          caption: 'Доступ',
          dataField: 'departmentPermission',
          dataType: 'string',
          required: true,
          dictionary: {
            dataSource: [
              {id: 'W', name: 'Запись'},
              {id: 'R', name: 'Чтение'}
            ],
            name: 'name',
            id: 'id'
          }
        },
        {
          caption: 'Маска кода объекта',
          dataField: 'mask',
          dataType: 'string'
        },
        {
          caption: 'Доступ',
          dataField: 'maskRight',
          dataType: 'string',
          dictionary: {
            dataSource: [
              {id: 'W', name: 'Запись'},
              {id: 'R', name: 'Чтение'}
            ],
            name: 'name',
            id: 'id'
          }
        }
      ]
    },
    {
      id: 'role-areas',
      name: 'Области использования роли',
      fileNameExport: 'Области использования роли',
      showCountRow: true,
      primaryKey: 'id',
      editMode: 'cell',
      allowAdd: true,
      allowUpdate: true,
      allowDelete: true,
      addRowCustom: true,
      columns: [{
        caption: 'Область использования',
        dataField: 'area.name',
        dataType: 'string',
        allowEdit: false
      },
        {
          caption: 'Тип доступа',
          dataField: 'permission',
          dataType: 'string',
          required: true,
          dictionary: {
            dataSource: [
              {id: 'W', name: 'Запись'},
              {id: 'R', name: 'Чтение'}
            ],
            name: 'name',
            id: 'id'
          }
        }
      ]
    },
    {
      id: 'role-areas-lk',
      name: 'Области использования роли',
      fileNameExport: 'Области использования роли',
      showCountRow: true,
      primaryKey: 'id',
      editMode: 'cell',
      allowAdd: true,
      allowUpdate: true,
      allowDelete: true,
      addRowCustom: true,
    },
    {
      id: 'role-documents',
      name: 'Типы документов роли',
      fileNameExport: 'Типы документов роли',
      showCountRow: true,
      primaryKey: 'typeId',
      editMode: 'cell',
      allowAdd: true,
      allowUpdate: true,
      allowDelete: true,
      addRowCustom: true,
      columns: [{
        caption: 'Тип документа',
        dataField: 'type.name',
        dataType: 'string'
      },
        {
          caption: 'Создание',
          dataField: 'creating',
          dataType: 'boolean'
        },
        {
          caption: 'Чтение',
          dataField: 'reading',
          dataType: 'boolean'
        },
        {
          caption: 'Запись',
          dataField: 'writing',
          dataType: 'boolean'
        },
        {
          caption: 'Удаление',
          dataField: 'deleting',
          dataType: 'boolean'
        }, {
          caption: 'Аннулир. после утвержд.',
          dataField: 'cancellation',
          dataType: 'boolean'
        },
        {
          caption: 'Все',
          dataField: 'all',
          dataType: 'boolean',
          cancelUpdate: false,
          allowExporting: false,
          onChangeValue: (dataSource, dataRow) => {
            const row = dataSource.find(x => x.typeId === dataRow.key);
            row.reading = row.creating = row.writing = row.deleting = row.cancellation = Object.values(dataRow.newData)[0];
          }
        }
      ]
    },
    {
      id: 'manuals',
      name: 'Инструкции',
      fileNameExport: 'Инструкции',
      showCountRow: true,
      get: 'api/admin/manual/list',
      fastDelete: true,
      delete: 'api/admin/manual',
      primaryKey: 'id',
      allowCopy: true,
      editRole: 'manualCard',
      remoteOperations: true,
      countPage: 15,
      allowRefresh: true,
      allowAdd: true,
      hideRowStatus: true,
      columns: [{
        caption: 'Код',
        dataField: 'code',
        dataType: 'string',
        sort: 'asc'
      },
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string'
        }, {
          caption: 'Файл',
          dataField: 'fileName',
          dataType: 'string',
          buttons: [
            {
              icon: 'download',
              hint: 'Скачать',
              id: 'download',
              show: (data) => {
                return data.fileName ? 'block' : 'none';
              }
            }
          ]
        },
        {
          caption: 'Интерфейс',
          dataField: 'forms',
          dataType: 'string',
          defaultHeaderFilter: true,
          defaultFilterExpression: true
        }]
    }, {
      id: 'manuals-lk',
      name: 'Инструкции',
      fileNameExport: 'Инструкции',
      showCountRow: true,
      get: 'api/admin/manual-lk/list',
      fastDelete: true,
      delete: 'api/admin/manual-lk',
      primaryKey: 'id',
      allowCopy: true,
      editRole: 'manualCard',
      remoteOperations: true,
      countPage: 15,
      allowRefresh: true,
      allowAdd: true,
      hideRowStatus: true,
      columns: [{
        caption: 'Код',
        dataField: 'code',
        dataType: 'string',
        sort: 'asc'
      },
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string'
        }, {
          caption: 'Файл',
          dataField: 'fileName',
          dataType: 'string',
          buttons: [
            {
              icon: 'download',
              hint: 'Скачать',
              id: 'download',
              show: (data) => {
                return data.fileName ? 'block' : 'none';
              }
            }
          ]
        },
        {
          caption: 'Интерфейс',
          dataField: 'forms',
          dataType: 'string',
          defaultHeaderFilter: true,
          defaultFilterExpression: true
        }]
    },
    {
      id: 'organization-official',
      name: 'Должностные лица',
      fileNameExport: 'Должностные лица',
      showCountRow: true,
      get: 'api/organization/official/list',
      primaryKey: 'id',
      remoteOperations: true,
      countPage: 15,
      choiceOnClick: true,
      hideRowStatus: true,
      columns: [{
        caption: 'Фамилия',
        dataField: 'lastName',
        dataType: 'string'
      },
        {
          caption: 'Имя',
          dataField: 'firstName',
          dataType: 'string'
        },
        {
          caption: 'Отчество',
          dataField: 'middleName',
          dataType: 'string'
        },
        {
          caption: 'Подразделение',
          dataField: 'departmentName',
          dataType: 'string'
        },
        {
          caption: 'Должность',
          dataField: 'position',
          dataType: 'string'
        },
        {
          caption: 'Телефон',
          dataField: 'phone',
          dataType: 'string',
        },
        {
          caption: 'Электронная почта',
          dataField: 'email',
          dataType: 'string'
        }]
    },
    {
      id: 'organization-department',
      name: 'Подразделения',
      fileNameExport: 'Подразделения',
      showCountRow: true,
      get: 'api/organization/department/list',
      primaryKey: 'id',
      remoteOperations: true,
      countPage: 15,
      allowChoice: true,
      hideRowStatus: true,
      columns: [{
        caption: 'Наименование',
        dataField: 'name',
        dataType: 'string'
      }
      ]
    },
    {
      id: 'areas',
      name: 'Области использования',
      fileNameExport: 'Области использования',
      showCountRow: true,
      get: 'api/admin/area/list',
      primaryKey: 'id',
      remoteOperations: true,
      countPage: 15,
      allowChoice: true,
      hideRowStatus: true,
      columns: [{
        caption: 'Наименование',
        dataField: 'name',
        dataType: 'string'
      }
      ]
    },
    {
      id: 'history',
      name: 'История',
      fileNameExport: 'История',
      showCountRow: true,
      get: 'api/admin/history/list',
      primaryKey: 'id',
      remoteOperations: true,
      countPage: 15,
      hideRowStatus: true,
      columns: [
        {
          caption: 'Таблица',
          dataField: 'modelName',
          dataType: 'string'
        },
        {
          caption: 'Колонка',
          dataField: 'attributeName',
          dataType: 'string'
        },
        {
          caption: 'Пользователь',
          dataField: 'userName',
          dataType: 'string'
        },
        {
          caption: 'Старое значение',
          dataField: 'oldValue',
          dataType: 'string'
        },
        {
          caption: 'Новое значение',
          dataField: 'newValue',
          dataType: 'string'
        },
        {
          caption: 'Делал',
          dataField: 'action',
          dataType: 'string'
          ,
          getTooltipText(data): string {
            switch (data.action[0]) {
              case 'u':
                return 'Изменил';
              case 'i':
                return 'Добавил';
              case 'd':
                return 'Удален';
            }
          }
        },
        {
          caption: 'Когда',
          dataField: 'createdAt',
          dataType: 'datetime'
        },
        {
          caption: 'id записи',
          dataField: 'entityId',
          dataType: 'string'
        }
        // ,
        // {
        //   caption: 'Описание',
        //   dataField: 'description',
        //   dataType: 'string'
        // }
      ]
    },
    {
      id: 'selected-interfaces',
      name: 'Интерфесы',
      fileNameExport: 'Интерфесы',
      showCountRow: true,
      primaryKey: 'id',
      countPage: 15,
      allowAdd: true,
      allowDelete: true,
      addRowCustom: true,
      columns: [{
        caption: 'Интерфейс',
        dataField: 'name',
        dataType: 'string'
      }
      ]
    },
    {
      id: 'integration-protocol',
      name: 'Протокол интеграций',
      fileNameExport: 'Протокол интеграций',
      showCountRow: true,
      primaryKey: 'id',
      countPage: 15,
      allowAdd: false,
      allowDelete: false,
      addRowCustom: false,
      allowUpdate: false,
      columns: [{
        caption: 'dat',
        dataField: 'dat',
        dataType: 'string'
      },
        {
          caption: 'errLevel',
          dataField: 'errLevel',
          dataType: 'string'
        }, {
          caption: 'text',
          dataField: 'text',
          dataType: 'string'
        }, {
          caption: 'action',
          dataField: 'action',
          dataType: 'string'
        }, {
          caption: 'terminal',
          dataField: 'terminal',
          dataType: 'string'
        }, {
          caption: 'userName',
          dataField: 'userName',
          dataType: 'string'
        }, {
          caption: 'StatusCode',
          dataField: 'StatusCode',
          dataType: 'string'
        },
        {
          caption: 'responseText',
          dataField: 'responseText',
          dataType: 'string'
        },
      ]
    }
  ];
}
