export class TestSchemaAgreement {

  public static testData = {
    viewDiagram: {
      "page":{"width":8391,"height":11906,"pageColor":-1,"pageWidth":8391,"pageHeight":11906,"pageLandscape":false,"units":1},
      "connectors":[],
      "shapes":[{"key":"1dqd12d12d-1d12d-12312d-12e","locked":false,"zIndex":0,"type":"shape1","text":"На оформлении","x":3600,"y":3600,"width":2160,"height":1440}]},
    dataDiagram: { stages:[{
        key: "1",
        stageId: 1,
        finalStage: null,
        countDay: null,
        firstStage: false,
        description: null,
        orgUnits: null
      }], actions: []}
  };

  public static stages = [{
    id: 1,
    name: "На оформлении"
  }, {
    id: 2,
    name: "Проверка в тех. блоке"
  }, {
    id: 3,
    name: "Аннулировано"
  }, {
    id: 4,
    name: "Устранение замечаний"
  }, {
    id: 5,
    name: "На визировании"
  }, {
    id: 6,
    name: "Согласован"
  }];

  public static finalStage = [{
    id: 1,
    name: 'Согласовано'
  },{
  id: 2,
  name: 'Отклонено'
}];

  public static actions = [{
    id: 1,
    name: "Аннулировать"
  }, {
    id: 2,
    name: "Проверено"
  }, {
    id: 3,
    name: "Выявлены замечания"
  }, {
    id: 4,
    name: "Передать на визирование"
  }, {
    id: 5,
    name: "Согласовано"
  }];

  public static conditionFields = [{
    caption: "Тип документа",
    dataField: "idDocType",
    dataType: "number",
    lookup: {
      displayExpr: 'name',
      valueExpr: 'id',
      dataSource: [
        {id: 1, name: "Type #3"},
        {id: 2, name: "Type #2"}
      ]
    }
  }];
}
