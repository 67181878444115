import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material";
import {DiagramAgreementService} from "../../diagram-agreement/diagram-agreement.service";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {SubscriptionLike} from "rxjs";
import {DxTreeListComponent} from "devextreme-angular";
import notify from "devextreme/ui/notify";
import {
  faSnowman,
  faInfoCircle,
  faHome,
  faExclamationTriangle,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';
import {CommonService} from "../common.service";

@Component({
  selector: 'app-choose-executor',
  templateUrl: './choose-executor.component.html',
  styleUrls: ['./choose-executor.component.scss']
})
export class ChooseExecutorComponent implements OnInit, OnDestroy, AfterViewInit {
  faUserTie = faUserTie;
  faHome = faHome;
  faExclamationTriangle = faExclamationTriangle;
  loading = true;
  dataSource: any;
  dataSourceContragent;
  expandedRowKeys = [];
  store: CustomStore;
  subscriptions: SubscriptionLike[] = [];
  filterDepartment = [];
  selectedRowsData: any;
  selectedRowsDataContragent: any;
  first = true;
  faInfoCircle = faInfoCircle;
  warningMessage = 'Для данного подразделения не заданы исполнители по умолчанию!';
  @ViewChild('treeOrgList', {static: false}) treeOrgList: DxTreeListComponent;
  priorities = [{id: 1, name: 'Сотрудники СК'}, {id: 2, name: 'Подрядчики'}];
  radioValue;
  private btnChoice: any = null;
  private btnChoiceContragent: any = null;
  showTerminated = false;
  title = 'Выбор исполнителя';
  accountsDataMap = {};
  selectedAccount;

  constructor(public dialogRef: MatDialogRef<ChooseExecutorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private service: DiagramAgreementService,
              private commonService: CommonService) {
    this.title = this.data && this.data.title || this.title;
    this.radioValue = this.data.onlyContragent ? 2 : (this.data.pageOrg || 1);
    if (this.data.idStage) {
      this.loading = true;
      this.subscriptions.push(this.service.getDefaultDepartment(this.data.idStage, this.data.idDoc).subscribe(data => {
        this.filterDepartment = data;
        data.forEach(x => this.expandedRowKeys.push(x));
        this.loading = false;
      }, error => {
        this.dialogRef.close();
        this.loading = false;
      }));
    } else this.loading = false;

    this.dataSource = new DataSource({
      store: new CustomStore({
        key: 'id',
        load: (options) => {
          let _filter = null;
          if (options.parentIds) options.parentIds = options.parentIds.filter(x => x);
          if (!options.parentIds || options.parentIds.length === 0) _filter = options.filter;
          if (this.filterDepartment && this.filterDepartment.length > 0) {
            let arrayFilters = [];
            this.filterDepartment.forEach(x => {
              if (arrayFilters.length > 0) arrayFilters.push('or');
              arrayFilters.push(['id', '=', x], 'or', [['parentId', '=', x], 'and', ['id', '<', '0']]);
            });
            if (this.first || (options.parentIds && options.parentIds.includes(0))) {
              options.parentIds = [];
              _filter = arrayFilters;
            } else if (options.parentIds && options.parentIds.length === 1 && this.filterDepartment.includes(options.parentIds[0])) {
              _filter = ['id', '<', '0'];
            } else if (_filter && _filter[0] !== 'id') {
              _filter = [_filter, 'and', arrayFilters];
            } else if (_filter && _filter[0] === 'id' && _filter[2] === null) {
              return [];
            }
            this.first = false;
          }
          return this.service.getTreeOrg(options.parentIds, _filter, options.sort, this.data.department, this.data.agreement, this.showTerminated).toPromise().then(data => {
            return data.map(x => {

              if (this.data.department) {
                x.isDepartment = true;
                this.warningMessage = 'В данном подразделении нет сотрудников!';
              }

              if (!x.isDepartment) {
                let parent = this.treeOrgList.instance.getDataSource().items().find(p => p.key === x.parentId);
                if (parent) parent = parent.data;
                else if (!parent) parent = data.find(p => p.id === x.parentId);
                if (parent) {
                  x.departmentName = parent.name;
                  x.depShortName = parent.shortName;
                }
              }
              /*if (this.data.filterDepartment && this.data.filterDepartment.includes(x.id))
                x.parentId = null;*/
              return x;
            });
          }).catch(error => {
            throw error.error;
          })
        }
      })
    });

    this.dataSourceContragent = new DataSource({
      paginate: true,
      pageSize: 15,
      store: new CustomStore({
        key: 'id',
        load: (options) => {
          return this.service.getContractorList(options).toPromise().then(data => {
              return {
                type: 'array',
                key: 'id',
                data: data.items,
                totalCount: data.count
              };
            }
          ).catch(error => {
            throw error.error;
          });
        }
      })
    });
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (subscription) => subscription.unsubscribe());
    this.subscriptions = [];
    if (this.dataSource) { this.dataSource.dispose(); }
    if (this.dataSourceContragent) { this.dataSourceContragent.dispose(); }
    if (this.accountsDataMap) {
      Object.keys(this.accountsDataMap).forEach(x => this.accountsDataMap[x].dataSource.dispose());
    }
  }

  ngAfterViewInit(): void {
  }

  onToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
        location: 'before',
        template: 'showTerminated',
        locateInMenu: 'auto',
        showText: 'inMenu'
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Выбрать',
          height: 36,
          width: 36,
          icon: 'check',
          onInitialized: (args: any) => {
            this.btnChoice = args.component;
          },
          onClick: this.onChoiceRows.bind(this)
        }
      });

  }

  onCellPrepared(e) {
    if (e.rowType === "data" && e.columnIndex === 0 && !this.data.singleSelect) {
      if (this.data.hideSelect && e.cellElement.children.length > 0 && this.data.hideSelect.includes(e.key))
        (e.cellElement.children[0].children[e.cellElement.children[0].children.length - 1] as HTMLElement).style.display = "none";
      else if (e.data.isDepartment && Number(e.data.hasExecutors) === 0 && e.cellElement.children.length > 0)
        (e.cellElement.children[0].children[e.cellElement.children[0].children.length - 1] as HTMLElement).style.display = "none";
      else if (e.data.isDepartment && this.data.filterDepartment && !this.data.filterDepartment.includes(e.data.id) && e.cellElement.children.length > 0)
        (e.cellElement.children[0].children[e.cellElement.children[0].children.length - 1] as HTMLElement).style.display = "none";
    }
  }

  onRowPrepared(e) {
    if (e.rowType === "data") {
      if (this.data.hideSelect && this.data.hideSelect.includes(e.key))
        e.rowElement.style.backgroundColor = '#cbe7ff';
      if (e.data.isTerminated)
        e.rowElement.style.backgroundColor = '#ffcbd5';
    }
  }

  onSelectionChanged(e) {
    if (this.data.singleSelect) {
      let disabled = false;
      if (e.selectedRowsData[0] && e.selectedRowsData[0].isDepartment) {
        disabled = true;
      } else if (this.data.executors && this.data.executors.includes(e.selectedRowsData[0].id))
        disabled = true;
      this.btnChoice.option({
        disabled: disabled
      });
      if (disabled) {
        this.selectedRowsData = [];
        return;
      }
    }
    this.selectedRowsData = e.selectedRowsData;
  }

  onChoiceRows() {
    if (this.data.singleSelect) {
      if (this.data.selectedRowKeys.length === 0) {
        notify('Необходимо выбрать сотрудника!', 'error', 2500);
      } else if (this.data.executors && this.data.executors.includes(this.data.selectedRowKeys[0])) {
        notify('Данный сотрудник уже присутствует в списке!', 'error', 2500);
      } else {
        this.dialogRef.close({
          id: this.selectedRowsData[0].id * -1,
          departmentId: this.selectedRowsData[0].parentId,
          name: `${this.selectedRowsData[0].name} (${this.selectedRowsData[0].depShortName || this.selectedRowsData[0].departmentName || this.selectedRowsData[0].depName})`,
          departmentName: this.selectedRowsData[0].departmentName,
          phone: this.selectedRowsData[0].phone,
          email: this.selectedRowsData[0].email,
          userName: this.selectedRowsData[0].name,
          position: this.selectedRowsData[0].position
        });
      }
    } else {
      if (this.data.canSelectDepartment || this.data.department) {
        this.dialogRef.close(this.selectedRowsData);
      } else {
        const departments = this.selectedRowsData.filter(x => x.id > 0).map(x => x.id);
        let executors = this.selectedRowsData.filter(x => x.id < 0).map(x => {
          return {id: x.id * -1, name: `${x.name} (${x.depShortName || x.departmentName || x.depName})`}
        });
        if (departments.length > 0) {
          this.subscriptions.push(
            this.service.getExecutorsOfDepartment(departments).subscribe(data => {
              data.forEach(x => {
                if (!executors.find(e => e.id === x.id)) executors.push({
                  id: x.id,
                  name: `${x.fullName} (${x.depShortName || x.departmentName || x.depName})`
                });
              });
              if (this.data.hideSelect)
                executors = executors.filter(x => !this.data.hideSelect.includes(x.id * -1));
              this.dialogRef.close(executors);
            })
          );
        } else this.dialogRef.close(executors);
      }
    }
  }

  onEditorPreparing(e: any) {
    if (e.parentType === 'searchPanel') {
      e.editorOptions.onValueChanged = function (arg) {
        if (arg.value.length == 0 || arg.value.length >= 5) {
          e.component.searchByText(arg.value);
        }
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onToolbarPreparingContragent(e) {

    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        disabled: true,
        hint: 'Выбрать',
        height: 36,
        width: 36,
        icon: 'check',
        onInitialized: (args: any) => {
          this.btnChoiceContragent = args.component;
        },
        onClick: this.onChoiceRowsContragent.bind(this)
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        visible: this.data.onlyContragent && this.data.showCurator,
        text: 'Вернуть куратору от СК',
        hint: 'Вернуть куратору от СК',
        onClick: this.returnCurator.bind(this)
      }
    });

  }

  onChoiceRowsContragent(e) {
    const data = {contragent: this.selectedRowsDataContragent[0], account: null};
    if (this.selectedAccount) {
      if (this.data.separateAccount) {
        data.contragent.accountName = this.selectedAccount.name;
      } else {
        data.contragent.name += ` (${this.selectedAccount.name})`;
        data.contragent.shortName += ` (${this.selectedAccount.name})`;
      }
      data.contragent.accountId = this.selectedAccount.id;

    }
    this.dialogRef.close(data);
  }

  onSelectionChangedContragent(e) {
    this.selectedRowsDataContragent = e.selectedRowsData;
    this.selectedAccount = null;
    this.btnChoiceContragent.option({
      disabled: !e.selectedRowsData.length
    });
  }

  returnCurator(e) {
    this.dialogRef.close({
      curator: {
        id: 'curator',
        name: 'Куратор от СК'
      }
    });
  }

  valueChangeTypeExecutor() {
    this.first = true;
  }

  getAccountsList(contragent): any {
    if (this.accountsDataMap[contragent.id]) {
      return this.accountsDataMap[contragent.id].dataSource;
    }
    const dataSourceAccounts = new DataSource({
      store: new CustomStore({
        key: 'id',
        load: (options) => {
          return this.commonService.getAccounts(contragent.id, options)
            .toPromise().then(data => {
              return {
                type: 'array',
                key: 'id',
                data: data.items,
                totalCount: data.count
              };
            }
          ).catch(error => {
            throw error.error;
          });
        },
      })
    });
    this.accountsDataMap[contragent.id] = {dataSource: dataSourceAccounts, selectedKeys: []};
    return dataSourceAccounts;
  }

  onRowClickAccount(e, contragent) {
    this.btnChoiceContragent.option({
      disabled: false
    });
    this.selectedRowsDataContragent = [contragent];
    this.selectedAccount = e.data;
  }

}
