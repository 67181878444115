import {AfterViewInit, Component, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {DxTreeListComponent} from 'devextreme-angular';
import {AdminAreaService} from '../../../../services/admin-area.service';
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";

export interface AdminArea {
  id: number
  shortName: string;
  name: string;
  inn: string;
  kpp: string;
}

@Component({
  selector: 'tree-lk-contragent',
  templateUrl: './tree-lk-contragent.component.html',
  styleUrls: ['./tree-lk-contragent.component.scss'],
  providers: [AdminAreaService],
  encapsulation: ViewEncapsulation.None
})
export class TreeLkContragentComponent implements OnInit, AfterViewInit {
  loading = false;
  selectedRowsData: any[] = [];
  adminAreas: AdminArea[];
  ids: number[];
  dataSource: any;
  totalCount = 0;
  @ViewChild('treeLkContragent', {static: false}) treeLkContragent: DxTreeListComponent;
  private btnChoice: any = null;

  constructor(public dialogRef: MatDialogRef<TreeLkContragentComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private adminAreaService: AdminAreaService) {
  }

  ngOnInit() {
    this.loading = true;
    this.dataSource = new DataSource({
      paginate: true,
      pageSize: 25,
      store: new CustomStore({
        key: 'id',
        load: options => {
          console.log(options);
          return this.adminAreaService.getContragentList(options.filter, options.take, options.skip).toPromise().then(result => {
              console.log(result);
              this.adminAreas = result.items;
              this.totalCount = result.count;
              this.ids = this.adminAreas.map(x => {
                return x.id;
              });
              this.loading = false;
              return {
                type: 'array',
                key: 'id',
                data: this.adminAreas,
                totalCount: result.count
              };
            }
          ).catch(error => {
            this.loading = false;
            throw error.error;
          });
        }
      })
    });

  }

  ngAfterViewInit(): void {
  }

  selectedRow(data: any) {
    this.selectedRowsData = this.treeLkContragent.instance.getSelectedRowsData('all');
    this.updateBtnStates();
    console.log(data);
  }
  closeDialog() {
    this.dialogRef.close();
  }
  onToolbarPreparing(e: any) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        hint: 'Выбрать',
        height: 36,
        width: 36,
        disabled: true,
        icon: 'check',
        onInitialized: (args: any) => {
          this.btnChoice = args.component;
        },
        onClick: this.onChoiceRows.bind(this)
      }
    });
  }

  onChoiceRows() {
    this.dialogRef.close(this.selectedRowsData);
  }

  private updateBtnStates() {
    if (this.btnChoice != null) {
      this.btnChoice.option({
        disabled: this.selectedRowsData.length === 0
      });
    }
  }

}
