import {Component, OnDestroy, OnInit} from '@angular/core';
import {ObjectService} from "../../../../../services/object.service";
import {DocumentService} from "../../../../../services/document.service";
import notify from "devextreme/ui/notify";

@Component({
  selector: 'app-monitoring-act',
  templateUrl: './monitoring-act.component.html',
  styleUrls: ['./monitoring-act.component.scss']
})
export class MonitoringActComponent implements OnInit, OnDestroy {

  loading = false;
  visiblePopup;
  filialDataSource = [];
  typesDataSource = [];
  statusDataSource = [];
  params = {
    periodStart: null,
    periodEnd: null,
    filialIds: [],
    typeIds: [],
    statusIds: []
  }

  constructor(public objectService: ObjectService,
              public documentService: DocumentService) {
    this.params.periodEnd = new Date();
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    this.params.periodStart = new Date(`${date.getMonth() + 1}.01.${date.getFullYear()}`);
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.params = this.params.filialIds = this.params.typeIds = this.params.statusIds =
      this.filialDataSource = this.typesDataSource = this.statusDataSource = null;
  }

  show() {
    if (this.visiblePopup === undefined) {
      this.loading = true;
      Promise.all([
        this.objectService.getDepartmentList(2).toPromise().then(data => {
          this.filialDataSource = data.items;
        }), this.documentService.getDocumentTypes('reportmonitoringact').toPromise().then(data => {
          this.typesDataSource = data.items;
          this.params.typeIds = data.items.map(x => x.id);
        }), this.documentService.getDocumentStatuses().toPromise().then(data => {
          this.statusDataSource = data;
        })
      ]).then(() => {
        this.loading = false;
        this.visiblePopup = true;
      })
      this.visiblePopup = true;
    } else this.visiblePopup = true;

  }

  getReport(e) {
    let message;
    if(!this.params.periodStart || !this.params.periodEnd) {
      message = 'Необходимо заполнить границы периода!'
    } else if(this.params.periodStart > this.params.periodEnd) {
      message = 'Начало периода не может быть больше окончания!'
    }
    if (message) {
      notify({message, width: 800}, 'success', 1500);
      return;
    }
    this.loading = true;
    this.documentService.getMonitoringAct(this.params).toPromise().then(data => {
      if (data.tmpId)
        window.open(`api/file/download?id=${data.tmpId}&excelService=true&name=Мониторинг_актов.xlsx`);
    }).finally(() => {
      this.loading = false;
    });
  }

}
