import {AfterViewInit, Component, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {DiagramAgreementService} from '../diagram-agreement.service';
import {DxTreeListComponent} from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

export interface orgUnit {
  id: number;
  name: string;
  idParent?: number;
}

@Component({
  selector: 'app-tree-org-unit',
  templateUrl: './tree-org-unit.component.html',
  styleUrls: ['./tree-org-unit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TreeOrgUnitComponent implements OnInit, AfterViewInit {

  dataSource: any;
  expandedRowKeys = [1];
  selectedRowsData: any[] = [];
  selectedRowKeys = [];
  ids: number[];
  @ViewChild('treeListOrgUnit', {static: false}) treeListOrgUnit: DxTreeListComponent;
  store: CustomStore;
  private btnChoice: any = null;

  constructor(public dialogRef: MatDialogRef<TreeOrgUnitComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private servivce: DiagramAgreementService) {
  }

  ngOnInit() {
    this.store = new CustomStore({
      key: 'id',
      load: (options) => {
        let filter = null;
        if (!options.parentIds) {
          filter = options.filter;
        }
        if (options.sort == null) {
          options.sort = [];
          options.sort.push({
            selector: 'name',
            desc: false
          });
        }
        return this.servivce.getOrgUnits(options.parentIds, filter, options.sort, true, this.data && this.data.filial).toPromise().then(data => {
          return data;
        });
      }
    });

    this.dataSource = new DataSource({
      store: this.store
    });
  }

  ngAfterViewInit(): void {
  }

  selectedRow(data: any) {
    this.selectedRowsData = this.treeListOrgUnit.instance.getSelectedRowsData('all');
    this.updateBtnStates();
    console.log(data);
  }

  onToolbarPreparing(e: any) {

    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        hint: 'Выбрать',
        height: 36,
        width: 36,
        disabled: true,
        icon: 'check',
        onInitialized: (args: any) => {
          this.btnChoice = args.component;
        },
        onClick: this.onChoiceRows.bind(this)
      }
    });
  }

  onChoiceRows() {
    this.dialogRef.close(this.selectedRowsData);
  }

  private updateBtnStates() {
    if (this.btnChoice != null) {
      this.btnChoice.option({
        disabled: this.selectedRowsData.length === 0
      });
    }
  }
}
