import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {gridDictionary} from "../../list-generator/generator-interfaces";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import {GeneratorService} from "../../list-generator/generator.service";
import {ObjectService} from "../../../../services/object.service";


@Component({
  selector: 'app-adder',
  templateUrl: './adder.component.html',
  styleUrls: ['./adder.component.scss']
})
export class AdderComponent implements OnInit {
  heightGrid: number;
  invNumPopupVisible: boolean = false;
  selectedIdbranchRes;
  selectedIdinvNum;
  fakeTypeSubId;
  constructor(public dialogRef: MatDialogRef<AdderComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private service: GeneratorService,
              private objectService: ObjectService) {
    this.fakeTypeSubId = this.data.fakeTypeSubId
  }

  dataSource;
  dataSourceGrid: any;
  invNumDataSource;
  branchResDataSource;
  treeData = false;
  selectedKeys = [];
  selectedData = [];
  btnAdd;

  ngOnInit() {
    this.treeData = !!this.data.treeData;
    this.selectedKeys = this.data.selectedRowKeys ? this.data.selectedRowKeys.map(x => x) : [];
    if (this.treeData) {
      this.dataSource = new DataSource({
        pageSize: 15,
        store: new CustomStore({
          key: 'id',
          load: (options) => {
            return this.service.getList(this.data.dictionary.get, options.take, options.skip, options.filter, options.sort, options.group).toPromise()
              .then(result => {
                return {
                  type: 'array',
                  key: 'id',
                  data: result,
                };
              }).catch(error => {
                throw error.error;
              });
          }
        })
      });
    } else {
      this.refreshDataGrid()
    }

    this.invNumDataSource = new DataSource({
      paginate: true,
      pageSize: 15,
      store: new CustomStore({
        key: 'id',
        load: (options) => {
          return this.objectService.getAssetsList(options.take, options.skip, options.filter, options.sort, true, this.data.stouCode)
            .toPromise().then(d => {
              return {
                type: 'array',
                key: 'id',
                data: d.items,
                totalCount: d.count
              };
            });
        }
      }),
    });
    this.branchResDataSource = new DataSource({
      paginate: true,
      pageSize: 25,
      store: new CustomStore({
        key: 'id',
        load: (options) => {
          return this.objectService.getDepartmentList(3, options.take, options.skip, options.filter, options.sort, true)
            .toPromise().then(d => {
              return {
                type: 'array',
                key: 'id',
                data: d.items,
                totalCount: d.count
              };
            });
        }
      }),
    });
  }

  choiceClick(e) {
    this.dialogRef.close(e);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onRowPopupClickInvNum(e) {
    this.selectedIdinvNum = e.data.id;
    if (this.selectedIdinvNum && this.selectedIdbranchRes) {
      this.objectService.addInvRes({
        mainAssetId: this.selectedIdinvNum,
        branchResId: this.selectedIdbranchRes,
        fakeTypeSubId: this.fakeTypeSubId
      }).subscribe(data => this.refreshDataGrid());
      this.invNumPopupVisible = false;
      this.selectedIdinvNum = null;
      this.selectedIdbranchRes = null;
    }
  }

  onRowPopupClickBranchRes(e) {
    this.selectedIdbranchRes = e.data.id;
    if (this.selectedIdinvNum && this.selectedIdbranchRes) {
      this.objectService.addInvRes({
        mainAssetId: this.selectedIdinvNum,
        branchResId: this.selectedIdbranchRes,
        fakeTypeSubId: this.fakeTypeSubId
      }).subscribe(data => this.refreshDataGrid());
      this.invNumPopupVisible = false;
      this.selectedIdinvNum = null;
      this.selectedIdbranchRes = null;
    }
  }

  onToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      locateInMenu: 'auto',
      showText: 'inMenu',
      options: {
        icon: 'refresh',
        onClick: this.refreshData.bind(this),
        hint: 'Обновить',
        text: 'Обновить'
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      locateInMenu: 'auto',
      showText: 'inMenu',
      options: {
        disabled: false,
        icon: 'plus',
        onClick: this.addRows.bind(this),
        hint: 'Добавить',
        text: 'Добавить',
        onInitialized: (args: any) => {
          this.btnAdd = args.component;
        }
      }
    })
  }

  refreshData() {
    this.dataSource.reload();
  }
  refreshDataGrid() {
    this.dataSourceGrid =  new DataSource({
      paginate: true,
      pageSize: 25,
      store: new CustomStore({
        key: 'id',
        load: (options) => {
          return this.objectService.getInvResList(options.take, options.skip, options.filter, options.sort,this.fakeTypeSubId)
            .toPromise().then(d => {
              return {
                type: 'array',
                key: 'id',
                data: d.items,
                totalCount: d.count
              };
            });
        }
      }),
    });;
  }

  addRows(e) {
    this.invNumPopupVisible = true
    //this.dialogRef.close(this.selectedData);
  }

  onSelectionChanged(e) {
    /*if (this.btnAdd) {
      this.btnAdd.option({
        disabled: false
      });
    }*/
    this.selectedData = e.selectedRowsData;
  }
}
