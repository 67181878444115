import {BrowserModule} from '@angular/platform-browser';
import {NgModule, ErrorHandler} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthGuard} from './guards/auth.guard';
import {AuthenticationService} from './services/authentication.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatButtonModule, MatMenuModule, MatToolbarModule} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TokenInterceptor} from './interceptor/token.interceptor';
import {DevExtremeModule} from 'devextreme-angular';
import {CommonElementModule} from './modules/common-element/common-element.module';
import {AngularFireModule} from '@angular/fire';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {environment} from '../environments/environment';
import {MatCardModule} from '@angular/material/card';
import {GlobalErrorHandler} from './errors/global-error-handler';
import {DEFAULT_TIMEOUT, TimeoutInterceptor} from './interceptor/timeout.interceptor';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    FontAwesomeModule,
    DevExtremeModule,
    CommonElementModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    MatCardModule
  ],
  providers: [AuthGuard, AuthenticationService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: TimeoutInterceptor,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: DEFAULT_TIMEOUT, useValue: 30 * 60 * 1000 },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
