import { Injectable } from '@angular/core';
import {ConfirmComponent} from "./confirm.component";
import {MatDialog} from "@angular/material";

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  constructor(public dialog: MatDialog) { }

  public confirm(title: string, message: string, cancel: boolean = false, field: string = null, array: any[] = null): any {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: {title: title, message: message, cancel: cancel, field: field, array: array},
      disableClose: true
    });
    return dialogRef.afterClosed();
  }
}
