import {AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ReferenceInformationService} from '../../../services/reference-information.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {CommonChoiceComponent} from '../../reference-information/components/common-choice/common-choice.component';
import {faPen} from '@fortawesome/free-solid-svg-icons';
import {DxFormComponent} from 'devextreme-angular';
import {DocumentService} from '../../../services/document.service';
import {DocInfo} from '../../../models/document.model';
import {DocumentComponent} from './document.component';
import {AuthenticationService} from '../../../services/authentication.service';
import {TreeObjectHierarchyComponent} from '../../common-element/tree-object-hierarchy/tree-object-hierarchy.component';
import {ListGeneratorComponent} from '../../common-element/list-generator/list-generator.component';
import {Dictionaries} from '../dictionaries';
import notify from 'devextreme/ui/notify';
import {formatDate} from '@angular/common';
import {ContractService} from '../../../services/contract.service';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.scss'],
  providers: [DocumentService, ContractService]
})

export class AddDocumentComponent implements OnInit, AfterViewInit {
  @Input() form: string;

  doc: DocInfo;
  objectId: number;
  code: string;
  name: string;
  fakeId = -1;
  enabledSubmit = false;
  loading = true;
  formVisible = true;

  heightGrid: number;
  faPen = faPen;
  indicatorVisible = false;
  dictionaryCheckTable: any;
  checkTableData: any;
  @ViewChild('checkTable', {static: false}) checkTable: ListGeneratorComponent;
  @ViewChild('formComponent0', {static: false}) formComponent0: DxFormComponent;
  @ViewChild('formComponent1', {static: false}) formComponent1: DxFormComponent;
  @ViewChild('formComponent2', {static: false}) formComponent2: DxFormComponent;
  level = 0;
  labelTypeLink = 'Объект/пообъект';
  typeLink = 'object';

  constructor(private router: Router, private documentService: DocumentService,
              public authService: AuthenticationService,
              public dialogRef: MatDialogRef<AddDocumentComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private refService: ReferenceInformationService,
              public dialog: MatDialog,
              private ref: ChangeDetectorRef) {
    switch (this.data.typeLink) {
      case 'title': this.typeLink = this.data.typeLink; break;
      default: this.typeLink = 'object'; break;
    }
  }

  doNewDocument() {
    if (this.data && this.data.ssr && this.doc && this.doc.type && this.doc.type.id === -13) {
      this.doc.parent = null;
    }
    if (this.doc && this.doc.type.isCommon) {
      this.doc.department.id = null;
      this.doc.department.name = null;
    }
    const dr = this.dialog.open(DocumentComponent, {
      minWidth: '30%',
      maxWidth: '85%',
      maxHeight: '90vh',
      data: {doc: this.doc},
      disableClose: true
    });
    dr.afterClosed().subscribe(result => {
      this.dialogRef.close({result});
    });
    this.formVisible = false;
  }

  doEditDocumentType() {
    const dialogRef = this.dialog.open(CommonChoiceComponent, {
      width: '50%',
      maxHeight: '100vh - 100px',
      data: {
        focusedRowKey: this.doc.type ? this.doc.type.id : undefined,
        selected: this.data && this.data.hideTypeId && !this.isEmpty(this.data.hideTypeId) ? this.data.hideTypeId : [],
        dictionary: 'choiceOnClickTypeDocs',
        typeLink: this.typeLink,
        objectId: (this.data.objectId && [this.data.objectId]),
        form: (this.form && !this.isEmpty(this.form)) ? this.form :
          ((this.data && this.data.form && !this.isEmpty(this.data.form)) ? this.data.form : null)
      },
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (!this.doc.type) {
          this.doc.type = {
            id: result.id,
            name: result.name,
            fullName: null,
            isApprovalRequired: result.isApprovalRequired,
            isVisible: result.isVisible,
            isAutoNumbered: result.isAutoNumbered,
            numberFromFile: result.numberFromFile,
            section: null,
            isCommon: result.isCommon,
            isContract: result.isContract,
            groupId: result.groupId,
            linkIds: result.linkIds,
            url: result.url,
            skipObject: result.skipObject,
            filial: result.filial,
            showLinks: result.isCommon && result.showLinks || false,
            journalLink: result.journalLink,
            allowAttach: result.allowAttach
          };
        } else {
          this.doc.type.id = result.id;
          this.doc.type.groupId = result.groupId;
          this.doc.type.name = result.name;
          this.doc.type.isAutoNumbered = result.isAutoNumbered;
          this.doc.type.numberFromFile = result.numberFromFile;
          this.doc.type.isCommon = result.isCommon;
          this.doc.type.isContract = result.isContract;
          this.doc.type.isApprovalRequired = result.isApprovalRequired;
          this.doc.type.isVisible = result.isVisible;
          this.doc.type.linkIds = result.linkIds;
          this.doc.type.url = result.url;
          this.doc.type.skipObject = result.skipObject;
          this.doc.type.filial = result.filial;
          this.doc.type.journalLink = result.journalLink;
          this.doc.type.allowAttach = result.allowAttach;
          if (result.isCommon) {
            this.doc.department.id = null;
            this.doc.department.name = null;
            this.doc.type.showLinks = result.showLinks;
          }
        }

        this.labelTypeLink = this.typeLink === 'object' && 'Объект/подобъект' || 'ИП/Элемент ИП';

        // Ищем договор для даного объекта
        if (this.typeLink === 'object' && this.doc.type.isContract && this.data && this.data.objectId)
          { this.getDefaultContract(this.data.objectId); }

        this.doc.type.typeLink = this.typeLink;
        if (!this.doc.type.linkIds || !this.doc.type.linkIds.length || [-2, -49].find(x => this.doc.type.linkIds.includes(x))) {
          this.doc.objectsName = null;
          this.doc.objects = [];
        }

        this.doc.name = result.name;
        await this.documentService.getFields(this.doc.type.id, this.doc.contract.id, null, this.doc.department.id).toPromise().then(data => {
          this.doc.fields = data;
        });
        if (this.doc.type.skipObject) {
          this.doNewDocument();
          return;
        }
        this.isEnabledSubmit();
        if (this.enabledSubmit) {
          this.nextStep();
        }
        this.ref.detectChanges();
      }
    });
  }

  async getDefaultContract(id) {
    this.loading = true;
    try {
      const data = await this.documentService.getContractDefault(id);
      if (data && data.id) {
         this.doc.contract = data;
        this.isEnabledSubmit();
      }
      this.loading = false;
    } catch (e) {
      this.loading = false;
    }

  }

  addContract() {
    const data = {
      selected: this.doc.contract ? [this.doc.contract.id] : [],
      dictionary: 'choiceOnClickContract'
    }
    if (this.authService.USER.contragentId)
      data['queryParam'] = [['subcontractorId', this.authService.USER.contragentId]]
    if (this.data && this.data.objectId)
      data['queryParam'] = (data['queryParam'] || []).concat([['needObject', true], ['simple', true], ['objectId', this.data.objectId]])

    const dialogRef = this.dialog.open(CommonChoiceComponent, {
      width: '70%',
      maxHeight: '100vh - 100px',
      data,
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (!this.doc.contract) {
          this.doc.contract = {
            id: result.id,
            name: result.numContract + ' от ' + formatDate(result.dateApprove, 'dd.MM.yyyy', 'en_US')
          };
        } else {
          this.doc.contract.id = result.id;
          this.doc.contract.name = result.numContract + ' от ' + formatDate(result.dateApprove, 'dd.MM.yyyy', 'en_US');
        }
        this.doc.objectsName = '';
        this.doc.objects = [];
      }
      this.isEnabledSubmit();
    });
  }

  addObjects() {
    const dialogRef = this.dialog.open(TreeObjectHierarchyComponent, {
      width: '80wh',
      height: '90vh',
      data: {
        typeLink: (this.authService.lkk || !this.typeLink) && 'object' || this.typeLink,
        selectedRowKeys: this.doc.objects ? this.doc.objects.map(x => x.id) : [],
        mode: this.doc.type.isCommon ? 'multiple' : 'single',
        objectId: this.data && this.data.objectId && this.data.objectId || undefined,
        linkIds: this.doc.type.linkIds,
        contractId: this.doc.contract ? this.doc.contract.id : undefined,
        typeId: this.doc.type.id
      },
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const docObjects = Object.create(this.doc.objects);
        this.doc.objects = [];
        for (const r of result) {
          this.doc.objects.push({
            id: r.id,
            code: r.code,
            name: r.name,
            statusRow: !docObjects.map(x => x.id).includes(r.id) && r.id > 0 ? 'i' : 's',
            isObject: r.parentId == null,
            objectId: r.parentId || r.id
          });
        }
      }
      if (this.doc.objects) {
        this.doc.objectsName = this.doc.objects.map(x => (x.code ? x.code : '') + ' ' + (x.name ? x.name : '')).join('; ');
      }
      this.isEnabledSubmit();
      if (this.enabledSubmit) {
        this.nextStep();
      }
    });
  }

  ngOnInit() {
    this.heightGrid = window.innerHeight - 420;
    const dateNow = new Date();
    this.doc = {
      id: this.fakeId--,
      docsvisionId: null,
      number: null,
      isNumberFromFile: false,
      isInPackage: false,
      order_number: null,
      documentDate: new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 0, 0, 0, 0),
      version: '1.0',
      name: null,
      description: null,
      isActive: false,
      approvalDate: null,
      type: {
        id: null,
        name: null,
        fullName: null,
        isApprovalRequired: false,
        isVisible: true,
        isAutoNumbered: false,
        numberFromFile: false,
        section: null,
        isCommon: false,
        isContract: false,
        groupId: null,
        linkIds: [],
        url: null,
        journalLink: null,
        allowAttach: false
      },
      status: {
        id: 1,
        name: 'Создан'
      },
      parent: this.data !== null && this.data.parentId && this.data.parentId !== null && this.data.parentId > 0 && this.data.parentName ? {
        id: this.data.parentId,
        name: this.data.parentName
      } : null,
      department: {
        id: this.authService.USER.official ?
          (this.authService.USER.official.department ? this.authService.USER.official.department.id : null) : null,
        name: this.authService.USER.official ?
          (this.authService.USER.official.department ? this.authService.USER.official.department.name : null) : null
      },
      official: this.authService.USER.official,
      documentType: null,
      documentYear: dateNow.toString(),
      year: dateNow.getFullYear(),
      fields: [],
      evalFields: [],
      versions: [],
      objects: this.data !== null && this.data.objectId > 0 && this.data.objectName ? [{
        id: this.data.objectId,
        code: '',
        name: this.data.objectName,
        statusRow: 'i',
        isObject: true
      }] : [],
      objectsName: this.data !== null && this.data.objectId > 0 && this.data.objectName ? this.data.objectName : null,
      fileName: null,
      files: [],
      attachments: [],
      contract: {
        id: null,
        name: null
      },
      contragent: {
        id: this.authService.USER.contragentId ?
          (this.authService.USER.contragentId ? this.authService.USER.contragentId : null) : null,
        shortName: this.authService.USER.contragentId ?
          (this.authService.USER.contragentId ? this.authService.USER.contragent.shortName : null) : null,
      }
    };
    this.doc.versions.push({
      id: this.fakeId--,
      version: '1.0',
      branchVersion: 1,
      subversion: 0,
      parentId: this.data !== null && this.data.parentId > 0 ? this.data.parentId : null,
      status: {
        id: 1,
        name: 'Создан'
      },
      isActive: false,
      createdAt: new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 0, 0, 0, 0),
      approvalDate: null
    });
    this.loading = false;
  }

  ngAfterViewInit(): void {
  }

  onAddDocumentFormSubmit() {
    if (this.formComponent0) {
      const res = this.formComponent0.instance.validate();
      if (!res.isValid) {
        return;
      }
    }

    if (this.formComponent1) {
      const res = this.formComponent1.instance.validate();
      if (!res.isValid) {
        return;
      }
    }

    if (this.formComponent2) {
      const res = this.formComponent2.instance.validate();
      if (!res.isValid) {
        return;
      }
    }

    if (this.checkTable) {
      if (this.checkTable.selectedRowKeys.length === 1) {
        this.nextStep(this.checkTable.selectedRowKeys[0]);
      } else {
        notify('Необходимо выбрать один документ!', 'error', 1500);
      }
    } else {
      this.nextStep();
    }
  }

  onValueChanged(e = null) {
    if (this.loading) {
      return;
    } else if (e && e.value === undefined) {
      return;
    }

    if (e.dataField === 'documentYear') {
      this.doc.year = new Date(e.value).getFullYear();
    }

    console.log('Changed');
    this.isEnabledSubmit();
    this.ref.detectChanges();
  }

  isEnabledSubmit(): void {
    if (this.doc) {
      this.enabledSubmit = !this.isEmpty(this.doc.type.id)
        && !this.isEmpty(this.doc.type.name)
        && !this.isEmpty(this.doc.year)
        && (!this.isEmpty(this.doc.objectsName) || this.level !== 1 || this.doc.type.isCommon)
        && (!this.isEmpty(this.doc.contract.name) || this.level !== 1 || !this.doc.type.isContract);
    }
  }

  isEmpty(str) {
    return (!str || 0 === str.length);
  }

  nextStep(id = 0) {
    if (!this.enabledSubmit) {
      return;
    }
    this.indicatorVisible = false;
    this.enabledSubmit = false;
    if (this.level === 0) {
      this.level = 1;
      this.isEnabledSubmit();
    } else {
      if (this.level === 1) {
        this.documentService.check(this.doc.type.id,
          this.doc.year.toString().length > 8 ? new Date(this.doc.year).getFullYear() : this.doc.year,
          this.doc.objects.map(x => x.id)).subscribe(data => {
          if (data.length === 0) {
            if (this.doc.year.toString().length > 8) {
              this.doc.year = new Date(this.doc.year).getFullYear();
            }
            if (this.data && this.data.ssr && this.doc && this.doc.type && this.doc.type.id === -13) {
              this.doc.parent = null;
            }
            const dialogRef = this.dialog.open(DocumentComponent, {
              minWidth: '30%',
              maxWidth: '85%',
              maxHeight: '90vh',
              data: {doc: this.doc},
              disableClose: true
            });
            dialogRef.afterClosed().subscribe(result => {
              this.dialogRef.close({result});
            });
            this.formVisible = false;
          } else {
            this.level = 2;
            this.dictionaryCheckTable = Dictionaries.dictionaries.find(x => x.id === 'check-table');
            this.checkTableData = data;
            this.isEnabledSubmit();
          }
        });
      } else {
        if (id > 0) {
          this.documentService.version(id, this.doc.objects.map(o => o.id)).subscribe(data => {
            if (this.doc.year.toString().length > 8) {
              this.doc.year = new Date(this.doc.year).getFullYear();
            }
            data.status.name = '';
            const v = data.versions.find(x => x.version === data.version);
            if (v.status && v.status.name) {
              v.status.name = '';
            }
            console.log(data);
            if (this.data && this.data.ssr && this.doc && this.doc.type && this.doc.type.id === -13) {
              this.doc.parent = null;
            }
            const dialogRef = this.dialog.open(DocumentComponent, {
              minWidth: '30%',
              maxWidth: '85%',
              maxHeight: '90vh',
              data: {doc: data},
              disableClose: true
            });
            dialogRef.afterClosed().subscribe(result => {
              this.dialogRef.close({result});
            });
            this.formVisible = false;
          });
        }
      }
    }
  }

  closeDialog(x = true) {
    if (this.level > 0 && !x) {
      this.level = this.level - 1;
      if (this.level === 0) {
        this.doc.objects = this.data !== null && this.data.objectId && this.data.objectId !== null
        && this.data.objectId > 0 && this.data.objectName ? [{
          id: this.data.objectId,
          code: '',
          name: this.data.objectName,
          statusRow: 'i',
          isObject: true
        }] : [];
        this.doc.objectsName = this.data !== null && this.data.objectId && this.data.objectId !== null
        && this.data.objectId > 0 && this.data.objectName ? this.data.objectName : null;
      }
      this.enabledSubmit = true;
    } else {
      this.dialogRef.close();
    }
  }
}
