import {AfterViewInit, Component, OnInit} from '@angular/core';

import ruMessages from 'devextreme/localization/messages/ru.json';
import {
  faHourglassHalf
} from '@fortawesome/free-solid-svg-icons';

import {locale, loadMessages} from 'devextreme/localization';
import {AuthenticationService} from './services/authentication.service';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {Title} from "@angular/platform-browser";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  message;
  faHourglassHalf = faHourglassHalf;

  constructor(public service: AuthenticationService,
              private afMessaging: AngularFireMessaging,private titleService: Title) {
    if (environment.name=='infoenergo'){
      let favIcon16: HTMLLinkElement = document.querySelector('.appIcon16');
      let appManifest: HTMLLinkElement = document.querySelector('.appManifest');
      let favIconSvg = document.querySelectorAll('.appIconSvg');
      favIcon16.href = './assets/ico/aispro16.png';
      favIconSvg.forEach(e=>e.setAttribute('href', './assets/ico/aispro.svg'));
      appManifest.href = './manifest-infoenergo.json';
    } else {
      // this.favIcon16.href = './assets/ico/aisks.svg';
      // this.favIconSvg.forEach(e=>e.setAttribute('href', './assets/ico/aisks.svg'));

    }

    loadMessages(ruMessages);
    loadMessages({
      ru: {
        'dxDataGrid-summarySum': 'Сумма: {0}'
      }
    });
    locale(navigator.language);
    this.afMessaging.messages
      .subscribe((message: any) => {
        const notification = new Notification(message.data.title, {body: message.data.body, data: message.data.click_action});
        notification.onclick = (event) => {
          window.open(notification.data);
        };
      });
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.service.nextUrl = window.location.href.split('#')[1] || '/home';
    this.service.genFingerPrint();
  }
}
