import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DxTreeListComponent} from 'devextreme-angular';
import {orgUnit} from '../../diagram-agreement/tree-org-unit/tree-org-unit.component';
import {DiagramAgreementService} from '../../diagram-agreement/diagram-agreement.service';
import {ListGeneratorComponent} from '../list-generator/list-generator.component';
import {gridDictionary} from '../list-generator/generator-interfaces';
import {SubscriptionLike} from 'rxjs';
import {isBoolean} from 'util';

@Component({
  selector: 'app-user-org-list',
  templateUrl: './user-org-list.component.html',
  styleUrls: ['./user-org-list.component.scss']
})

export class UserOrgListComponent implements OnInit, AfterViewInit, OnDestroy {
  heightGrid: number;
  orgUnits: orgUnit[];
  @ViewChild('treeListOrgUnit', {static: false}) treeListOrgUnit: DxTreeListComponent;
  @ViewChild('gridUserOrgStructure', {static: false}) tableUserOrg: ListGeneratorComponent;
  dictionary: gridDictionary = {
    id: 'user-org-list',
    name: 'Официальные лица',
    get: 'api/organization/department/user/list',
    primaryKey: 'id',
    countPage: 15,
    remoteOperations: true,
    hideExport: true,
    wordWrap: true,
    hideRowStatus: true,
    allowChoice: true,
    hideChooser: true,
    selectedMode: 'single',
    hideSelectedColumn: false,
    showCountRow: true,
    allowRefresh: false,
    hideSearchPanel: true,
    hideFilterPanel: true,
    hideFilterHead: true,
    hideSearchFilterHead: true,
    defaultFilterValue: {
      filterValue: [{name: 'id', value: '1'}],
      getFilterValue: (data, filter) => {
        if (!filter) {
          return;
        }
        const values = [];
        if (typeof filter[0] !== 'string') {
          for (const el of filter) {
            if (el.filterValue) {
              values.push({name: el[0], value: el.filterValue.toString()});
            }
          }
        } else {
          values.push({name: filter[0], value: filter.filterValue.toString()});
        }
        return values;
      }
    },
    columns: [
      {
        caption: 'ФИО',
        dataField: 'fullName',
        dataType: 'string',
        width: 200,
        sort: 'asc'
      },
      {
        caption: 'Подразделение',
        dataField: 'department',
        dataType: 'string',
        width: 200
      },
      {
        caption: 'Логин',
        dataField: 'login',
        dataType: 'string',
        minWidth: 100
      },
      {
        caption: 'Должность',
        dataField: 'position',
        dataType: 'string',
        minWidth: 100
      }
    ]
  };
  filterValue: Array<{ name: string, value: string }>;
  ids: number[];
  selectedRowKeys = [];
  subscriptions: SubscriptionLike[] = [];
  min = 300;

  constructor(
    public dialogRef: MatDialogRef<UserOrgListComponent>,
    public dialog: MatDialog,
    private service: DiagramAgreementService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.heightGrid = Math.max(window.innerHeight - 460, this.min);
    if (isBoolean(this.data.multi)) {
      this.dictionary.selectedMode = this.data.multi ? 'multiple' : 'single';
    }
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.service.getOrgUnits().subscribe(orgs => {
        this.orgUnits = orgs;
        this.ids = this.orgUnits.map(x => x.id);
        this.selectedRowKeys.push(this.ids[0]);
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onRowClick(e) {
    this.tableUserOrg.dictionary.defaultFilterValue.filterValue = [{name: 'id', value: e.data.id.toString()}];
    this.tableUserOrg.refreshData();
  }

  onChooseClick(e) {
    if (isBoolean(this.data.multi) && this.data.multi) {
      this.dialogRef.close(e);
    } else {
      this.dialogRef.close({user: e[0]});
    }
  }
}
