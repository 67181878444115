export const environment = {
  production: false,
  name: 'infoenergo',
  firebase: {
    apiKey: 'AIzaSyBjDe0kVB9UFFLSeu3cu25oBdnJl-dtSX8',
    authDomain: 'aisks-44fb6.firebaseapp.com',
    databaseURL: 'https://aisks-44fb6.firebaseio.com',
    projectId: 'aisks-44fb6',
    storageBucket: 'aisks-44fb6.appspot.com',
    messagingSenderId: '848203131550',
    appId: '1:848203131550:web:dce709e969859bc112698f'
  }
};
