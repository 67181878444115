import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {gridDictionary} from '../list-generator/generator-interfaces';
import {ChooseExecutorComponent} from '../choose-executor/choose-executor.component';
import {MatDialog} from '@angular/material';
import {DxDataGridComponent} from 'devextreme-angular';
import {ListGeneratorComponent} from '../list-generator/list-generator.component';
import notify from 'devextreme/ui/notify';
import {SubscriptionLike} from 'rxjs';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'app-delegation',
  templateUrl: './delegation.component.html',
  styleUrls: ['./delegation.component.scss']
})
export class DelegationComponent implements OnInit, OnDestroy {

  @Input() admin = false;
  @Input() heightGrid;
  @Input() widthGrid;
  @Output() changedData = new EventEmitter<any>();
  _target;
  _source;
  fakeId = -1;
  subscriptions: SubscriptionLike[] = [];

  validationRule = {
    type: 'custom',
    message: 'Дата начала периода должна быть меньше или равной дате окончания!',
    validationCallback: (e) => {
      if (e.data.startPeriod && e.data.endPeriod) {
        const start = new Date(e.data.startPeriod).setHours(0);
        const end = new Date(e.data.endPeriod).setHours(12);
        return start <= end;
      } else {
        return true;
      }
    }
  };

  dictionary: gridDictionary = {
    id: 'delegation',
    primaryKey: 'id',
    name: 'Делегирование',
    remoteOperations: true,
    handleCellChanging: true,
    allowDelete: true,
    allowRefresh: true,
    allowUpdate: true,
    allowAdd: true,
    addRowCustom: true,
    editMode: 'batch',
    editRole: 'delegation',
    countPage: 15,
    wordWrap: true,
    fastDelete: true,
    hideFilterPanel: true,
    hideRowStatus: true,
    //  hideChooser: true,
    // fields: ['sourceId', 'targetId',  'startPeriod', 'endPeriod'],
    columns: [{
      dataField: 'sourceId',
      caption: 'sourceId',
      dataType: 'number',
      visible: false,
    }, {
      dataField: 'targetId',
      caption: 'targetId',
      dataType: 'number',
      visible: false
    }, {
      dataField: 'sdn',
      caption: 'sdn',
      dataType: 'string',
      visible: false
    }, {
      dataField: 'tdn',
      caption: 'sdn',
      dataType: 'string',
      visible: false
    }, {
      dataField: 'sfn',
      caption: 'Кто делегирует полномочия',
      dataType: 'string',
      visible: false,
      width: undefined,
      cellTemplate: 'columnDelegationInfo',
      editCellTemplate: 'columnDelegationButtons'
    }, {
      dataField: 'tfn',
      caption: 'Кому делегируются полномочия',
      dataType: 'string',
      width: undefined,
      validationRule: [{
        type: 'required'
      }],
      cellTemplate: 'columnDelegationInfo',
      editCellTemplate: 'columnDelegationButtons'
    }, {
      dataField: 'startPeriod',
      caption: 'Начало',
      dataType: 'date',
      width: 100,
      validationRule: [this.validationRule]
    }, {
      dataField: 'endPeriod',
      caption: 'Окончание',
      dataType: 'date',
      width: 100,
      validationRule: [this.validationRule]
    }]
  };

  @ViewChild('delegationList', {static: false}) delegationList: ListGeneratorComponent;

  constructor(public dialog: MatDialog, private userService: AuthenticationService) {
  }

  ngOnInit() {
    if (this.admin) {
      this.dictionary.columns.find(x => x.dataField === 'sfn').visible = true;
      this.dictionary.get = 'api/admin/delegation';
      this.dictionary.post = 'api/admin/delegation';
      this.dictionary.put = 'api/admin/delegation';
      this.dictionary.delete = 'api/admin/delegation';
      this.dictionary.fieldDelete = 'sfn';
      this.dictionary.showCountRow = true;
    } else {
      this.dictionary.get = 'api/account/delegation';
      this.dictionary.post = 'api/account/delegation';
      this.dictionary.put = 'api/account/delegation';
      this.dictionary.delete = 'api/account/delegation';
      this.dictionary.fieldDelete = 'tfn';
      this.dictionary.hideBatchButton = true;
    }
  }

  choosePerson(field, newRow = true, dataRow?) {

    let hideExecutor = [];
    if (this.admin) {
      if (field === 'target' && dataRow && dataRow.data.sourceId) {
        hideExecutor.push(dataRow.data.sourceId * -1);
      } else if (field === 'source' && dataRow && dataRow.data.targetId) {
        hideExecutor.push(dataRow.data.targetId * -1);
      }
    } else {
      hideExecutor.push(this.userService.USER.officialId * -1);
    }
    console.log(field, newRow, dataRow)
    const dialogRef = this.dialog.open(
      ChooseExecutorComponent, {
        maxWidth: '40%',
        maxHeight: '95vh',
        data: {
          showAgreementInfo: false,
          selectedRowKeys: [],
          executors: hideExecutor,
          singleSelect: true,
          hideSelect: hideExecutor,
          showTerminatedCheck: this.admin
        }
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (newRow) {
          if (field === 'target') {
            this._target = result;
          } else {
            this._source = result;
          }
          this.delegationList.dataGrid.instance.addRow();
        } else if (dataRow) {
          if (field === 'target') {
            this.delegationList.dataGrid.instance.cellValue(dataRow.rowIndex, 'targetId', result.id);
            this.delegationList.dataGrid.instance.cellValue(dataRow.rowIndex, 'tfn', result.userName);
            this.delegationList.dataGrid.instance.cellValue(dataRow.rowIndex, 'tdn', result.departmentName);
          } else {
            this.delegationList.dataGrid.instance.cellValue(dataRow.rowIndex, 'sourceId', result.id);
            this.delegationList.dataGrid.instance.cellValue(dataRow.rowIndex, 'sfn', result.userName);
            this.delegationList.dataGrid.instance.cellValue(dataRow.rowIndex, 'sdn', result.departmentName);
          }
        }
      }
    });
  }

  addClick(e) {
    console.log(e);
    this.choosePerson(this.admin ? 'source' : 'target');
  }

  modifiedData(e) {
    console.log(e);
    if (this._target) {
      e.targetId = this._target.id;
      e.tfn = this._target.userName;
      e.tdn = this._target.departmentName;
      this._target = null;
    } else if (this._source) {
      e.sourceId = this._source.id;
      e.sfn = this._source.userName;
      e.sdn = this._source.departmentName;
      this._source = null;
    }
    this.changedData.emit();
  }

  saveData() {
    this.delegationList.dataGrid.instance.saveEditData();
  }

  checkValidation() {
    return this.delegationList.checkValidationRules();
  }

  buttonIconClick(e) {
    if (e) {
      this.choosePerson(e.id, false, e);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

}
