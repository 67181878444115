import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import notify from 'devextreme/ui/notify';
import {Router} from '@angular/router';
import {DxFileUploaderComponent} from 'devextreme-angular';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit, OnDestroy {
  @ViewChild('fileUploader', {static: false}) fileUploader: DxFileUploaderComponent;
  @Output() uploadedFile = new EventEmitter<any>();
  @Input() visible = true;
  isExpanded = false;
  files: any[] = [];
  filesTmp: any[] = [];
  @Input() tmpUploadFiles = [];
  uploadUrl = '';

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.uploadUrl = `${window.location.origin}/api/file/upload`;
    console.log(this.router);
  }

  simulateOpen() {
    this.isExpanded = true;
    // @ts-ignore
    this.fileUploader.instance._isCustomClickEvent = true;
    // @ts-ignore
    this.simulateClick(this.fileUploader.instance._$content[0].querySelectorAll('.dx-fileuploader-button')[0]);
  }

  simulateClick(el) {
    let evt;
    if (document.createEvent) {
      evt = document.createEvent('MouseEvents');
      evt.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    }
    (evt) ? el.dispatchEvent(evt) : (el.click && el.click());
  }

  onUploaded(e) {
    if (e.request && e.request.responseText) {
      this.tmpUploadFiles.push(e.request.responseText);
      this.uploadedFile.emit({file: {name: e.file.name}, tmpId: e.request.responseText});
      this.filesTmp.push({file: e.file, tmpId: e.request.responseText});
    }
  }

  onUploadError(e) {
    if (e.request && e.request.responseText) {
      notify(e.request.responseText, 'error', 2500);
    }
  }

  ngOnDestroy(): void {
    this.fileUploader.instance.dispose();
    this.fileUploader = this.tmpUploadFiles = null;
  }

  clearFile() {
    this.files = [];
  }

  remove(ids) {
    const _files = [];
    this.files.filter(x => this.filesTmp.filter(x => ids.includes(x.tmpId)).map(x => x.file).includes(x)).forEach(x => {
      const index = this.files.indexOf(x);
      this.fileUploader.instance.removeFile(index);
    })
  }

}
