import {Injectable, ErrorHandler} from '@angular/core';
import {HttpErrorResponse, HttpClient} from '@angular/common/http';

const api = '/api';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private http: HttpClient) {
  }

  handleError(error: any) {
    if (error instanceof HttpErrorResponse || error.message.startsWith('Uncaught (in promise): ChunkLoadError')) {
      return;
    }
    this.http.post(`${api}/admin/error`, {message: error.message, stack: error.stack}).toPromise().then(() => {
      console.log('Ошибка отправлена на сервер', error);
    }).catch(err => {
      console.error('Не удалось отправить ошибку на сервер');
      console.error(err);
    });
  }
}
