import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {gridDictionary} from "../../list-generator/generator-interfaces";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import {GeneratorService} from "../../list-generator/generator.service";


@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class SelectorComponent implements OnInit {
  heightGrid: number;

  constructor(public dialogRef: MatDialogRef<SelectorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private service: GeneratorService) {
  }

  dataSource;
  treeData = false;
  selectedKeys = [];
  selectedData = [];
  btnChoice;

  ngOnInit() {
    this.treeData = !!this.data.treeData;
    this.selectedKeys = this.data.selectedRowKeys ? this.data.selectedRowKeys.map(x => x) : [];
    if (this.treeData)
      this.dataSource = new DataSource({
        pageSize: 15,
        store: new CustomStore({
          key: 'id',
          load: (options) => {
            return this.service.getList(this.data.dictionary.get, options.take, options.skip, options.filter, options.sort, options.group).toPromise()
              .then(result => {
                return {
                  type: 'array',
                  key: 'id',
                  data: result,
                };
              }).catch(error => {
                throw error.error;
              });
          }
        })
      });
  }

  choiceClick(e) {
    this.dialogRef.close(e);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      locateInMenu: 'auto',
      showText: 'inMenu',
      options: {
        icon: 'refresh',
        onClick: this.refreshData.bind(this),
        hint: 'Обновить',
        text: 'Обновить'
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      locateInMenu: 'auto',
      showText: 'inMenu',
      options: {
        disabled: true,
        icon: 'check',
        onClick: this.choiceRows.bind(this),
        hint: 'Выбрать',
        text: 'Выбрать',
        onInitialized: (args: any) => {
          this.btnChoice = args.component;
        }
      }
    })
  }

  refreshData() {
    this.dataSource.reload();
  }

  choiceRows() {
    this.dialogRef.close(this.selectedData);
  }

  onSelectionChanged(e) {
    if (this.btnChoice) {
      this.btnChoice.option({
        disabled: false
      });
    }
    this.selectedData = e.selectedRowsData;
  }
}
