import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import notify from 'devextreme/ui/notify';
import {UserService} from '../../../../services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [UserService]
})
export class ResetPasswordComponent {
  constructor(
              public dialogRef: MatDialogRef<ResetPasswordComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onCancelRestore() {
    this.dialogRef.close();
  }

  onAcceptRestore() {
    this.dialogRef.close();
  }
}
