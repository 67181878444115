import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {faExclamationTriangle, faPen} from '@fortawesome/free-solid-svg-icons';
import {DxFormComponent} from 'devextreme-angular';
import {ConfirmService} from '../../common-element/components/confirm/confirm.service';
import {Title} from '@angular/platform-browser';
import {ObjectService} from '../../../services/object.service';
import {ContractService} from '../../../services/contract.service';
import {gridDictionary} from '../../common-element/list-generator/generator-interfaces';
import notify from "devextreme/ui/notify";
import {ListGeneratorComponent} from "../../common-element/list-generator/list-generator.component";

@Component({
  selector: 'app-contragent',
  templateUrl: './contragent.component.html',
  styleUrls: ['./contragent.component.scss'],
  providers: [ContractService],
  encapsulation: ViewEncapsulation.None
})
export class ContragentComponent implements OnInit {
  contragent: any;
  titleBefore: string;
  loading = true;
  canEditHeadline = false;
  enabledSubmit = false;
  indicatorVisible = false;
  faPen = faPen;
  faExclamationTriangle = faExclamationTriangle;
  validEmail = true;
  @ViewChild('formComponent', {static: false}) formComponent: DxFormComponent;
  @ViewChild('tableContragentEmails', {static: false}) tableContragentEmails: ListGeneratorComponent;

  dictionaryContragentAddress = {
    id: 'contragent-address',
    name: 'Адресные данные контрагента',
    fileNameExport: 'Адресные данные контрагента',
    primaryKey: 'addressId',
    showCountRow: true,
    hideSelectedColumn: true,
    focusedRowEnabled: true,
    columnAutoWidth: true,
    wordWrap: true,
    columns: [
      {
        caption: 'Тип адреса',
        dataField: 'addressTypeName',
        dataType: 'string'
      },
      {
        caption: 'Адрес',
        dataField: 'addressText',
        dataType: 'string'
      }
    ]
  };
  dictionaryContragentEmails = {
    id: 'contragent-emails',
    name: 'Рассылка',
    fileNameExport: 'Рассылка',
    primaryKey: 'id',
    editMode: 'cell',
    selectedMode: 'multiple',
    allowAdd: true,
    allowUpdate: true,
    allowDelete: true,
    showCountRow: true,
    focusedRowEnabled: true,
    columnAutoWidth: true,
    wordWrap: true,
    columns: [
      {
        caption: 'Эл. почта',
        dataField: 'email',
        dataType: 'string'
      }
    ]
  };

  tabContent;
  tabs = [{
    id: 0,
    template: 'address',
    title: "Адресные данные контрагента",
    text: "Адреса",
    icon: "",
    content: "Адреса"
  },
    {
      id: 1,
      template: 'emails',
      title: "Рассылка",
      text: "Рассылка",
      icon: "",
      content: "Рассылка"
    }];

  warningMessage: any;
  defaultVisible = false;

  constructor(private contractService: ContractService,
              public dialogRef: MatDialogRef<ContragentComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private ref: ChangeDetectorRef,
              private confirmDialog: ConfirmService,
              private titleService: Title) {
    this.asyncValidationEmail = this.asyncValidationEmail.bind(this);
  }

  ngOnInit(): void {
    this.titleBefore = this.titleService.getTitle();
    this.canEditHeadline = !(this.data.id && this.data.id > 0);
    if (this.data.id > 0) {
      this.contractService.getContragent(this.data.id).subscribe(data => {
        console.log(data);
        this.contragent = data;
        this.contragent.email = data.email;
        this.titleService.setTitle(this.contragent.number === null ? 'Новый документ' : this.contragent.number);
        this.loading = false;
      });
    }
  }

  editHeadLine() {
    return;
    // this.canEditHeadline = !this.canEditHeadline;
  }

  asyncValidationEmail(params) {
    /*if (this.contragent.id > 0 && params.value === this.contragent.email) {
      return new Promise((resolve) => {
          resolve(true);
        }
      );
    }

     */

    return new Promise((resolve) => {
      this.contractService.checkContragent('email', params.value).subscribe(data => {
          resolve(data.result);
          this.validEmail = data.result

        }, () => {
          resolve(false);
        }
      );
    });
  }

  toggleDefault() {
    this.defaultVisible = !this.defaultVisible;
  }

  isEnabledSubmit(): void {
    if (this.contragent) {
      this.enabledSubmit = true;
      //this.enabledSubmit = (!this.isEmpty(this.contragent.email));
    }
  }

  onFormSubmit() {
    this.tableContragentEmails.closeEditor()
    if (this.isEmpty(this.contragent.email)) {
      this.validEmail = true
    }
    if (this.formComponent) {
      const res = this.formComponent.instance.validate();
      if (!res.isValid || !this.validEmail) {
        return;
      }
    }

    this.saveCard();
    this.validEmail = true
  }

  onValueChanged(e = null) {
    if (this.loading) {
      return;
    } else if (e && e.value === undefined) {
      return;
    } else if (!e.component._isReady) {
      return;
    }

    console.log('Changed');
    this.isEnabledSubmit();
    this.ref.detectChanges();
  }

  saveCard() {
    const saveObject = {
      //id: this.contragent.id > 0 ? this.contragent.id : undefined,
      id: this.contragent.id,
      name: this.contragent.name,
      inn: this.contragent.inn,
      kpp: this.contragent.kpp,
      ogrn: this.contragent.ogrn,
      ogrnDate: this.contragent.ogrnDate,
      codeMdm: this.contragent.codeMdm,
      contragentStatusName: this.contragent.contragentStatusName,
      email: this.contragent.email,
      emails: this.tableContragentEmails.getModifedRow()
    };
    this.indicatorVisible = true;
    this.contractService.saveContragent(saveObject, saveObject.id).subscribe(data => {
      console.log(data)
      //this.contragent.refreshData();
      this.indicatorVisible = false;
      this.dialogRef.close({newRow: !saveObject.id, data});
      notify('Данные успешно сохранены!', 'success', 1500);
    }, error => {
      this.indicatorVisible = false;
      // notify(error.message, 'error', 2500);
    });
    this.enabledSubmit = false;
  }

  closeDialog() {
    if (!this.enabledSubmit) {
      this.titleService.setTitle(this.titleBefore);
      this.dialogRef.close();
    } else {
      this.confirmDialog.confirm('Подтверждение', 'Имеются не сохраненные данные! Сохранить перед выходом?', true).subscribe(
        result => {
          if (result) {
            //this.onFormSubmit();
            this.saveCard();
          } else if (result !== undefined) {
            this.titleService.setTitle(this.titleBefore);
            this.dialogRef.close();
          }
        });
    }
  }

  isEmpty(str) {
    return (!str || 0 === str.length);
  }

  stringToNumber(val: string) {
    if (this.loading) {
      return;
    } else if (val === undefined || val === null) {
      return null;
    }
    return Number(val.toString().replace(',', '.'));
  }

  selectTab(e) {
    this.tabContent = this.tabs[e.itemIndex].content;
  }

  modifiedData(e) {
    this.isEnabledSubmit();
  }
}
